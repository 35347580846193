.menu-item {
    text-decoration: none;
    color: $gray;
    font-size: $font-size-root-md;
    transition: all 0.2s ease;
    font-size: 14px;

    &:hover {
        color: $white;
    }

    &__submenu {
        width: 149px;
        background-color: $white;
        border-radius: 20px;
        height: 0;
        padding: 0;
        position: absolute;
        top: 33px;
        transition: all 0.2s ease;
        z-index: 10;
        left: -15px;
        opacity: 0;
        visibility: hidden;
        will-change: opacity, height;

        .single {
            @include p(0 17px);

            &:not(:last-child) {
                @include mt(17px);
                @include mb(14px);
            }

            a {
                transition: all 0.2s ease;
                color: $dark-gray;

                &:hover {
                    color: $dark-gray-15;
                }
            }
        }
    }

    &__submenu-locations {
        display: flex;
        gap: 15px;
        width: 775px;
        background-color: $white;
        border-radius: 20px;
        height: 0;
        position: absolute;
        top: 33px;
        transition: all 0.2s ease;
        z-index: 10;
        left: -170px;
        will-change: height;

        @include media-breakpoint-up(lg) {
            left: -150px;
        }

        &__col {
            display: flex;
            flex-direction: column;
            gap: 5px;
            @include pt(10px);
            @include pl(5px);
            visibility: hidden;
            opacity: 0;
            transition: all 0.2s ease;
            will-change: opacity;

            .single {
                @include p(0 17px);
                @include mt(13px);
                @include mb(8px);

                a {
                    transition: all 0.2s ease;
                    color: $dark-gray;

                    p {
                        font-size: 14px;
                    }

                    &:hover {
                        color: $dark-gray-15;
                    }
                }
            }

            .arrow  {
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                border: solid $gray;
                border-width: 0 2px 2px 0;
            }
        }
    }
}

.navbar {
    position: absolute;
    width: 100%;

    @media screen and (max-width: 1024px) {
        flex-wrap: nowrap;
    }

    #logo {
        fill: $white;

        #stroke {
            stroke: $white;
        }
    }

    &.bg-white {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .life-at-luxoft-option {
        position: relative;
        height: 18px;
        display: flex;

        .menu-item {
            @include pb(32px);
        }

        &:hover {
            .menu-item__submenu {
                height: 140px;
                opacity: 1;
                visibility: visible;
            }

            .arrow  {
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                border: solid $white;
                border-width: 0 2px 2px 0;
            }
        }

        .arrow {
            display: inline-block;
            padding: 3px;
            -webkit-transition-duration: .35s;
            -o-transition-duration: .35s;
            transition-duration: .35s;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            position: relative;
            border: solid $gray;
            margin: 4px 2px 2px 7px;
            border-width: 0 2px 2px 0;
        }
    }

    .locations-option {
        position: relative;
        height: 18px;
        display: flex;

        .menu-item {
            @include pb(32px);
        }

        &:hover {
            .menu-item__submenu-locations {
                height: 240px;
            }

            .menu-item__submenu-locations__col {
                opacity: 1;
                visibility: visible;
            }

            .arrow  {
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                border: solid $white;
                border-width: 0 2px 2px 0;
            }
        }

        .arrow {
            display: inline-block;
            padding: 3px;
            -webkit-transition-duration: .35s;
            -o-transition-duration: .35s;
            transition-duration: .35s;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            position: relative;
            border: solid $gray;
            margin: 4px 2px 2px 7px;
            border-width: 0 2px 2px 0;
        }
    }
}

.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    transform: translateX(-10000px);
    transition: opacity 1s;
}

.overlay-language {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    transform: translateX(-10000px);
    transition: opacity 1s;
}

.overlay-show {
    transform: translateX(0);
    opacity: 1;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.navbar-mobile {
    @include media-breakpoint-up(md) {
        display: none;
    }

    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-f8f8;
    width: 100vw;
    height: 64px;
    bottom: 0px;
    z-index: 200;

    #language-menu {
        z-index: 900 !important;
        bottom: 0 !important;
    }

    .navbar-buttons {
        display: flex;
        width: 100%;
        padding: 10px 16px;
        gap: 24px;
        justify-content: space-around;
        z-index: 201;
        position: relative;
        background-color: $gray-f8f8;

        .navbar-button {
            background-color: transparent;
            padding: 0;
            margin: 0;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            text-decoration: none;
            color: #000;
        }
    }

    .navbar-popup-menu {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: fit-content;
        position: fixed;
        border-radius: 20px 20px 0 0;
        padding: 20px;
        bottom: 64px;
        background-color: $white;
        z-index: 99;
        transform: translateY(1000px);
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-duration: 1.2s;

        &.menu-open {
            transform: translateY(0);

            &.second-level-opened {
                transform: translateY(230px);
            }

            &.second-level-locations-opened {
                transform: translateY(0);
                height: 800px;

                @media only screen and (max-width: 400px) {
                    height: 520px;
                }
            }
        }

        .header-title {
            font-size: 18px;
            margin: 0;
            text-transform: uppercase;
        }

        &__first-items-container {
            width: 100%;
            transition: all 0.3s ease-in;

            &.hidden {
                transform: translateX(-105%);
            }
        }

        &__second-items-container {
            transform: translateX(100%);
            width: 100%;
            position: absolute;
            right: -2%;
            transition: all 0.3s ease-out;
            top: 92px;

            &.shown {
                transform: translateX(0);
            }
        }

        &__second-items-locations-container {
            overflow-x: hidden;
            height: 100px;
            position: absolute;
            display: none;


            &.shown {
                display: block;
                height: 700px;
                top: 90px;
                left: 20px;
                width: 95%;
                transition: all 0.3s ease-out;

                @media only screen and (max-width: 400px) {
                    height: 420px;
                }
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            .back {
                display: none;
            }

            &.second-level-opened,
            &.second-level-locations-opened {
                justify-content: flex-start;
                @include p(20px 20px 20px 10px);

                .cross {
                    opacity: 0;
                }

                .back {
                    display: block;
                    @include mr(20px);
                }
            }

            .cross,
            .back {
                opacity: 1;
                transition: all 0.5s ease;
                background-color: transparent;
                padding: 0;
                margin: 0;
                border: 1px solid rgba(0, 0, 0, 0.4);
                border-radius: 10px;
                width: 32px;
                height: 32px;
            }

            .back {
                img {
                    transform: rotate(90deg);
                }
            }
        }

        &-item,
        &-locations-item {
            display: flex;
            padding: 15px;
            align-items: center;
            gap: 15px;
            text-decoration: none;
            height: 75px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                right: 20px;
                border-bottom: 1px solid $gray-90;
                display: block;
                margin-bottom: -60px;
                width: 100%;
            }

            &:last-child::after {
                content: none;
            }
            &:last-child {
                padding-bottom: 0;
            }

            p {
                text-decoration: none;
                color: black;
                font-size: 14px;
                margin: 0;
                padding: 0;
            }

            svg {
                width: 30px;
                height: 30px;
            }
        }

        &-locations-item {
            gap: 10px;

            p {
                font-size: 15px;
            }
        }
    }
}

.saved-jobs {
    gap: 20px;
    padding: 10px 14px 10px 12px;
    align-self: center;
    width: 145px;
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    .bookmark {
        display: flex;
        align-items: center;

        figure {
            margin: 0;

            img {
                @include ml(6px);
                @include mr(2px);
            }
        }
    }

    span {
        color: $bright-purple-70;
    }
}

.booked {
    background-color: $gray-900;
    border-radius: 15px;
}

.language-dropdown {
    display: flex;
    gap: 10px;
    padding: 10px 14px;
    border-radius: 15px;
    background-color: $gray-21;
    align-self: center;
    align-items: center;

    @include media-breakpoint-down(md) {
        display: none;
    }

    svg {
        stroke: $gray-70;
    }

    p {
        color: $gray-70;
        margin: 0;
        font-size: 14px;
    }

    &:hover {
        background-color: $gray-30;
        svg {
            stroke: $white;
        }

        p {
            color: $white;
        }
    }
}

.up-menu-logo {
    width: 150px;
    height: 52px;
    background: url("/theme/luxoft/assets/images/logo/logo-luxoft-career-white.svg") no-repeat;
}

#header.bg-grey {
    border-bottom: 1px solid $gray-e6e6;

    .up-menu-logo {
        width: 150px;
        height: 52px;
        background: url("/theme/luxoft/assets/images/logo/logo-luxoft-career-purple.svg") no-repeat;
    }

    .menu-item {
        color: $dark-gray;
        &:hover {
            color: $text-rich-black;
        }
    }
}

#header.bg-white {
    border-bottom: 1px solid $gray-e6e6;

    .up-menu-logo {
        width: 150px;
        height: 52px;
        background: url("/theme/luxoft/assets/images/logo/logo-luxoft-career-purple.svg") no-repeat;
    }

    .language-dropdown,
    .booked {
        border: 1px solid $gray-e6e6;
        backdrop-filter: blur(7.5px);
        background-color: transparent;

        svg {
            stroke: $dark-gray;
        }

        p {
            color: $dark-gray;
        }

        &:hover {
            border-color: $dark-gray-15;
            svg {
                stroke: $dark-gray-15;
            }
            p {
                color: $dark-gray-15;
            }
        }
    }

    .menu-item {
        color: $dark-gray;

        &:hover {
            color: $text-rich-black;
        }
    }

    .life-at-luxoft-option {
        &:hover {
            .arrow {
                border: solid $gray !important;
                border-width: 0 2px 2px 0 !important;
            }
        }

        .menu-item {
            &__submenu {
                box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
                -webkit-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
                -moz-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
            }
        }
    }

    .locations-option {
        &:hover {
            .arrow {
                border: solid $gray !important;
                border-width: 0 2px 2px 0 !important;
            }
        }

        .menu-item__submenu-locations {
            box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
            -webkit-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
            -moz-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
        }
    }
}
