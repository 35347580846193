@mixin dropdown-search() {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;

    &.items-checked {
        .dropdown-face {
            color: $dark-gray-15;
        }
    }

    .dropdown-face {
        width: 100%;
        display: flex;
        height: 55px;
        padding: 15px;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        flex: 1 0 0;
        border-radius: 15px;
        background-color: transparent;
        border: none;

        @include media-breakpoint-down(xl) {
            padding: 14px 15px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        img {
            transition: all 0.2s ease;
        }

        &.show {
            color: $dark-gray-15;
        }

        &.show {
            img {
                transform: rotate(180deg);
            }
        }
    }

    .dropdown-menu::-webkit-scrollbar {
        width: 2px;
    }

    .dropdown-menu::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $gray-999;
    }

    .dropdown-menu::-webkit-scrollbar-thumb {
        background-color: $gray-70;
        outline: 1px solid $gray-70;
    }

    .dropdown-menu {
        width: 100%;
        border: none;
        box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
        -webkit-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
        -moz-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
        @include p(0 20px);
        overflow-x: hidden;
        max-height: 300px;
        z-index: 900;

        &.shown {
            display: block;
            position: absolute;
            inset: 0px auto auto 0px;
            margin: 0px;
            transform: translate3d(0px, 57px, 0px);
        }

        li {
            cursor: pointer;
            @include m(22px 0);
            display: flex;
            justify-content: space-between;
            position: relative;
            font-size: 14px;

            &.no-result {
                cursor: initial;
            }

            label {
                width: 100%;
                cursor: pointer;
            }

            input[type="checkbox"] {
                cursor: pointer;
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                margin: 0;
                width: 24px;
                height: 24px;
                border: 1px solid $gray-ccc;
                border-radius: 5px;
                display: grid;
                place-content: center;

                &::before {
                    content: url("/theme/luxoft/assets/images/icons/checkbox-checked-purple.svg");
                    width: 24px;
                    height: 24px;
                    transform: scale(0);
                    transform-origin: center;
                    transition: 120ms transform ease-in-out;
                }

                &:checked {
                    &::before {
                        border: none;
                        transform: scale(1);
                    }
                }
            }
        }
    }

    .extra-counrties-checked {
        background-color: $purple-f8f3;
        border-radius: 5px;
        @include p(1px 6px 4px 6px);
        position: absolute;
        top: 15px;
        right: 35px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-up(xl) {
            right: 60px;
        }

        span {
            font-weight: $font-weight-normal;
            font-size: 14px;
            color: $purple;
        }
    }

    .input-search-dropdown {
        border: none;
        height: 35px;
        width: 100%;
        color: $black;

        &:focus-visible {
            border: none;
            outline: none;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .caret-down-wrapper {
        position: absolute;
        top: 16px;
        right: 5px;
        cursor: pointer;
        height: 28px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        &::before {
            content: url("/theme/luxoft/assets/images/icons/caret-down-black.svg");
            width: 20px;
            height: 24px;
            transition: all 0.2s ease;
            display: block;
        }

        &.rotate {
            &::before {
                transform: rotate(180deg);
            }
        }

    }
}
