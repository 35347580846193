.logeek-magazine-lp {
    &.white-background {
        gap: 0;
    }

    .apply-here-link-container {
        text-align: center;
        @include p(0 20px);

        @include media-breakpoint-up(xl) {
            @include p(0);
        }

        a {
            display: inline-block;
        }
    }

    .container {
        @include media-breakpoint-up(xl) {
            max-width: 1280px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }
    }

    .career-legacy {
        gap: 30px;

        &__cards {
            &__card {
                &:hover {
                    border: 1.5px solid $gray-e6e6;
                    box-shadow: none;
                }

                &--title {
                    width: 100%;
                }
            }
        }
    }

    .logeek-magazine {
        .row {
            @include mr(0);
            @include ml(0);

            @include media-breakpoint-up(md) {
                margin-right: calc(var(--#{$variable-prefix}gutter-x) * -.5);
                margin-left: calc(var(--#{$variable-prefix}gutter-x) * -.5);
            }
        }

        .row > * {
            @include media-breakpoint-down(md) {
                @include pr(0);
                @include pl(0);
            }
        }

        &__header-black {
            @include p(104px 20px 25px);

            @include media-breakpoint-up(md) {
                @include p(148px 0 60px);
            }

            h1 {
                @include media-breakpoint-up(md) {
                    text-align: center;
                }
            }

            .header-content {
                @include mt(60px);

                .buttons-container {
                    &.desktop {
                        display: none;

                        @include media-breakpoint-up(lg) {
                            display: flex;
                            flex-direction: column;
                        }

                        @include media-breakpoint-up(xl) {
                            flex-direction: row;
                        }

                        .social-buttons-wrapper {
                            display: flex;
                            @include mt(20px);

                            @include media-breakpoint-up(xl) {
                                @include mt(0);
                            }
                        }
                    }

                    &.mobile {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .social-buttons-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include mt(20px);
                        }

                        @include media-breakpoint-up(lg) {
                            display: none;
                        }
                    }

                    button {
                        &:nth-child(2) {
                            @include mr(15px);
                            @include ml(15px);

                            @include media-breakpoint-up(xl) {
                                @include mr(0);
                            }
                        }

                        @include media-breakpoint-up(xl) {
                            @include ml(15px);
                        }
                    }

                    a {
                        width: 100%;

                        @media screen and (min-width: 600px) {
                            width: auto;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 70%;
                        }

                        @include media-breakpoint-up(xl) {
                            @include mr(10px);
                            width: auto;
                        }

                        &:hover {
                            background-color: $gray-20;
                        }
                    }
                }

                .content-right {
                    @include mt(40px);

                    @include media-breakpoint-up(lg) {
                        @include mt(0);
                    }

                    .preview {
                        background-color: $gray-30;
                        border-radius: 15px;
                        text-align: center;
                        height: 300px;

                        @include media-breakpoint-up(lg) {
                            height: 100%;
                        }

                        iframe {
                            height: 100%;
                            max-width: 100%;
                        }

                        .bp-container {
                            .bp-header-container {
                                display: none;

                                &:not(.bp-is-hidden)+.bp:not(.bp-is-fullscreen) {
                                    top: 0;
                                }
                            }
                        }
                    }
                }

                .content-left {
                    @include media-breakpoint-up(md) {
                        @include pr(60px);
                    }

                    p {
                        color: $gray;
                        line-height: 1.5;
                    }

                    h2 {
                        @include media-breakpoint-up(md) {
                            font-size: 55px;
                        }
                    }
                }
            }
        }

        &__download-options {
            background-color: $gray-20;
            border-radius: 30px;
            @include p(40px 30px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            p {
                font-weight: $font-weight-semibold;
                font-size: 22px;
                line-height: 28.6px;
            }

            .download-option-btn {
                display: flex;
                @include mt(20px);
                position: relative;

                @include media-breakpoint-up(md) {
                    @include mt(0);
                }
            }

            .magazines-list-wrapper {
                color: $white;
                background-color: transparent;
                border: 1px solid $dark-gray;
                cursor: pointer;
                position: relative;
                font-size: 14px;
                border-radius: 15px;
                @include mr(10px);
                @include p(16px 30px 16px 20px);

                &.active {
                    &:after {
                        transform: rotate(180deg);
                    }
                }

                &:after {
                    content: url(/theme/luxoft/assets/images/icons/chevron-down-white.svg);
                    height: 24px;
                    position: absolute;
                    right: 6px;
                    top: 12px;
                    transition: all .2s ease;
                    width: 24px;
                }

                .default-magazine {
                    color: $white;
                    font-size: 14px;
                    background-color: transparent;
                    border: none;
                }

                .magazines-list {
                    height: 0;
                    -webkit-transition: height 0.02s cubic-bezier(0, 0, 0, 1.01), padding 0.1s linear;
                    transition: height 0.2s cubic-bezier(0, 0, 0, 1.01), padding 0.1s linear;
                    position: absolute;
                    background-color: #202020;
                    border-radius: 15px;
                    top: 53px;
                    left: 0;
                    width: 125px;
                    z-index: 4;

                    &.active {
                        border: 1px solid #63666a;
                        height: auto;

                        .single {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    .single {
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.2s ease;
                        @include p(12px 10px);

                        &:not(:first-child) {
                            border-top: 1px solid $dark-gray;
                        }

                        button {
                            border: none;
                            color: $white;
                            font-size: 14px;
                            background-color: transparent;
                        }
                    }
                }
            }

            .download-magazine-btn {
                background-color: $purple;
                width: 55px;
                height: 55px;
                border: none;
                border-radius: 15px;
            }
        }
    }
}
