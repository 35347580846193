#splider-our-locations {
    @include media-breakpoint-up(md) {
        display: none !important;
    }
    margin-bottom: 25px;

    .splide__pagination.splide__pagination--custom {
        margin-left: -40px !important;
    }
}

.our-locations {
    padding-bottom: 45px;
    background-color: $gray-100;
    border-radius: 30px;
    padding: 60px;

    @include media-breakpoint-down(md) {
        background-color: $white;
        padding: 0;
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 250px;
        text-align: center;
        row-gap: 20px;
        margin-bottom: 45px;

        @include media-breakpoint-down(xl) {
            padding: 0 40px;
        }

        @include media-breakpoint-down(md) {
            padding: 0 20px;
            align-items: flex-start;
            text-align: left;
            margin-bottom: 25px;
        }

        h2 {
            margin: 0;
            color: $text-rich-black;

            @include media-breakpoint-down(md) {
                text-align: left;
            }
        }

        p {
            color: $gray-66;
            margin: 0;
            font-size: 20px;
            line-height: 150%;

            @include media-breakpoint-down(xxl) {
                font-size: 18px;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 160%;
                font-weight: $font-weight-normal;
            }
        }
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        @include media-breakpoint-down(xl) {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        &__left {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            @include media-breakpoint-down(xl) {
                order: 1;
            }

            @include media-breakpoint-down(md) {
                display: flex;
                overflow-x: hidden;
            }

            .location-card {
                text-decoration: none;
                background-color: $white;
                border-radius: 25px;
                padding: 25px 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: box-shadow 0.3s;
                position: relative;

                @include media-breakpoint-down(md) {
                    padding: 0;
                }

                .location-background {
                    @include media-breakpoint-down(md) {
                        border-radius: 15px;
                        width: 300px;
                        height: 210px;
                        object-fit: fill;
                    }
                }

                svg {
                    height: 31px;
                    width: 31px;
                    transition: transform 0.3s;
                    box-shadow: none;

                    path {
                        stroke: $dark-gray-15;
                    }

                    rect {
                        fill: $dark-gray-15;
                    }
                }

                &:hover {
                    @include media-breakpoint-up(md) {
                        box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);
                        svg {
                            transform: rotate(45deg);
                        }
                    }
                }

                &__text {
                    @include media-breakpoint-down(md) {
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.8);
                        display: flex;
                        gap: 10px;
                        padding: 10px;
                        border-radius: 15px;
                        bottom: 15px;
                        right: 60px;
                    }
                    &--country {
                        font-size: 20px;
                        color: $text-rich-black;
                        font-weight: $font-weight-semibold;
                        line-height: 130%;
                        margin: 0;

                        @include media-breakpoint-down(xxl) {
                            font-size: 18px;
                        }
                    }
                }

                .link-btn {
                    background: transparent;
                    border-radius: 10px;
                    border: 1.5px solid $white;
                    padding: 10px;
                    bottom: 20px;
                    left: 20px;

                    &--hover {
                        display: none;
                    }

                    &:hover {
                        background-color: $white;

                        .link-btn--normal {
                            display: none;
                        }

                        .link-btn--hover {
                            display: block;
                        }
                    }
                }
            }
        }

        &__right {
            position: relative;
            width: 100%;

            @include media-breakpoint-down(md) {
                display: none;
            }

            figure {
                margin: 0;
                height: 100%;

                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    max-height: 367px;
                    border-radius: 25px;

                    @include media-breakpoint-down(xl) {
                        height: 350px;
                    }
                }
            }

            &__location {
                display: flex;
                gap: 15px;
                align-items: center;
                position: absolute;
                padding: 5px;
                padding-right: 25px;
                bottom: 20px;
                right: 20px;
                border-radius: 15px;
                background: rgba(255, 255, 255, 0.8);
                box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
                backdrop-filter: blur(6px);
                text-decoration: none;
                color: $text-rich-black;

                @include media-breakpoint-down(md) {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    padding-left: 15px;
                    padding-right: 5px;
                    gap: 10px;
                }

                &--icon {
                    display: flex;
                    padding: 10px;
                    background-color: $white;
                    border-radius: 10px;
                    box-shadow: 0px 2px 5px 0px rgba(21, 21, 21, 0.08);

                    img {
                        height: auto !important;
                    }
                }

                &--text {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 14px;

                    @include media-breakpoint-down(md) {
                        font-size: 15px;
                        gap: 8px;
                    }

                    &__city {
                        font-weight: $font-weight-semibold;
                        color: $text-rich-black;
                        margin: 0;

                        @include media-breakpoint-down(md) {
                            line-height: 140%;
                        }
                    }
                }
            }
        }
    }

    .splide {
        padding-left: 20px;
        .splide__track {
            .splide__list {
                .splide__slide {
                    figure {
                        height: 100%;
                        width: 100%;
                        img {
                            border-radius: 20px;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }

    .button-container {
        @include media-breakpoint-up(md) {
            display: none;
        }
        display: flex;
        justify-content: center;
        padding: 0 20px;

        @media (max-width: 600px) {
            display: block;
        }
    }
}
