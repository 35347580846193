//
// Base styles
//

.accordion {
    margin-bottom: 35px !important;
    padding: 0 20px;

    .accordion-item {
        border: none;
        border-bottom: 1px solid $gray-30;

        &:first-of-type {
            @include border-top-radius($accordion-border-radius);

            .accordion-button {
                @include border-top-radius($accordion-inner-border-radius);
            }
        }

        &:not(:first-of-type) {
            border-top: 0;
        }

        // Only set a border-radius on the last item if the accordion is collapsed
        &:last-of-type {
            @include border-bottom-radius($accordion-border-radius);

            .accordion-button {
                &.collapsed {
                    @include border-bottom-radius(
                        $accordion-inner-border-radius
                    );
                }
            }

            .accordion-collapse {
                @include border-bottom-radius($accordion-border-radius);
            }
        }

        &:last-of-type {
            border-bottom: none !important;
        }

        .accordion-header {
            margin-bottom: 0;

            .accordion-button {
                padding-right: 0 !important;
                padding-left: 0 !important;
                background-color: $dark-gray-15;
                box-shadow: none !important;
                border: none !important;
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                padding: $accordion-button-padding-y $accordion-button-padding-x;
                font-size: 17px;
                font-weight: $font-weight-semibold;
                line-height: 140%;
                color: $white;
                text-align: left; // Reset button style
                border: 0;
                @include border-radius(0);
                overflow-anchor: none;

                &:not(.collapsed) {
                    box-shadow: inset 0 ($accordion-border-width * -1) 0
                        $accordion-border-color;

                    &::after {
                        transform: $accordion-icon-transform;
                    }
                }

                // Accordion icon
                &::after {
                    flex-shrink: 0;
                    width: 24px;
                    height: 24px;
                    margin-left: auto;
                    content: "";
                    background-image: escape-svg($accordion-button-icon);
                    background-repeat: no-repeat;
                    background-size: 100%;
                    @include transition($accordion-icon-transition);
                }

                &:hover {
                    z-index: 2;
                }

                &:focus {
                    z-index: 3;
                    border-color: $accordion-button-focus-border-color;
                    outline: 0;
                    box-shadow: $accordion-button-focus-box-shadow;
                }
            }
        }

        .accordion-body {
            padding: $accordion-body-padding-y $accordion-body-padding-x;
            ul {
                list-style-type: none;
                @include pl(0);
                li {
                    @include mt(1.5rem);
                }
            }
        }
    }
}

.white-background {
    .accordion {
        margin-bottom: 35px !important;
        padding: 0 20px;

        .accordion-item {
            border: none;
            border-bottom: 1px solid $gray-90;

            &:first-of-type {
                @include border-top-radius($accordion-border-radius);

                .accordion-button {
                    @include border-top-radius($accordion-inner-border-radius);
                }
            }

            &:not(:first-of-type) {
                border-top: 0;
            }

            // Only set a border-radius on the last item if the accordion is collapsed
            &:last-of-type {
                @include border-bottom-radius($accordion-border-radius);

                .accordion-button {
                    &.collapsed {
                        @include border-bottom-radius(
                            $accordion-inner-border-radius
                        );
                    }
                }

                .accordion-collapse {
                    @include border-bottom-radius($accordion-border-radius);
                }
            }

            &:last-of-type {
                border-bottom: none !important;
            }

            .accordion-header {
                .accordion-button {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                    background-color: $white;
                    color: $dark-gray-15;
                    box-shadow: none !important;
                    border: none !important;
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: $accordion-button-padding-y
                        $accordion-button-padding-x;
                    font-size: 17px;
                    font-weight: $font-weight-semibold;
                    line-height: 140%;
                    text-align: left; // Reset button style
                    border: 0;
                    @include border-radius(0);
                    overflow-anchor: none;

                    &:not(.collapsed) {
                        box-shadow: inset 0 ($accordion-border-width * -1) 0
                            $accordion-border-color;

                        &::after {
                            transform: $accordion-icon-transform;
                        }
                    }

                    // Accordion icon
                    &::after {
                        flex-shrink: 0;
                        width: 24px;
                        height: 24px;
                        margin-left: auto;
                        content: "";
                        background-image: escape-svg(
                            $accordion-button-black-icon
                        );
                        background-repeat: no-repeat;
                        background-size: 100%;
                        @include transition($accordion-icon-transition);
                    }

                    &:hover {
                        z-index: 2;
                    }

                    &:focus {
                        z-index: 3;
                        border-color: $accordion-button-focus-border-color;
                        outline: 0;
                        box-shadow: $accordion-button-focus-box-shadow;
                    }
                }
            }

            .accordion-body ul {
                list-style-type: none;
                @include pl(0);
                padding: $accordion-body-padding-y $accordion-body-padding-x;

                li {
                    @include mt(1.5rem);
                }
            }
        }
    }

    .single-event {
        &__faq {
            .accordion {
                @include mb(0 !important);

                .accordion-item {
                    &:last-of-type {
                        border-bottom: 1px solid $gray-e6e6 !important;
                    }
                }
            }
        }
    }
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
    .accordion-collapse {
        border-width: 0;
    }

    .accordion-item {
        border-right: 0;
        border-left: 0;
        @include border-radius(0);

        &:first-child {
            border-top: 0;
        }
        &:last-child {
            border-bottom: 0;
        }

        .accordion-button {
            @include border-radius(0);
        }
    }
}
