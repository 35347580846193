[data-aos="normal-state"] {
    &.aos-animate {
        transform: translateY(0px);
    }
}

[data-aos="title-section-up"] {
    &.aos-animate {
        transform: translateY(-500px);
    }
}

[data-aos="navbar-up"] {
    &.aos-animate {
        transform: translateY(-100px);
    }
}

