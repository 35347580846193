.pagination-buttons {
    display: flex;
    @include list-unstyled();
}

.page-link {
    position: relative;
    display: block;
    color: $black;
    background-color: transparent;
    border: none;
    @include transition($pagination-transition);

    &:hover {
        z-index: 2;
        color: $purple;
        background-color: $bright-purple-90;
    }

    &:focus {
        z-index: 3;
        background-color: $bright-purple-90;
        border-bottom: 1px solid $purple;
        box-shadow: none;
    }

    &:active {
        background-color: $purple;
        color: $white;
        border-radius: 4px;
    }
}

.page-item {
    &:not(:first-child) .page-link {
        margin-left: $pagination-margin-start;
    }

    &.active .page-link {
        z-index: 3;
        color: $white;
    }

    &.disabled .page-link {
        color: $pagination-disabled-color;
        pointer-events: none;
        background-color: $gray-90;
        border-color: $pagination-disabled-border-color;
    }
}
