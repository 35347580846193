.white-background.events-container {
    padding: 0;
}

.events {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 45px;

    @include media-breakpoint-down(md) {
        padding: 25px 20px 20px;
    }

    &__headline {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 25px;
        display: flex;
        height: 300px;
        padding: 20px;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;

        @include media-breakpoint-down(xxl) {
            height: auto;
        }

        @include media-breakpoint-down(xl) {
            height: 300px;
        }

        @include media-breakpoint-down(md) {
            height: 400px;
            border-radius: 20px;
            padding: 10px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
        }

        &__card {
            display: flex;
            border-radius: 20px;
            padding: 25px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background: $white;
            box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
            backdrop-filter: blur(6px);
            width: 450px;
            height: 100%;

            @include media-breakpoint-down(xxl) {
                gap: 20px;
            }

            @include media-breakpoint-down(xl) {
                gap: 0px;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 15px;
                gap: 10px;
                height: auto;
            }

            &__details {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                @include media-breakpoint-down(md) {
                    gap: 0px;
                }

                h3 {
                    margin: 0;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 5px;
                    }
                }
                > p {
                    color: $dark-gray;
                    width: 60%;
                    margin: 0;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 10px;
                        width: 100%;
                    }
                }

                p {
                    margin: 0;
                }

                &__tags {
                    display: flex;
                    padding: 5px 0px;
                    align-items: center;
                    gap: 13px;
                    color: $dark-gray-15;

                    @include media-breakpoint-down(md) {
                        flex-wrap: wrap;
                        gap: 10px 15px;
                    }

                    &__location {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                    }

                    &__date {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                    }

                    &__hours {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                    }
                }
            }
            > button {
                padding: 13px 35px;
            }
        }
    }

    &__upcoming {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        &__container {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        & #splider-upcoming-events {
            width: 100%;
            @include media-breakpoint-up(md) {
                display: none;
            }

            > .splide__track {
                margin-right: -20px;
                padding-right: 20px;
            }

            & .splide__pagination--custom {
                padding-bottom: 0px !important;
            }
        }
    }

    &__past {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;

        @include media-breakpoint-down(md) {
            gap: 20px;
        }

        &__container {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 45px;

            &__month {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;

                &__container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                & #splider-past-events {
                    width: 100%;
                    @include media-breakpoint-up(md) {
                        display: none;
                    }

                    > .splide__track {
                        margin-right: -20px;
                        padding-right: 20px;
                    }

                    & .splide__pagination--custom {
                        padding-bottom: 0px !important;
                    }
                }
            }
        }
    }

    &__card {
        display: flex;
        width: 310px;
        padding: 10px 10px 25px 10px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 25px;
        border-radius: 25px;
        border: 1.5px solid $gray-e6e6;
        background-color: $white;
        text-decoration: none;
        cursor: pointer;

        @include media-breakpoint-down(xl) {
            width: 274.67px;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            gap: 20px;
            padding: 10px 10px 20px;
            height: 100%;
        }

        &:hover {
            @include media-breakpoint-up(md) {
                border-radius: 25px;
                border-color: transparent;
                box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
            }
        }

        &__details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            gap: 15px;

            &__image {
                position: relative;
                width: 100%;

                img {
                    border-radius: 20px;
                    height: 200px;
                    width: 100%;
                    object-fit: cover;

                    @include media-breakpoint-down(md) {
                        height: 160px;
                    }
                }

                &__date {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    padding: 10px;
                    border-radius: 15px;
                    background: rgba(255, 255, 255, 0.9);
                    box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
                    backdrop-filter: blur(6px);
                    color: $dark-gray-15;

                    @include media-breakpoint-down(md) {
                        border-radius: 10px;
                    }

                    > p {
                        margin: 0;
                    }
                }
            }

            &__text {
                display: flex;
                padding: 0px 15px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 10px;

                @include media-breakpoint-down(md) {
                    gap: 8px;
                }

                > p {
                    margin: 0;
                }

                &__title {
                    color: $dark-gray-15;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &__description {
                    color: $dark-gray;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &__tags {
            display: flex;
            padding: 0px 15px;
            align-items: center;
            align-content: center;
            gap: 5px;
            color: $dark-gray-15;
            flex-wrap: wrap;

            &__location,
            &__hours {
                display: flex;
                padding: 5px 10px 5px 7px;
                align-items: center;
                gap: 6px;
                border-radius: 10px;
                background: $gray-90;
                height: 34px;

                > p {
                    margin: 0;
                }
            }
        }
    }
}

.single-event {
    .container {
        @include media-breakpoint-up(xl) {
            max-width: 1280px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }
    }

    figure {
        @include m(0);
    }

    &.single-past-event-header {
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px;
    }

    &.single-past-event-content {
        @include pt(40px);
        @include pb(0);

        .worlds-best-workplace,
        .single-event__agenda,
        .single-event__faq {
            display: none;
        }
    }

    .row {
        @include mr(0);
        @include ml(0);
    }

    &__header {
        @include p(104px 20px 30px);

        @include media-breakpoint-up(md) {
            @include p(148px 0 80px);
        }

        &--title {
            color: $white;
            @include mb(20px);

            @include media-breakpoint-up(md) {
                @include mb(30px);
                text-align: center;
            }
        }

        &--content {
            display: flex;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(md) {
                gap: 20px;
            }

            &__text {
                width: 50%;
                height: 350px;
                border-radius: 30px;
                background-color: $gray-20;
                padding: 35px 100px 35px 35px;

                @include media-breakpoint-down(xl) {
                    height: 260px;
                    padding: 35px;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                    padding: 25px 25px 100px 25px;
                    height: auto;
                }

                p {
                    padding: 0;
                    text-align: start;
                    color: $gray-ccc;

                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: -0.28px;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: $font-weight-normal;
                        line-height: 160%;
                        letter-spacing: -0.32px;
                        color: $gray-e6e6;
                    }
                }
            }

            &__img {
                width: 100%;
                height: 350px;
                border-radius: 30px;
                object-fit: cover;

                @include media-breakpoint-down(xl) {
                    height: 260px;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        &--action {
            display: flex;
            align-items: initial;
            justify-content: center;
            flex-direction: column;
            gap: 20px;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            .dropdown {
                .event-share-btn {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    width: auto;
                    height: 55px;
                    padding: 10px 15px;
                    background-color: transparent;
                    border-radius: 15px;
                    border: 1px solid #666666;
                    color: $white;
                    font-size: 14px;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }

                    .share-icon {
                        width: 24px;
                        height: 24px;
                        stroke: $white;
                        fill: transparent;
                    }

                    &::after {
                        content: none;
                    }
                }

                .dropdown-menu {
                    border-radius: 15px;
                    box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
                    border: none;
                    min-width: unset;
                    padding: 0;
                    overflow: hidden;
                    width: 100%;

                    .dropdown-item {
                        display: flex;
                        padding: 20px 25px 20px 20px;
                        gap: 10px;
                        align-items: center;
                        cursor: pointer;

                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &__icon--facebook {
                            border-radius: 50px;
                            width: 35px;
                            height: 35px;
                            background-color: $blue-337;
                        }
                        &__icon--twitter {
                            border-radius: 50px;
                            width: 35px;
                            height: 35px;
                            background-color: $black;
                        }
                        &__icon--linkedin {
                            border-radius: 50px;
                            width: 35px;
                            height: 35px;
                            background-color: $blue-006;
                        }
                        &__icon--mail {
                            border-radius: 50px;
                            width: 35px;
                            height: 35px;
                            background-color: $orange;
                        }

                        .facebook-icon {
                            fill: $white;
                            width: 11px;
                            height: 19px;
                        }

                        .twitter-icon {
                            fill: $white;
                            width: 19px;
                            height: 15px;
                        }

                        .linkedin-icon {
                            fill: $white;
                            width: 15px;
                            height: 15px;
                        }

                        .mail-icon {
                            fill: $white;
                            width: 40px;
                            height: 35px;
                        }

                        &:hover {
                            background-color: #fbfbfb;
                        }
                    }
                }
            }

            &.top-action {
                display: none;

                @include media-breakpoint-up(md) {
                    display: flex;
                    @include mb(35px);
                }
            }

            &.bottom-action {
                display: flex;
                @include mt(20px);

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
    }

    .worlds-best-workplace {
        @include p(45px 20px);

        @include media-breakpoint-up(md) {
            @include p(45px 0);
        }
    }

    .standard-video-section-rounded {
        @include p(0 20px);

        @include media-breakpoint-up(md) {
            @include p(0);
        }
    }

    &__event-form {
        @include p(30px 20px 0);

        @include media-breakpoint-up(md) {
            @include p(40px 0 0);
        }

        .event-headline {
            font-size: 35px;
            line-height: 42px;

            @include media-breakpoint-up(md) {
                font-size: 60px;
                line-height: 72px;
            }
        }
    }

    &__agenda {
        @include p(45px 20px 15px);

        @include media-breakpoint-up(md) {
            @include p(45px 0);
        }

        &--title {
            @include pl(0);
            @include pr(0);
            @include mb(10px);

            @include media-breakpoint-up(md) {
                text-align: center;
            }
        }

        &--item {
            @include p(22px 0);
            &:not(:last-child) {
                border-bottom: 1px solid $gray-de;
            }

            @include media-breakpoint-up(md) {
                @include mt(20px);
                @include p(22px 25px);
                border: 1px solid $gray-de;
                border-radius: 20px;
            }

            .item-time {
                line-height: 18.2px;
                color: $gray-66;
                font-size: 15px;
                font-weight: $font-weight-normal;

                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
            }

            .item-title {
                color: $dark-gray-15;
                font-size: 18px;
                line-height: 23.4px;
                font-weight: $font-weight-semibold;
                @include m(6px 0);

                @include media-breakpoint-up(md) {
                    @include m(12px 0);
                    font-size: 24px;
                    line-height: 31.2px;
                }
            }

            .item-description {
                color: $dark-gray;
                font-size: 15px;
                line-height: 21px;
                @include mb(6px);

                @include media-breakpoint-up(md) {
                    @include mb(12px);
                }
            }

            .item-list {
                color: $dark-gray;
                font-size: 15px;
                line-height: 21px;
            }
        }
    }

    &__our-speakers {
        @include p(10px 20px 45px);

        @include media-breakpoint-up(md) {
            @include p(10px 0 45px);
        }

        .row > * {
            @include pr(0);
            @include pl(0);
        }

        &--title {
            @include media-breakpoint-up(md) {
                text-align: center;
            }
        }

        .html-code {
            display: none;
        }

        .our-speakers-mobile {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .our-speakers-desktop {
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
            }
        }

        &--item--container {
            @include media-breakpoint-up(md) {
                &:nth-child(odd) {
                    @include pr(10px);
                }

                &:nth-child(even) {
                    @include pl(10px);
                }
            }
        }

        &--item {
            border: 1px solid $gray-e6e6;
            border-radius: 30px;
            @include p(1rem 1.2rem);
            width: 84% !important;

            @include media-breakpoint-up(md) {
                @include p(2rem 1.5rem);
                width: auto !important;
            }

            &--name-title {
                display: flex;
                align-content: center;
                justify-content: space-between;

                .speaker-name-title {
                    width: 84%;

                    @include media-breakpoint-up(xl) {
                        width: 90%;
                    }

                    .speaker-name {
                        color: $dark-gray-15;
                        @include mb(4px);
                    }

                    .speaker-title {
                        color: $dark-gray;
                    }
                }
            }

            &--bio {
                @include media-breakpoint-up(md) {
                    display: -webkit-box;
                }

                .speaker-img {
                    img {
                        border-radius: 10px;
                        width: 105px !important;
                    }
                }

                .speaker-bio {
                    @include mt(3);

                    @include media-breakpoint-up(md) {
                        @include mt(0);
                        @include ml(25px);
                        width: 65%;
                    }

                    @include media-breakpoint-up(xl) {
                        width: 67%;
                    }

                    @include media-breakpoint-up(xxl) {
                        width: 78%;
                    }

                    p {
                        color: $dark-gray;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    &__faq {
        @include p(35px 20px);

        @include media-breakpoint-up(md) {
            @include p(45px 0);
        }

        .accordion {
            @include p(0);

            .accordion-item {
                border-bottom: 1px solid $gray-e6e6;

                .accordion-collapse {
                    .accordion-body {
                        @include p(0 4rem 16px 0);

                        p {
                            font-size: 15px;
                            font-weight: $font-weight-normal;
                            line-height: 24px;
                            color: $dark-gray
                        }
                    }
                }

                .accordion-header {
                    .accordion-button {
                        @include p(18px 0);
                        font-size: 18px;
                        line-height: 23.4px;
                        background-color: transparent;

                        &::after {
                            width: 28px;
                            background-image: url(/theme/luxoft/assets/images/icons/faq-arrow.svg);
                            transform: rotate(-180deg);
                        }

                        &:not(.collapsed)::after {
                            transform: rotate(0);
                            background-image: url(/theme/luxoft/assets/images/icons/faq-arrow.svg);
                        }
                    }
                }
            }
        }
    }

    &__photo-gallery {
        @include p(40px 20px);

        @include media-breakpoint-up(md) {
            @include p(40px 0);
        }

        .row > * {
            @include pr(0);
            @include pl(0);
        }

        &--btn-c {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include mt(15px);

            @include media-breakpoint-up(md) {
                @include mt(50px);
            }

            @include media-breakpoint-up(xl) {
                @include mt(35px);
            }
        }

        &--subheadline {
            display: none;

            @include media-breakpoint-up(xxl) {
                display: grid;
            }
        }

        &--items {
            @include mt(4);
        }

        &--ic {
            &:not(:last-child) {
                @include mb(14px);

                @include media-breakpoint-up(md) {
                    @include mb(24px);
                }

                .single-event__photo-gallery--ic--item {
                    .photo-gallery-text {
                        border-bottom: 1px solid $gray-e6e6;

                        @include media-breakpoint-up(md) {
                            border-bottom: none;
                        }
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                @include mb(0 !important);
            }

            &--item {
                display: flex;

                @include media-breakpoint-up(xl) {
                    display: grid;
                    width: 305px;
                }

                @include media-breakpoint-up(xxl) {
                    width: 310px;
                }

                .photo-gallery-img {
                    overflow: hidden;
                    display: -webkit-box;
                    width: 72px;
                    height: 72px;
                    border-radius: 10px;

                    @include media-breakpoint-up(md) {
                        width: 160px;
                        height: 160px;
                    }

                    @include media-breakpoint-up(xl) {
                        width: 305px;
                        height: 270px;
                        display: grid;
                    }

                    @include media-breakpoint-up(xxl) {
                        width: 310px;
                    }

                    img {
                        height: -webkit-fill-available;
                        object-fit: cover;
                    }
                }

                .photo-gallery-text {
                    width: 75%;
                    @include ml(18px);
                    @include pb(14px);

                    @media only screen and (min-width: 580px) {
                        width: 80%;
                    }

                    @media only screen and (min-width: 700px) {
                        width: 85%;
                    }

                    @media only screen and (min-width: 800px) {
                        width: 88%;
                    }

                    @include media-breakpoint-up(md) {
                        width: 79%;
                    }

                    @include media-breakpoint-up(xl) {
                        @include ml(0);
                        width: 100%;
                    }

                    .photo-title {
                        font-size: 18px;
                        line-height: 23.4px;
                        @include mb(8px);

                        @include media-breakpoint-up(md) {
                            line-height: 28.6px;
                            font-size: 22px;
                        }

                        @include media-breakpoint-up(xl) {
                            @include m(1rem 0);
                        }
                    }

                    .photo-description {
                        line-height: 24px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        @include media-breakpoint-up(xl) {
                            -webkit-line-clamp: 3;
                        }
                    }
                }
            }
        }
    }

    &__footer-photo-gallery {
        @include pt(40px);
        @include pr(20px);
        @include pl(20px);

        @include media-breakpoint-up(md) {
            @include pt(60px);
            @include pb(50px);
        }

        &--splide {
            .splide {
                &__slide {
                    width: 84% !important;
                    overflow: hidden;
                    border-radius: 10px;

                    img {
                        transition: .3s ease;
                        width: 100%;
                        object-fit: cover;
                        left: 0;
                        margin: 0;
                        height: 100%;
                    }
                }

                &__arrows {
                    display: none;
                }
            }
        }

        &--desktop {
            display: none;

            @include media-breakpoint-up(md) {
                @include media-breakpoint-up(md) {
                    display: flex;
                }
            }
        }

        &--mobile {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &--items {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 0px;

            .photo-gallery-item {
                width: 124px;
                border-radius: 10px;

                @include media-breakpoint-up(xl) {
                    width: 193px;
                }

                @include media-breakpoint-up(xxl) {
                    width: 196px;
                }

                &:not(:last-child) {
                    @include mr(20px);
                }

                &:first-child {
                    grid-row-start: 1;
                    grid-column-start: 1;
                    grid-row-end: 3;
                    grid-column-end: 2;
                    height: 230px;

                    @include media-breakpoint-up(xl) {
                        height: 270px;
                    }

                    img {
                        height: 230px;

                        @include media-breakpoint-up(xl) {
                            height: 270px;
                        }
                    }
                }

                &:nth-child(2) {
                    grid-row-start: 1;
                    grid-column-start: 2;
                    grid-row-end: 2;
                    grid-column-end: 3;
                    height: 105px;

                    @include media-breakpoint-up(xl) {
                        height: 125px;
                    }

                    img {
                        height: 105px;

                        @include media-breakpoint-up(xl) {
                            height: 125px;
                        }
                    }
                }

                &:nth-child(3) {
                    grid-row-start: 2;
                    grid-column-start: 2;
                    grid-row-end: 3;
                    grid-column-end: 3;
                    height: 105px;
                    @include mt(10px);

                    @include media-breakpoint-up(xl) {
                        height: 125px;
                    }

                    img {
                        height: 105px;

                        @include media-breakpoint-up(xl) {
                            height: 125px;
                        }
                    }
                }

                &:nth-child(4) {
                    grid-row-start: 1;
                    grid-column-start: 3;
                    grid-row-end: 3;
                    grid-column-end: 4;
                    height: 230px;

                    @include media-breakpoint-up(xl) {
                        height: 270px;
                    }

                    img {
                        height: 230px;

                        @include media-breakpoint-up(xl) {
                            height: 270px;
                        }
                    }
                }

                img {
                    border-radius: 10px;
                    object-fit: cover;
                }
            }
        }

        &--headline {
            font-size: 35px;
            line-height: 42px;

            @include media-breakpoint-up(md) {
                font-size: 50px;
                line-height: 60px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 60px;
                line-height: 72px;
            }
        }

        &--subheadline {
            @include mt(15px);
            color: $gray;
            font-size: 16px;
            line-height: 25.6px;

            @include media-breakpoint-up(md) {
                font-size: 15px;
                line-height: 22.5px;
                @include mt(25px);
            }

            @include media-breakpoint-up(xxl) {
                font-size: 18px;
                line-height: 27px;
            }
        }

        &--btn-c {
            display: flex;
            flex-direction: column;
            @include mt(15px);

            @include media-breakpoint-up(md) {
                align-items: baseline;
                @include mt(35px);
            }

            @include media-breakpoint-up(xl) {
                @include mt(50px);
            }

            a {
                &:hover {
                    background-color: $gray-20;
                }
            }
        }
    }

    .cta-section {
        &__cta-banner {
            .btn-arrow-ghost--white {
                &.outline {
                    &:hover {
                        background-color: $bright-purple-40;
                    }
                }
            }
        }
    }
}
