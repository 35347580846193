<script setup>
import { defineProps, onMounted, ref } from 'vue'

const props = defineProps({
    magazines: {
        type: Array,
        required: true,
        default: () => []
    }
})

const displayMagazinesList = ref(false)
const issueTitle = ref('')
const issueFileUrl = ref('')
const boxFileId = ref(null)
const magazinesListWrapper = ref(null)

const getFileurl = (link) => {
    return decodeURIComponent(link)
}

const splitTitle = (title) => {
    const parts = title.split('#', 2)
    return `Issue #${parts.length > 1 ? parts[1] : ''}`
}

const updateIssueData = (title, file) => {
    issueTitle.value = splitTitle(title)
    issueFileUrl.value = getFileurl(file)
    displayMagazinesList.value = false
}

const openMagazineFile = () => {
    window.open(`${issueFileUrl.value}`, "_blank")
}

const displayMagazinePreview = (token) => {
    const preview = new Box.Preview()

    preview.show(boxFileId.value, token, {
        container: ".preview",
        showDownload: true,
    })
}

const generateToken = async () => {
    const response = await fetch('/ajax/generate-box-api-token')
    const data = await response.json()
    return data?.access_token || null
}

onMounted(async() => {
    issueTitle.value = splitTitle(props.magazines[1].title)
    issueFileUrl.value = getFileurl(props.magazines[1].file)
    boxFileId.value = props.magazines[0].box_id || '1592193064647'

    if (boxFileId.value) {
        const token = await generateToken()
        if (token) {
            displayMagazinePreview(token)
        }
    }

    document.addEventListener('click', function(e) {
        if (e.target !== magazinesListWrapper.value) {
            displayMagazinesList.value = false
        }
    })
})
</script>

<template>
    <div id="blackSection1" class="black-background first-black logeek-magazine-lp">
        <div class="logeek-magazine container">
            <div class="logeek-magazine__header-black">
                <h1 class="text-white">
                    LoGeek Magazine
                </h1>
                <div class="row header-content">
                    <div class="col-12 col-lg-7 col-xl-6 content-left">
                        <h2 class="text-white">
                            In this issue
                        </h2>
                        <p class="mt-3">
                            We’re happy to share the 17th issue of LoGeek Magazine, part of the Luxoft Technology Series (LTS). This magazine talks about the latest trends and technologies in the IT world and the different types of coding that IT specialists use. We hope this issue of LoGeek Magazine will give you some fun and useful information.
                            <br>
                            So, sit back and turn the (virtual) page.
                            <br>
                            Let's start our journey!
                        </p>
                        <div class="mt-4 buttons-container desktop">
                            <a :href="getFileurl(props.magazines[0].file)" class="btn-arrow-ghost--white outline" target="_blank" id="latest-magazine">
                                {{ props.magazines[0].title }}
                                <img src="/theme/luxoft/assets/images/icons/download.svg" alt="download icon">
                            </a>
                            <div class="social-buttons-wrapper">
                                <button class="job-short-details__actions__share social-share" type="button" data-network="linkedin" data-source="logeek-magazine">
                                    <img src="/theme/luxoft/assets/images/icons/linkedin-gray.svg" alt="linkedin icon">
                                </button>
                                <button class="job-short-details__actions__share social-share" type="button" data-network="twitter" data-source="logeek-magazine">
                                    <img src="/theme/luxoft/assets/images/icons/xtwitter-gray.svg" alt="x icon">
                                </button>
                                <button class="job-short-details__actions__share social-share" type="button" data-network="fb" data-source="logeek-magazine">
                                    <img src="/theme/luxoft/assets/images/icons/facebook-gray.svg" alt="facebook icon">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 col-xl-6 content-right">
                        <div class="preview"></div>
                    </div>
                    <div class="mt-4 buttons-container mobile">
                        <a :href="getFileurl(props.magazines[0].file)" class="btn-arrow-ghost--white outline" target="_blank">
                            {{ props.magazines[0].title }}
                            <img src="/theme/luxoft/assets/images/icons/download.svg" alt="download icon">
                        </a>
                        <div class="social-buttons-wrapper">
                            <button class="job-short-details__actions__share social-share" type="button" data-network="linkedin" data-source="logeek-magazine">
                                <img src="/theme/luxoft/assets/images/icons/linkedin-gray.svg" alt="linkedin icon">
                            </button>
                            <button class="job-short-details__actions__share social-share" type="button" data-network="twitter" data-source="logeek-magazine">
                                <img src="/theme/luxoft/assets/images/icons/twitter-gray.svg" alt="x icon">
                            </button>
                            <button class="job-short-details__actions__share social-share" type="button" data-network="fb" data-source="logeek-magazine">
                                <img src="/theme/luxoft/assets/images/icons/facebook-gray.svg" alt="facebook icon">
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mt-5">
                    <div class="logeek-magazine__download-options">
                        <div>
                            <p class="text-white">
                                Download previous issues of LoGeek Magazine here
                            </p>
                        </div>
                        <div class="download-option-btn">
                            <div
                                class="magazines-list-wrapper"
                                :class="{ 'active': displayMagazinesList }"
                                @click.stop="displayMagazinesList = !displayMagazinesList"
                                ref="magazinesListWrapper"
                            >
                                <button class="default-magazine">{{ issueTitle }}</button>
                                <div
                                    class="magazines-list"
                                    :class="{ 'active': displayMagazinesList }"
                                >
                                    <div
                                        class="single" v-for="magazine in props.magazines.slice(1)"
                                        @click.stop.prevent="updateIssueData(magazine.title, magazine.file)"
                                    >
                                        <button>
                                            {{ splitTitle(magazine.title) }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button class="download-magazine-btn" @click="openMagazineFile">
                                <img src="/theme/luxoft/assets/images/icons/download.svg" alt="download icon">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="whiteSection" class="white-background logeek-magazine-lp">
        <section class="container career-legacy">
            <h2 class="text-center">
                Become an author
            </h2>
            <p class="text-center">
                Submit an article for the next issue of LoGeek Magazine
            </p>
            <div class="career-legacy__cards mt-2">
                <div class="career-legacy__cards__card">
                    <h3 class="career-legacy__cards__card--title">
                        Why contribute
                    </h3>
                    <p class="career-legacy__cards__card--description">
                        Share your opinions and expertise through LoGeek Magazine and show off your professional knowledge to a global community of about 100,000 readers. It’s a great way to share your expert ideas and opinions and build a reputation as an IT thought leader. Also, you’ll earn 100 Loyalty points if your article is published!
                    </p>
                </div>
                <div class="career-legacy__cards__card">
                    <h3 class="career-legacy__cards__card--title">
                        Article requirements
                    </h3>
                    <p class="career-legacy__cards__card--description">
                        To qualify for publication, articles must be original, exclusive to LoGeek Magazine and based on your personal experience and professional expertise. Make sure you can cover your planned topic in 1,200-1,800 words. Write in a corporate blog or white paper style (but make sure it’s easily understandable).
                    </p>
                </div>
                <div class="career-legacy__cards__card">
                    <h3 class="career-legacy__cards__card--title">
                        How to apply
                    </h3>
                    <p class="career-legacy__cards__card--description">
                        Send us a pitch for your article idea at <a href="mailto:employer_branding@luxoft.com">employer_branding@luxoft.com</a>. Once your idea’s approved, write the first draft and review it with a project account manager, then make your edits. Once the final version is approved, all you have to do is wait for your article to be published.
                    </p>
                </div>
            </div>
        </section>
        <p></p>
        <div class="apply-here-link-container mt-4">
            <a class="btn-arrow-ghost--rich-black" href="mailto:employer_branding@luxoft.com">SEND YOUR PITCH</a>
        </div>
    </div>

    <div id="blackSection2" class="black-background logeek-magazine-lp second-black">
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.form-error-container {
    margin-top: 10px;
}

.form-error-container .error-message {
    color: #f04438 !important;
    font-size: 12px !important;
}
</style>
