.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    &__link {
        display: flex;
        width: 44px;
        height: 44px;
        padding: 12px 6px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: transparent;
        color: $dark-gray;
        text-decoration: none;

        &.active {
            background-color: $primary;
            color: $white;
        }
    }
}
