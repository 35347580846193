@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import "bootstrap/scss/functions";
@import "custom-variables";
@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap-utilities";
@import "bootstrap/scss/utilities/api";
@import "bootstrap/scss/bootstrap-reboot";
@import "custom";
@import "general-classes";
@import "modules/menu";
@import "modules/pagination-buttons";
@import "modules/filters_and_chips";
@import "modules/main";
@import "modules/breadcrumbs";
@import "contact-form";
@import "modules/scrollspy";
@import "error-blocks";
@import "blogs";

/*
* Remember mobile First, we should try to use the media queries from the smaller to the bigger.
* This way is more readable.
    Recommended:
    @include media-breakpoint-up(sm) {
    }

    @include media-breakpoint-up(md) {
    }

    @include media-breakpoint-up(xl) {
    }

    @include media-breakpoint-up(xxl) {
    }

    Instead of:
    @include media-breakpoint-down(xxl) {
    }

    @include media-breakpoint-down(xl) {
    }

    @include media-breakpoint-down(md) {
    }

    @include media-breakpoint-down(sm) {
    }
*/
