.white-background,
.black-background,
.single-event__footer-photo-gallery {
    .splide__pagination--custom {
        gap: 10px;
        padding-top: 15px !important;
        padding-bottom: 20px !important;
        align-items: center;

        li {
            margin: 0 !important;
            display: flex !important;
            align-items: center !important;
        }

        &__item {
            margin: 0;
            padding: 0px;
            border-radius: 50%;
            width: 6px;
            height: 6px;

            &.is-active {
                padding: 3px;
            }
        }
    }
}
.white-background,
.single-event__footer-photo-gallery {
    .splide__pagination--custom {
        &__item {
            border: 2px solid transparent;
            background-color: $gray;

            &.is-active {
                background-color: $white;
                border: 2px solid $gray;
            }
        }
    }
}

.black-background {
    .splide__pagination--custom {
        &__item {
            border: 2px solid transparent;
            background-color: $gray;

            &.is-active {
                background-color: transparent;
                border: 2px solid $gray;
            }
        }
    }
}
