.locations-regions-header {
    position: fixed;
    border-bottom: 1px solid $gray-e6e6;
    z-index: 4;
    width: 100%;
    background-color: $white;
    top: 88px;

    .anchors-header {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 15px 0;

        @include media-breakpoint-down(xxl) {
            max-width: 970px;
        }

        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        &__anchor {
            position: relative;
            display: flex;
            white-space: nowrap;
            align-items: center;
            justify-content: center;
            padding: 13px 20px;
            border-radius: 15px;
            border: 1.5px solid $gray-e6e6;

            a {
                color: $gray;
                font-size: 14px;
                line-height: 140%;
            }

            &:hover {
                background-color: $gray-90;
                border-color: $gray-ccc;

                a {
                    color: $dark-gray-15;
                }
            }

            &.active {
                background-color: $primary;
                border-color: transparent;

                a {
                    color: $white;
                }
            }
        }
    }
}
