@mixin filter-input-search() {
    .filter-input-search {
        border: 1px solid $gray-b3b3;
        display: flex;
        @include p(15px);
        border-radius: 15px;

        input {
            outline: none;
            border: none;
            height: 30px;
            color: $black;
            width: -webkit-fill-available;
            @include pl(8px);

            &:focus,
            &:active {
                border: none;
            }
        }

        button {
            border: none;
            background-color: transparent;
        }
    }
}
