.careers-with-impact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 45px;
    padding-top: 80px;

    @include media-breakpoint-down(md) {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 160px;
        text-align: center;
        row-gap: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-down(xxl) {
            padding: 0 230px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 40px;
        }

        @include media-breakpoint-down(md) {
            text-align: left;
            padding: 0 20px;
            font-size: 16px;
        }

        h2 {
            margin: 0;
            color: $text-rich-black;
        }

        p {
            color: $gray-66;
            margin: 0;
            font-size: 20px;

            @include media-breakpoint-down(xxl) {
                font-size: 18px;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 160%;
                font-weight: $font-weight-normal;
            }
        }

        button {
            font-size: $font-size-14;
            text-transform: none;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        height: 100%;
        margin-top: 25px;

        @include media-breakpoint-down(md) {
            padding: 0 20px;
        }
        figure {
            margin: 0;
            height: 100%;

            img {
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
                height: 610px;
                border-radius: 30px;

                @include media-breakpoint-down(xxl) {
                    height: 600px;
                }

                @include media-breakpoint-down(xl) {
                    height: 410px;
                }

                @include media-breakpoint-down(md) {
                    height: 260px;
                    border-radius: 20px;
                }

                @media (max-width: 600px) {
                    height: 210px;
                }
            }
        }

        &__location {
            display: flex;
            gap: 15px;
            align-items: center;
            position: absolute;
            padding: 5px;
            padding-right: 25px;
            bottom: 20px;
            right: 20px;
            border-radius: 15px;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
            backdrop-filter: blur(6px);

            @include media-breakpoint-down(xl) {
                right: 20px;
            }

            @include media-breakpoint-down(md) {
                right: 30px;
                bottom: 10px;
            }

            &--icon {
                display: flex;
                padding: 10px;
                background-color: $white;
                border-radius: 10px;
                box-shadow: 0px 2px 5px 0px rgba(21, 21, 21, 0.08);
                height: 40px !important;
                width: 40px;

                img {
                    border-radius: 0 !important;
                    object-fit: contain !important;
                    height: auto !important;
                }
            }

            &--text {
                p {
                    margin-bottom: 0;
                    color: $text-rich-black;
                    font-weight: $font-weight-semibold;
                    font-size: 14px;

                    @include media-breakpoint-down(md) {
                        font-size: 12px;
                        line-height: 125%;
                    }
                }
            }
        }
    }
}
