.search-results {
    &.white-background {
        @include p(24px 20px 50px);

        @include media-breakpoint-up(md) {
            @include p(50px 0px);
        }
    }

    &__header-black {
        @include pt(130px);
        @include mb(60px);
        @include pl(20px);
        @include pr(20px);

        @include media-breakpoint-up(md) {
            @include pt(168px);
            @include mb(60px);
            @include pl(0);
            @include pr(0);
        }

        .global-search {
            display: flex;
            background-color: $white;
            padding: 10px;
            border-radius: 20px;
            box-sizing: border-box;
            border: 1.5px solid transparent;
            width: 100%;
            font-size: 14px;
            gap: 10px;

            &--input {
                width: 100%;
                border: none;
                padding: 15px;
                caret-color: $purple;
                color: $black;

                &:focus-visible {
                    border: none;
                    outline: none;
                }
            }

            .btn-normal {
                @include p(10px 13px);
                width: 52px;
            }
        }
    }

    &__header-white {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .searched-word {
            font-size: 18px;
            font-weight: $font-weight-semibold;

            @include media-breakpoint-up(md) {
                font-size: 30px;
            }
        }
    }

    &__items-wrapper {
        .single-search {
            border-top: 1px solid $gray-e6e6;

            &__content {
                @include p(24px 0 34px);
                display: flex;
                flex-direction: column;
                gap: 12px;

                @include media-breakpoint-up(md) {
                    @include p(30px 30px 44px 30px);
                }

                .title {
                    font-size: 18px;
                    font-weight: $font-weight-semibold;

                    @include media-breakpoint-up(md) {
                        font-size: 22px;
                    }
                }

                .excerpt {
                    font-size: 14px;
                    color: $dark-gray;
                    line-height: 19.6px;
                }
            }
        }
    }
}
