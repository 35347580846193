.banner-text {
    &.grey-background,
    &.white-background
    {
        padding: 40px 0 40px 10px;
        border-radius: 0;

        @include media-breakpoint-up(md) {
            padding: 40px 0;
        }

        .container {
            @include media-breakpoint-up(xxl) {
                max-width: 1300px;
            }

            @include media-breakpoint-down(xxl) {
                max-width: 1280px;
            }
            @include media-breakpoint-down(xl) {
                max-width: 864px;
            }

            @include media-breakpoint-down(md) {
                max-width: 100%;
                width: auto;
                overflow: hidden;
                position: relative;
            }
        }

        h3 {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 20px;
            text-align: left;
            font-weight: 600;
            padding-left: 15px;

            @include media-breakpoint-up(md) {
                font-size: 50px;
                line-height: 60px;
                margin-bottom: 50px;
                text-align: center;
                padding: 0;
            }

            @include media-breakpoint-up(xl) {
                font-size: 55px;
                line-height: 66px;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 60px;
                line-height: 72px;
            }
        }

        .banner-text__button {
            margin: 25px 0 0 0;
            width: 95%;
            display: block;

            @include media-breakpoint-up(bsm) {
                margin: 25px auto 0;
                width: fit-content;
            }

            @include media-breakpoint-up(md) {
                padding: 15px 25px;
            }
        }

        .banner-text__item {
            flex: 0 0 80%;
            width: 300px;
            height: auto;
            background-color: $white;
            border-radius: 20px;
            padding: 15px;

            @include media-breakpoint-up(bsm) {
                width: 100%;
            }

            @include media-breakpoint-up(md) {
                display: flex;
                gap: 40px;
                margin-top: 25px;
                width: auto;
                background-color: transparent;
                border-radius: 0;
                padding: 0;
            }

            .banner-text__item__text {
                display: flex;
                flex-direction: column;
                gap: 10px;

                @include media-breakpoint-up(md) {
                    gap: 25px;
                    padding: 15px 40px 15px 0;
                }

                h5 {
                    font-size: 18px;
                    line-height: 23.4px;
                    font-weight: 600;
                    letter-spacing: 0;

                    @include media-breakpoint-up(md) {
                        font-size: 35px;
                        line-height: 1.2;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: 45px;
                    }

                    @include media-breakpoint-up(xxl) {
                        font-size: 55px;
                    }
                }

                p {
                    font-size: 15px;
                    line-height: 24px;
                    color: $dark-gray;

                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                        line-height: 27px;
                    }
                }
            }

            .banner-text__item__image {
                margin: 0;

                img {
                    display: none;

                    @include media-breakpoint-up(md) {
                        display: block;
                        min-width: 422px;
                        height: 350px;
                        border-radius: 30px;
                    }

                    @include media-breakpoint-up(xl) {
                        min-width: 620px;
                    }

                    @include media-breakpoint-up(xxl) {
                        min-width: 630px;
                    }
                }
            }
        }

        .banner-text__wrapper {
            display: flex;
            transition: transform 0.5s ease;
            gap: 15px;

            @include media-breakpoint-up(md) {
                display: initial;
                transition: none;
            }
        }

        .banner-text__dots {
            text-align: center;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-up(md) {
                display: none;
            }

            .dot {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: $dark-gray;
                border-radius: 50%;
                margin: 0 5px;
                cursor: pointer;
            }

            .dot.active {
                border: 3px solid $dark-gray;
                background-color: $gray-90;
                width: 15px;
                height: 15px;
            }

            p {
                visibility: hidden;
                position: absolute;
            }
        }
    }
}

.banner-text {
    &.white-background {
        .container {
            .banner-text__wrapper {
                .banner-text__item {
                    background-color: $gray-90;

                    @include media-breakpoint-up(md) {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
