.career-legacy {
    display: flex;
    flex-direction: column;
    gap: 45px;

    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }

    &.container {
        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }

        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    h2 {
        color: $text-rich-black;
    }

    &__cards {
        display: flex;
        gap: 20px;

        @include media-breakpoint-down(xl) {
            flex-direction: column;
        }

        &__card {
            border-radius: 20px;
            padding: 30px;
            border: 1.5px solid $gray-e6e6;
            background: $white;
            width: 33%;
            transition: box-shadow 0.3s;

            @include media-breakpoint-up(md) {
                &:hover {
                    border: 1.5px solid transparent;
                    box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);
                }
            }

            @include media-breakpoint-down(xl) {
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                padding: 20px;
            }

            &--title {
                color: $text-rich-black;
                font-size: 22px;
                font-style: normal;
                font-weight: $font-weight-semibold;
                line-height: 130%;
                margin-bottom: 15px;

                @include media-breakpoint-down(xxl) {
                    width: 60%;
                }

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                    width: 100%;
                }
            }

            &--description {
                color: $dark-gray;
                font-size: 15px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 150%;
                margin-bottom: 0;
            }
        }
    }
}
