.error_blocks {
    @include mt(140px);

    &__wrapper {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        height: 492px;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            height: 386px;
        }

        @include media-breakpoint-down(md) {
            height: 240px;
        }

        @include media-breakpoint-down(sm) {
            height: 410px;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 0;
            transform: translate(-50%, -50%);
            object-fit: cover;
        }
    }

    &__content {
        margin: 0 auto;

        h6 {
            font-size: (1.375rem);
            font-weight: $font-weight-normal;
        }
    }

    &__btn {
        @include mt(50px); 

        .btn {
            @include mt(20px); 
        }
    }

    
}