.container-breadcrumbs {
	@include media-breakpoint-up(md) {
		display: block;
	}

	.breadcrumb {
		&-item {
			a {
				text-decoration: none;
				color: $gray;

				&:hover {
					color: $gray-80;
				}
			}

			&.active {
				color: $gray-80;
			}
		}

		@include media-breakpoint-down(md) {
			:not(:nth-child(1)) {
				&::before {
					content: '<';
					color: $gray-80;
				}
			}
		}
	}

	&--light {
		.breadcrumb {
			@media screen and (max-width: $screen-size-small-max) {
				margin-left: $breadcrumb-margin;
			}

			&-item {
				a {
					text-decoration: none;
					color: $dark-gray;

					&:hover {
						color: $gray-30;
					}

					&:active {
						color: $dark-gray;
					}

					&:enabled {
						color: $dark-gray;
					}
				}

				&.active {
					color: $gray-30;
				}
			}

			@include media-breakpoint-down(md) {
				display: block;

				.breadcrumb-item {
					display: inline;
					word-wrap: break-word;
					white-space: break-spaces;
				}

				:not(:nth-child(1)) {
					&::before {
						content: '<';
						color: $gray-30;
						float: none;
					}
				}
			}
		}
	}
}
