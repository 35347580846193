.scrollspy-list{
    .list-group-item {
        color: $gray-70;
        border: none;
        @include pl(3);
        height: 40px;
        border-left: 1px solid $gray-70;
        vertical-align: middle;

        &.active {
            border-left: 4px solid $gray-70;
            background-color: inherit;
            font-weight: $font-weight-bold;
            color: $gray-50;
            margin-left: -1px;
        }
    }
}