.worlds-best-workplace {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 45px 0px;

    &.container {
        @include media-breakpoint-up(xl) {
            max-width: 1280px;
        }
    }

    @include media-breakpoint-down(md) {
        text-align: left;
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 250px;
        text-align: center;
        row-gap: 25px;
        @include mb(45px);

        @include media-breakpoint-down(xl) {
            padding: 0 80px;
        }

        @include media-breakpoint-down(md) {
            text-align: left;
            max-width: 100vw;
            padding: 0 20px;
        }

        &--single-event {
            @include p(0);
            @include mb(25px);

            @include media-breakpoint-up(md) {
                @include mb(45px);
                @include p(0 65px);
            }

            @include media-breakpoint-up(xl) {
                @include p(0 100px);
            }

            @include media-breakpoint-up(xxl) {
                @include p(0 160px);
            }
        }

        h2 {
            margin: 0;
            color: $text-rich-black;
        }

        p {
            color: $gray-66;
            margin: 0;
            font-size: 20px;

            @include media-breakpoint-down(xxl) {
                font-size: 18px;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 160%;
                font-weight: $font-weight-normal;
            }
        }
    }

    &__cards {
        display: flex;
        gap: 20px;

        @include media-breakpoint-down(xl) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
        }

        &--single-event {
            @include p(0);

            @include media-breakpoint-up(md) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;
            }

            .single-event-card {
                @include media-breakpoint-up(md) {
                    &:first-child {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    &:nth-child(2) {
                        grid-area: 1 / 2 / 2 / 3;
                    }

                    &:nth-child(3) {
                        grid-area: 2 / 1 / 3 / 3;
                    }
                }

                @include media-breakpoint-up(xl) {
                    &:first-child,
                    &:nth-child(2),
                    &:nth-child(3) {
                        grid-area: none;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                display: flex;
                gap: 20px;
            }
        }

        &__card {
            border-radius: 30px;
            padding: 30px;
            width: 25%;
            min-height: 347px;
            position: relative;
            overflow: hidden;

            @include media-breakpoint-down(xl) {
                min-height: auto;
                width: 100%;
            }

            &.green {
                background-color: #00968f;

                p {
                    color: #ccfaff;
                }
            }

            &.blue {
                background-color: #0e56e9;

                p {
                    color: #e5eeff;
                }
            }

            &.purple {
                background-color: #9d4dff;

                p {
                    color: $white;
                }
            }

            h3 {
                font-size: 24px;
                color: $white;
                margin-bottom: 15px;
                font-weight: 500;
                padding-right: 70px;
            }

            p {
                font-size: 15px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 140%; /* 21px */
                opacity: 0.8999999761581421;
            }

            .svg {
                position: absolute;
                bottom: 0;
                right: 0;
            }

            &:last-child {
                display: flex;
                padding: 25px 30px 30px 30px;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                background-color: $orange-70;

                h3 {
                    padding: 0;
                    font-weight: $font-weight-bold;
                    color: $text-rich-black;
                }
            }

            &--v2 {
                border-radius: 30px;
                padding: 30px;
                width: 25%;
                min-height: 372px;
                position: relative;
                overflow: hidden;
                background-color: $gray-90;

                &.single-event-card {
                    @include media-breakpoint-up(md) {

                    }

                    @include media-breakpoint-up(xl) {
                        width: 33%;
                    }
                }

                @include media-breakpoint-down(xl) {
                    min-height: auto;
                    width: 100%;
                }

                @include media-breakpoint-down(md) {
                    border-radius: 20px;
                    padding: 20px;
                }

                svg {
                    margin-bottom: 35px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 20px;
                    }
                }

                h3 {
                    font-size: 22px;
                    font-style: normal;
                    font-weight: $font-weight-semibold;
                    line-height: 130%;
                    color: $purple;
                    margin-bottom: 15px;
                    width: 65%;

                    @include media-breakpoint-down(xxl) {
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 20px;
                        line-height: 140%;
                    }
                }

                p {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: $font-weight-normal;
                    line-height: 160%;
                    color: $dark-gray;
                    margin-bottom: 0;

                    @include media-breakpoint-down(md) {
                        font-size: 15px;
                        line-height: 160%;
                    }
                }

                a {
                    text-align: center;
                    justify-content: center;

                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }

            &--v2:last-child:not(.single-event-card) {
                display: flex;
                padding: 25px 30px 30px 30px;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                background-color: $purple;

                @include media-breakpoint-down(md) {
                    padding: 20px;
                }

                h3 {
                    padding: 0;
                    font-weight: $font-weight-semibold;
                    color: $white;
                    width: 100%;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 40px;
                    }
                }

                svg {
                    margin-bottom: 0;
                }
            }
        }
    }
}
