.white-background.event {
    gap: 0;
    padding: 0;
}

.form-title,
.form-legend {
    span {
        color: #f04438;
    }
}

.form-title {
    padding-top: 40px;
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
        padding-top: 20px;
    }
}

.form-legend {
    height: 40px;
    margin-bottom: 20px;
    font-size: 14px;
    color: $dark-gray;
    display: flex;
    align-items: center;
    gap: 5px;
}

.form {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    gap: 20px;

    &__fields-container {
        display: flex;
        row-gap: 20px;
        column-gap: 30px;
        flex-wrap: wrap;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        &__divider {
            width: 100%;
            background-color: $gray-e6e6;
            height: 1px;
            margin-top: -10px;
        }

        &__field-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: calc(50% - 15px);

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &.full-width {
                width: 100%;
            }

            .consent-without-checkbox {
                display: none;
            }

            &--label {
                font-size: 14px;
                font-weight: $font-weight-normal;
                line-height: 18px;
                color: $dark-gray;

                &.required::after {
                    content: "*";
                    color: red;
                }
            }

            &--input {
                width: 100%;
                border-radius: 10px;
                border: 0.08em solid $gray-b3b3;
                padding: 8px 10px 8px 15px;
                color: #000;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.28px;
                height: 40px;

                &::placeholder {
                    color: $gray;
                }
                &:focus {
                    outline: none;
                }
            }

            .custom-file-input {
                cursor: pointer;
                border: 0.08em solid $primary;
                color: $primary;
                border-radius: 10px;
                width: 147px;
                height: 40px;
                display: flex;
                gap: 5px;
                font-size: 13px;
                font-weight: $font-weight-semibold;
                line-height: 140%;
                letter-spacing: -0.26px;
                align-items: center;
                padding: 10px 15px 10px 5px;

                input[type="file"] {
                    display: none;
                }
            }

            &--consent-text {
                color: $dark-gray;
                font-feature-settings: "clig" off, "liga" off;
                line-height: 16px;
                font-size: 13px;
            }

            &__collapse {
                &--button {
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                    line-height: 18px;
                    font-feature-settings: "clig" off, "liga" off;
                    color: $dark-gray;
                    display: flex;

                    &:hover {
                        color: $dark-gray;
                    }
                }

                &--button.collapsed {
                    & > svg {
                        transform: rotate(180deg);
                        z-index: -1;
                    }
                }

                &--text {
                    margin-top: 10px;
                    color: $dark-gray;
                    font-feature-settings: "clig" off, "liga" off;
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .number-currency-container {
                position: relative;
            }

            .custom-select.currency-select {
                width: auto;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);

                .select-selected {
                    background-color: $bright-purple-90;
                    font-size: 14px;
                    font-weight: $font-weight-semibold;
                    padding: 2px 3px;
                    width: 53px;
                    border: none;
                    border-radius: 5px;
                    height: 24px;
                    color: $primary;
                    cursor: pointer;

                    &::after {
                        right: 4px;
                        background: url("/theme/luxoft/assets/images/icons/chevron-purple-up.svg");

                        @include media-breakpoint-down(md) {
                            right: 0px;
                        }
                    }
                }
            }

            .custom-select {
                width: 100%;
                position: relative;

                &.small {
                    width: 70px;
                }
            }

            .custom-select select {
                display: none;
            }

            .select-selected {
                background-color: transparent;
                border-radius: 10px;
                border: 0.08em solid $gray-b3b3;
                padding: 8px 10px 8px 15px;
                font-size: 14px;
                letter-spacing: -0.28px;
                line-height: 140%;
                color: $dark-gray-15;
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            .select-selected:after {
                position: absolute;
                content: "";
                background: url("/theme/luxoft/assets/images/icons/chevron-black-up.svg");
                width: 20px;
                height: 22px;
                right: 10px;
            }

            .select-selected.select-arrow-active {
                background: $gray-90;
            }

            .select-selected.select-arrow-active::after {
                transform: rotate(180deg);
            }

            .select-items div {
                color: $dark-gray-15;
                padding: 15px 20px;
                cursor: pointer;
            }

            .currency-select .select-items {
                width: 366px;
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
                column-gap: 6px;
                row-gap: 9px;

                & > div {
                    padding: 5px;
                    color: $primary;
                    width: 38px;
                    height: 24px;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: $font-weight-semibold;
                    border-radius: 5px;

                    &:hover {
                        background-color: $bright-purple-90;
                    }

                    &.same-as-selected {
                        background-color: $bright-purple-90;
                    }
                }
            }

            .select-items {
                position: absolute;
                background-color: $white;
                border-radius: 15px;
                max-height: 280px;
                top: 38px;
                left: 0;
                right: 0;
                z-index: 99;
                box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
                overflow: auto;
            }

            .select-hide {
                display: none !important;
            }

            .select-items div:hover,
            .same-as-selected {
                background-color: $gray-f9;
            }

            &--error {
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 12px;
                line-height: 12px;
                font-feature-settings: "clig" off, "liga" off;
                color: #f04438;

                .clear-resume {
                    display: none;
                }

                &.hidden {
                    opacity: 0;
                }

                .field-error-icon {
                    display: block;
                }

                .field-success-icon {
                    display: none;
                }

                &.success {
                    color: #3b8f1a;

                    .field-success-icon {
                        display: block;
                    }

                    .field-error-icon {
                        display: none;
                    }

                    .clear-resume {
                        border: none;
                        -webkit-appearance: none;
                        background-color: transparent;
                        display: block;

                        img {
                            width: 12px;
                            filter: invert(16%) sepia(66%) saturate(3547%) hue-rotate(262deg) brightness(88%) contrast(95%);
                        }
                    }
                }
            }
        }
    }

    #submit-button {
        height: 55px;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.28px;
        width: 202px;
    }
}

#dropdown-modal {
    .modal-dialog {
        position: fixed;
        bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        max-width: 100%;

        .modal-content {
            border-radius: 0px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            .modal-header {
                border-top-left-radius: 20px;
                border-bottom: none;
                padding: 20px;

                .modal-title {
                    font-weight: $font-weight-semibold;
                    color: $dark-gray-15;
                    text-transform: uppercase;
                    font-size: 18px;
                }

                &__close {
                    border: 0.08em solid $gray-e6e6;
                    border-radius: 10px;
                    padding: 3px;
                }
            }
            #dynamic_content {
                max-height: 220px;
                overflow: auto;
                padding: 0;

                .select-items {
                    div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 15px 20px;
                        font-size: 15px;
                        line-height: 160%;
                        letter-spacing: -0.3px;
                        color: #000;

                        &.same-as-selected {
                            background-color: $bright-purple-90;
                            color: $primary;

                            &::after {
                                content: "";
                                background: url("/theme/luxoft/assets/images/check-purple.svg");
                                height: 24px;
                                width: 24px;
                            }
                        }
                    }
                }
            }
            .modal-footer {
                padding: 20px 20px 44px 20px;
                border-top: none;
                border-radius: 0;

                .btn.btn-primary {
                    padding: 15px 0;
                    width: 100%;
                    border-radius: 15px;
                    text-transform: none;
                }
            }
        }
    }
}

.custom-tooltip {
    .tooltip-inner {
        background-color: $purple;
        font-size: 14px;
    }

    .tooltip-arrow {
        &::before {
            border-right-color: $purple !important;
        }
    }
}
