.hero-locations {
    padding: 0 20px 20px;

    @include media-breakpoint-up(md) {
        padding: 0;
    }

    .container {
        @include media-breakpoint-up(xl) {
            max-width: 1280px;
        }
    }

    &__text-content {
        &__heading {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 20px;

            @include media-breakpoint-up(bsm) {
                text-align: center;
            }

            @include media-breakpoint-up(md) {
                font-size: 60px;
                line-height: 72px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 65px;
                line-height: 78px;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 70px;
                line-height: 84px;
            }
        }

        &__description {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 20px;

            @include media-breakpoint-up(bsm) {
                text-align: center;
            }

            @include media-breakpoint-up(md) {
                font-size: 18px;
                line-height: 27px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 800px;
                margin: 0 auto 25px;
            }

            @include media-breakpoint-up(xxl) {
                max-width: 755px;
            }
        }
    }

    a {
        display: block;

        @include media-breakpoint-up(bsm) {
            width: fit-content;
            margin: 0 auto;
        }
    }

    &__figure {
        margin: 15px 0 0;

        @include media-breakpoint-up(md) {
            margin: 35px 0 0;
        }

        img {
            width: 100%;
            object-fit: cover;
            height: 210px;
            border-radius: 20px;

            @include media-breakpoint-up(bsm) {
                height: 260px;
                border-radius: 30px;
            }

            @include media-breakpoint-up(xl) {
                height: 350px;
            }
        }
    }
}
