// Color system //
$white: #fff;
$black: #000;
$gray: #969696;
$dark-gray: #63666a;

$gray-10: #0f0f0f;
$gray-20: #1a1a1a;
$gray-30: #333333;
$gray-40: #4d4d4d;
$gray-50: $gray;
$gray-60: #808080;
$gray-70: #bfbfbf;
$gray-80: #d9d9d9;
$gray-90: #f2f2f2;
$gray-16: #161616;
$gray-44: #444444;
$gray-66: #666666;
$gray-21: #212121;
$dark-gray-15: #151515;
$gray-20: #202020;
$gray-ccc: #cccccc;
$gray-c3: #c3c3c3;
$pink-ef: #efeaf6;
$gray-f9: #f9f9f9;
$gray-de: #DEE6ED;
$gray-b3b3: #b3b3b3;
$blue-337: #337fff;
$blue-33c: #33ccff;
$blue-006: #006699;
$gray-999: #999999;
$purple-362e: #362e3d;
$purple-f8f3: #f8f3ff;
$grey-f7f7: #f7f7f8;

$grays: (
    "10": $gray-10,
    "20": $gray-20,
    "30": $gray-30,
    "40": $gray-40,
    "50": $gray-50,
    "60": $gray-60,
    "70": $gray-70,
    "80": $gray-80,
    "90": $gray-90,
);

$bright-teal: #00968f;
$bright-blue: #00a3e1;
$orange: #ed9b33;
$navy: #003a79;
$cobalt-blue: #0e56e9;
$red: #dc362e;
$yellow: #f2c94c;
$green: #0fb239;
$purple: #5f249f;
$gray-e6e6: #e6e6e6;
$gray-f8f8: #f8f8f8;
$purple-9d: #9d4dff;
$orange-10: #331d00;
$bright-purple-60: #aa73e6;
$light-purple: #F1E5FF;

$colors: (
    "purple": $purple,
    "teal": $bright-teal,
    "blue": $bright-blue,
    "orange": $orange,
    "black-orange": $orange-10,
    "navy": $navy,
    "cobalt-blue": $cobalt-blue,
    "gray": $gray-50,
    "rich-black": $dark-gray-15,
    "white": $white,
    "bright-purple": $bright-purple-60,
);

$primary: $purple;
$secondary: $black;
$success: $green;
$warning: $yellow;
$danger: $red;
$light: $gray-10;
$dark: $gray-90;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
);

$color-contrast-dark: $black;
$color-contrast-light: $white;

$bright-blue-10: #002533;
$bright-blue-20: #005273;
$bright-blue-30: #00658c;
$bright-blue-40: #0089bf;
$bright-blue-50: $bright-blue;
$bright-blue-60: #1abeff;
$bright-blue-70: #80dbff;
$bright-blue-80: #b2e9ff;
$bright-blue-90: #d9f4ff;

$bright-purple-10: #0f061a;
$bright-purple-20: #260f40;
$bright-purple-30: #361559;
$bright-purple-40: #4d1d80;
$bright-purple-50: $purple;
$bright-purple-70: #d7b2ff;
$bright-purple-80: #ebd9ff;
$bright-purple-90: #f8f2ff;

$bright-teal-10: #001a18;
$bright-teal-20: #003330;
$bright-teal-30: #004d49;
$bright-teal-40: #005955;
$bright-teal-50: $bright-teal;
$bright-teal-60: #00b3aa;
$bright-teal-70: #5ce6df;
$bright-teal-80: #a6fffb;
$bright-teal-90: #d9fffd;

$orange-20: #734100;
$orange-30: #995700;
$orange-40: #cc7c14;
$orange-50: $orange;
$orange-60: #ffb24d;
$orange-70: #ffbd66;
$orange-80: #ffd399;
$orange-90: #ffeed9;

$navy-10: #000c1a;
$navy-20: #001933;
$navy-30: #00254d;
$navy-40: #003166;
$navy-50: $navy;
$navy-60: #005fc4;
$navy-70: #4da3ff;
$navy-80: #99caff;
$navy-90: #e5f2ff;

$cobalt-blue-10: #020e26;
$cobalt-blue-20: #051d4d;
$cobalt-blue-30: #093999;
$cobalt-blue-40: #0b47bf;
$cobalt-blue-50: $cobalt-blue;
$cobalt-blue-60: #3377ff;
$cobalt-blue-70: #80aaff;
$cobalt-blue-80: #b2ccff;
$cobalt-blue-90: #e5eeff;

$red-10: #410e0b;
$red-20: #601410;
$red-30: #8c1d18;
$red-40: #b3261e;
$red-50: $red;
$red-60: #e46962;
$red-70: #ec928e;
$red-80: #f2b8b5;
$red-90: #f9dedc;

$yellow-10: #29240e;
$yellow-20: #443c17;
$yellow-30: #60521c;
$yellow-40: #776627;
$yellow-50: #ac9037;
$yellow-60: #cfad41;
$yellow-70: $yellow;
$yellow-80: #f5d470;
$yellow-90: #f7df94;

$green-10: #043511;
$green-20: #064717;
$green-30: #096b22;
$green-40: #0c8e2d;
$green-50: $green;
$green-60: #22ba49;
$green-70: #48c368;
$green-80: #75d48d;
$green-90: #9fe0b0;

// Text color classes
.text-rich-black {
    color: $dark-gray-15;
}
.text-dark-gray {
    color: $dark-gray;
}
.text-medium-gray {
    color: $gray;
}

// Breakpoints //
$grid-breakpoints: (
    xs: 0,
    sm: 375px,
    bsm: 600px,
    md: 1024px,
    lg: 1239px,
    xl: 1440px,
    xxl: 1920px,
);
$container-max-widths: (
    md: 864px,
    xl: 970px,
    xxl: 1300px,
);

// Typography //
// font size
$font-size-root-sm: 16px;
$font-size-root-md: 18px;
$font-size-root: 18px;
$font-size-root-xl: 20px;

$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.75;
$font-size-md: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 0.85;
$font-size-xl: $font-size-base;
$font-size-xxl: $font-size-base * 1.125;

// font weight
$font-weight-normal: 400; // Regular
$font-weight-semibold: 600; // Semibold
$font-weight-bold: 700; // Bold

$font-weight-base: $font-weight-normal;

// line height
$line-height-base: 1.2; // 120%
$line-height-110: 1.1; // 110%
$line-height-130: 1.3; // 130%
$line-height-160: 1.6; // 160%

// head font size, rem
$h1-font-size: $font-size-base * 3.75;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 3;
$h4-font-size: $font-size-base * 2.25;
$h5-font-size: $font-size-base * 2;

// body font size, rem
$font-size-14: $font-size-base * 0.85;
$font-size-16: $font-size-base;
$font-size-20: $font-size-base * 1.25;
$font-size-24: $font-size-base * 1.5;
$font-size-28: $font-size-base * 1.75;
$font-size-72: $font-size-base * 5.15;
$font-size-sub01: $font-size-base * 3;
$font-size-sub02: $font-size-base * 1.75;

// Buttons //
$border-width-btn: 2px;
$btn-padding-y: 0.875rem;
$btn-padding-x: 2rem;
$btn-font-size: $font-size-20;
$btn-border-radius: 20px;
$btn-focus-box-shadow: 0px 1px 2px rgba($black, 0.3),
    0px 2px 6px 2px rgba(103, 80, 164, 0.2);
$btn-disabled-box-shadow: 0px 4px 4px rgba($black, 0.25);
$btn-press-box-shadow: inset 0px 2px 6px rgba(67, 44, 105, 0.5);
$btn-disabled-opacity: 0.25;

// Spacing //
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.5,
    2: $spacer,
    3: $spacer * 1.5,
    4: $spacer * 2,
    5: $spacer * 2.5,
    6: $spacer * 3,
    7: $spacer * 3.5,
    8: $spacer * 4,
    9: $spacer * 4.5,
    10: $spacer * 5,
    11: $spacer * 7.5,
);

$list-group-border-radius: 0;
$list-group-hover-bg: transparent;

$tooltip-bg: $gray-30;
$tooltip-font-size: $font-size-base;
$tooltip-max-width: 400px;
