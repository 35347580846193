.white-background,
.black-background {
    .latest-opportunities {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 20px;

        @include media-breakpoint-down(xxl) {
            gap: 40px;
        }

        @include media-breakpoint-down(xl) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 40px;
        }

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            overflow: hidden;
        }

        &.container {
            @include media-breakpoint-between(xl, xxl) {
                max-width: 1280px;
            }
        }

        &__left-col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 60px;
            width: 550px;

            @include media-breakpoint-down(xxl) {
                padding-right: 80px;
                width: 510px;
            }

            @include media-breakpoint-down(xl) {
                padding: 0;
                width: 100%;
                align-items: center;
            }

            @include media-breakpoint-down(md) {
                align-items: flex-start;
                text-align: left;
            }

            .carousel-container {
                display: none;
                @include media-breakpoint-down(md) {
                    display: block;
                    margin-bottom: 25px;
                }
            }

            h2 {
                color: $text-rich-black;

                margin-bottom: 25px;

                @include media-breakpoint-down(xl) {
                    text-align: center;
                }

                @include media-breakpoint-down(xxl) {
                    width: 60%;
                }

                @include media-breakpoint-down(md) {
                    text-align: left;
                    padding: 0 20px;
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: 15px;
                }
            }

            p {
                color: $gray-66;
                font-size: 20px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 150%;
                margin-bottom: 40px;

                @include media-breakpoint-down(xxl) {
                    font-size: 18px;
                }

                @include media-breakpoint-down(xl) {
                    text-align: center;
                    width: 80%;
                }

                @include media-breakpoint-down(md) {
                    width: auto;
                    text-align: left;
                    font-size: 16px;
                    line-height: 160%;
                    font-weight: $font-weight-normal;
                    margin-bottom: 25px;
                    padding: 0 20px;
                }
            }

            .button-container {
                @include media-breakpoint-down(md) {
                    padding: 0 20px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

                @media (max-width: 600px) {
                    display: block;
                    width: 100%;
                }
            }
        }

        &__right-col {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;

            @include media-breakpoint-down(xl) {
                width: auto;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &__card {
                text-decoration: none;
                border-radius: 20px;
                border: 1.5px solid $gray-e6e6;
                padding: 25px 30px 30px 30px;
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                transition: box-shadow 0.3s;
                height: 215px;
                justify-content: space-between;

                @include media-breakpoint-up(md) {
                    &:hover {
                        border: 1.5px solid transparent;
                        box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);
                    }
                }

                &__description {
                    h3 {
                        color: $text-rich-black;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: $font-weight-semibold;
                        line-height: 130%;
                        margin-bottom: 10px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    p {
                        color: $dark-gray;
                        font-size: 15px;
                        font-weight: $font-weight-normal;
                        margin-bottom: 30px;

                        @include media-breakpoint-down(xxl) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(md) {
                            padding: 0;
                            margin-bottom: 20px;
                        }
                    }
                }

                &__location {
                    color: $text-rich-black;
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    background: $gray-90;
                    padding: 5px 10px;
                    padding-left: 5px;
                    gap: 6px;
                    font-size: 13px;
                    line-height: 140%;
                }
            }
        }
    }

    #splider-latest-opportunities {
        @include media-breakpoint-up(md) {
            display: none;
        }

        width: 100vw;
        padding-left: 20px;

        .splide__pagination.splide__pagination--custom {
            margin-left: -40px !important;
        }

        .ol-carousel {
            padding: 20px;
            border-radius: 20px;
            border: 1.5px solid $gray-e6e6;
            background-color: $white;
            display: block;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 169px;

            &__title {
                font-size: 18px;
                font-style: normal;
                font-weight: $font-weight-semibold;
                line-height: 130%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                color: $dark-gray-15;
                text-overflow: ellipsis;
                margin-bottom: 5px;
            }

            &__description {
                color: $dark-gray;
                font-size: 15px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 160%;
                margin-bottom: 20px;
            }

            &__location {
                display: flex;
                align-items: center;
                border-radius: 10px;
                background: $gray-90;
                padding: 5px 10px 5px 8px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 140%; /* 16.8px */
                margin-top: auto;

                &__img {
                    &:first-child {
                        margin-right: 6px;
                    }
                }

                &__text {
                    color: $dark-gray-15;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: $font-weight-normal;
                    line-height: 140%;
                }
            }
        }
    }
}

.white-background {
    .latest-opportunities {
        &__left-col {
            h2 {
                color: $text-rich-black;
            }

            p {
                color: $gray-66;
            }
        }

        &__right-col {
            &__card {
                border: 1.5px solid $gray-e6e6;

                @include media-breakpoint-up(md) {
                    &:hover {
                        border: 1.5px solid transparent;
                        box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);
                    }
                }

                &__description {
                    h3 {
                        color: $text-rich-black;
                    }

                    p {
                        color: $dark-gray;
                    }
                }

                &__location {
                    color: $text-rich-black;
                }
            }
        }
    }
}

.black-background {
    .latest-opportunities {
        padding: 0;
        &__left-col {
            h2 {
                color: $white;
                width: 80%;
            }

            p {
                color: $gray;
            }
        }

        &__right-col {
            &__card {
                border: 1.5px solid transparent;
                background-color: $gray-20;

                @include media-breakpoint-up(md) {
                    &:hover {
                        background-color: #2a2a2a;
                    }
                }

                &__description {
                    h3 {
                        color: $white;
                    }

                    p {
                        color: $gray;
                    }
                }

                &__location {
                    background-color: $gray-30;
                    color: $gray;

                    .location-pin-icon {
                        width: 20px;
                        height: 20px;
                    }

                    .bullet-icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    #splider-latest-opportunities {
        .ol-carousel {
            border: 1.5px solid transparent;
            background-color: $gray-20;

            &__title {
                color: $white;
            }

            &__description {
                color: $gray;
            }

            &__location {
                background: $gray-30;

                &__text {
                    color: $gray;
                }
            }
        }
    }
}
