.refer-people-section {
    @include p(20px);

    @include media-breakpoint-up(md) {
        background-color: $gray-100;
        @include p(55px);
        border-radius: 30px;
    }

    &__description {
        @include mb(45px);

        @include media-breakpoint-up(md) {
            text-align: center;
        }

        @include media-breakpoint-up(xxl) {
            @include p(0 80px);
        }

        h2 {
            @include m(0);
            color: $text-rich-black;
        }
    }

    &__content {
        &__left {
            .section-title {
                font-size: 18px;
                @include mb(10px);

                @include media-breakpoint-up(md) {
                    font-size: 30px;
                    @include mb(15px);
                }
            }

            .section-text {
                color: $dark-gray;
                font-size: 15px;
                @include mb(10px);
                line-height: 24px;

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    line-height: 28.8px;
                }

                @include media-breakpoint-up(xl) {
                    @include mb(10px);
                }

                @include media-breakpoint-up(xxl) {
                    @include mb(25px);
                }
            }

            .location-card {
                text-decoration: none;
                background-color: $white;
                border-radius: 25px;
                @include p(25px 30px);
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: box-shadow 0.3s;
                position: relative;
                @include mt(25px);
                box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);

                @include media-breakpoint-up(md) {
                    box-shadow: none;
                }

                svg {
                    height: 31px;
                    width: 31px;
                    transition: transform 0.3s;
                    box-shadow: none;

                    path {
                        stroke: $dark-gray-15;
                    }

                    rect {
                        fill: $dark-gray-15;
                    }
                }

                &:hover {
                    @include media-breakpoint-up(md) {
                        box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);

                        svg {
                            transform: rotate(45deg);
                        }
                    }
                }

                &__text {
                    &--country {
                        font-size: 20px;
                        color: $text-rich-black;
                        font-weight: $font-weight-semibold;
                        line-height: 130%;
                        margin: 0;

                        @include media-breakpoint-down(xxl) {
                            font-size: 18px;
                        }
                    }
                }

                .link-btn {
                    background: transparent;
                    border-radius: 10px;
                    border: 1.5px solid $white;
                    padding: 10px;
                    bottom: 20px;
                    left: 20px;

                    &--hover {
                        display: none;
                    }

                    &:hover {
                        background-color: $white;

                        .link-btn--normal {
                            display: none;
                        }

                        .link-btn--hover {
                            display: block;
                        }
                    }
                }
            }
        }

        &__right {
            position: relative;
            @include mt(30px);

            @include media-breakpoint-up(xl) {
                @include mt(0);
            }

            figure {
                @include m(0);
                height: 100%;

                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 300px;
                    border-radius: 30px;
                    z-index: 0;

                    @media screen and (min-width: 450px) {
                        height: 100%;
                    }

                    @include media-breakpoint-up(md) {
                        height: 550px;
                    }

                    @include media-breakpoint-up(xl) {
                        height: 100%;
                    }

                    @include media-breakpoint-up(xxl) {
                        height: 455px;
                    }
                }
            }

            .text-content {
                border-radius: 30px;
                background-color: $grey-f7f7;
                @include p(20px 20px 30px 20px);
                z-index: 1;
                width: 88%;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: 20px;

                @include media-breakpoint-up(md) {
                    @include p(24px 24px 40px 24px);
                }

                @include media-breakpoint-up(xxl) {
                    bottom: 50px;
                }

                .section-title {
                    font-size: 18px;
                    @include mb(10px);

                    @include media-breakpoint-up(md) {
                        font-size: 30px;
                        @include mb(15px);
                    }
                }

                .section-text {
                    color: $dark-gray;
                    font-size: 15px;
                    @include mb(28px);
                    line-height: 24px;

                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                        line-height: 28.8px;
                    }
                }

                .btn-normal {
                    font-size: 10px;

                    @media screen and (min-width: 350px) {
                        font-size: 12px;
                    }

                    @media screen and (min-width: 420px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .splide {
        padding-left: 20px;
        .splide__track {
            .splide__list {
                .splide__slide {
                    figure {
                        height: 100%;
                        width: 100%;
                        img {
                            border-radius: 20px;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }

    .button-container {
        @include media-breakpoint-up(md) {
            display: none;
        }
        display: flex;
        justify-content: center;
        padding: 0 20px;

        @media (max-width: 600px) {
            display: block;
        }
    }
}
