#pinned-element {
    @include media-breakpoint-down(xl) {
        display: none;
    }
}

#twoColumnsTextAndCards {
    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }
}

.about-us-lp {
    figure {
        @include m(0);
    }
}

.black-background {
    &.about-us-first {
        padding-top: 148px;
        padding-bottom: 80px;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;

        @include media-breakpoint-down(md) {
            padding: 104px 0 25px;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
        }

        .container {
            @include media-breakpoint-between(xl, xxl) {
                max-width: 1280px;
            }
        }
    }

    &.about-us-second {
        @include media-breakpoint-down(md) {
            padding: 30px 20px !important;
        }
        .container {
            @include media-breakpoint-between(xl, xxl) {
                max-width: 1280px;
            }
        }
    }

    .about-us-first__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;

        @include media-breakpoint-down(xl) {
            padding-bottom: 0;
        }

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            gap: 15px;
            padding-bottom: 0;
        }

        h1 {
            color: $white;

            @include media-breakpoint-down(md) {
                width: 80%;
            }
        }

        &__desc {
            display: none;
            color: $gray;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        &__header-imgs {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 20px;
            height: 536px;
            width: 100%;
            margin-top: 90px;

            @include media-breakpoint-down(xl) {
                height: 520px;
                padding: 30px 0;
                margin-top: 60px;
            }

            @include media-breakpoint-down(md) {
                margin-top: 0;
                padding: 25px 0;
                display: none;
            }

            & > * {
                border-radius: 30px;
                background: $gray-20;
            }

            &__img1 {
                height: 100%;
                width: 338px;
                padding: 30px;
                position: relative;
                overflow: hidden;

                @include media-breakpoint-down(xl) {
                    width: calc(100% / 3 - 15px);
                    padding: 25px;
                }

                &--title {
                    color: $white;
                    letter-spacing: -0.44px;
                    margin-bottom: 15px;
                }

                &--desc {
                    color: $gray;
                    letter-spacing: -0.3px;
                }

                &__small-circle {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                &__big-circle {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }

            &__img2 {
                width: 605px;
                height: 340px;

                @include media-breakpoint-down(xl) {
                    width: calc(100% / 3 - 15px);
                    height: 180px;
                }

                figure {
                    width: 100%;
                    height: 100%;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        border-radius: 30px;
                    }
                }
            }

            &__img3 {
                width: 605px;
                height: 176px;
                padding: 30px;
                position: relative;
                overflow: hidden;

                @include media-breakpoint-down(xl) {
                    width: calc(100% / 3 - 15px);
                    height: 260px;
                    padding: 25px;
                }

                &--title {
                    color: $white;
                    letter-spacing: -0.44px;
                    margin-bottom: 15px;
                }

                &--desc {
                    color: $gray;
                    letter-spacing: -0.3px;

                    @include media-breakpoint-down(xl) {
                        line-height: 140%; /* 19.6px */
                        letter-spacing: -0.28px;
                    }
                }

                &__big-circle-left {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            &__img4 {
                width: 317px;
                height: 258px;

                @include media-breakpoint-down(xl) {
                    width: calc(100% / 3 - 15px);
                    height: 220px;
                }

                figure {
                    width: 100%;
                    height: 100%;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        border-radius: 30px;
                    }
                }
            }
            &__img5 {
                width: 317px;
                height: 258px;
                @include media-breakpoint-down(xl) {
                    width: calc(100% / 3 - 15px);
                    height: 220px;
                }

                figure {
                    width: 100%;
                    height: 100%;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        border-radius: 30px;
                    }
                }
            }
        }

        #splider-about-us-header {
            display: none;

            @include media-breakpoint-down(md) {
                display: block;
                width: 100%;
            }

            .splide__track {
                margin-right: -20px;
                margin-left: -20px;
                padding-left: 20px !important;
                padding-right: 20px !important;
            }
        }

        .about-us-first__container__header-imgs-carousel__card {
            border-radius: 30px;
            background: $gray-20;
            padding: 25px;
            height: 100%;
            position: relative;
            overflow: hidden;

            &--title {
                color: $gray-e6e6;
                margin-bottom: 15px;
            }

            &--desc {
                color: $gray;
            }

            &__mobile-left-circles {
                position: absolute;
                bottom: 0;
                right: 0;
            }

            &__mobile-right-circle {
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }

    .about-us-first__two-col-scroll-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        padding-top: 80px;
        position: relative;

        @include media-breakpoint-down(xl) {
            padding-top: 0;
            display: none;
        }

        &.container {
            @include media-breakpoint-up(xxl) {
                max-width: 1300px;
            }

            @include media-breakpoint-down(xxl) {
                max-width: 1280px;
            }
            @include media-breakpoint-down(xl) {
                max-width: 864px;
            }

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 120px;
            background: linear-gradient(
                180deg,
                rgba(21, 21, 21, 1) 40%,
                rgba(21, 21, 21, 0.9) 50%,
                rgba(21, 21, 21, 0) 100%
            );
            z-index: 10;
        }

        &::after {
            content: "";
            position: absolute;
            top: 546px;
            width: 100%;
            height: 160px;
            background: linear-gradient(
                0deg,
                rgba(21, 21, 21, 1) 0%,
                rgba(21, 21, 21, 0.9) 50%,
                rgba(21, 21, 21, 0) 100%
            );
            z-index: 10;
        }

        &__left-col {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 3px;
                height: 100%;
                top: 0;
                left: 18px;
                background-color: rgb(32, 32, 32);
                z-index: -1;
            }
            &__info-section {
                padding-right: 140px;
                padding-top: 175px;

                &__title {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    background-color: $dark-gray-15;

                    &__box {
                        padding: 8px;
                        background-color: $gray-20;
                        border-radius: 15px;
                        display: flex;
                        align-items: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;

                        .arrow-down-icon {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    h3 {
                        font-size: 32px;
                        font-weight: $font-weight-normal;
                        letter-spacing: -0.9px;
                        color: $white;
                    }
                }

                & > p {
                    font-size: 18px;
                    padding-left: 60px;
                    background: linear-gradient(
                        90deg,
                        $white -217.26%,
                        rgba(255, 255, 255, 0.3) 142.13%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                }
            }
        }

        &__right-col {
            &__cards-section {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 170px;
                margin-top: 40px;

                &__card {
                    padding: 25px;
                    border-radius: 30px;
                    background: $gray-20;
                    width: calc(50% - 10px);
                    position: relative;

                    &:nth-child(even) {
                        top: 40px;
                    }

                    &__title {
                        color: $white;
                        margin-bottom: 15px;
                        font-size: 18px;
                    }
                    &__desc {
                        color: $dark-gray;
                    }
                }
            }
        }
    }

    #tablet-pinned-element {
        position: relative;
        display: none;
        @include media-breakpoint-down(xl) {
            display: block;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 40px;
            background: linear-gradient(
                180deg,
                rgba(21, 21, 21, 1) 40%,
                rgba(21, 21, 21, 0.9) 50%,
                rgba(21, 21, 21, 0) 100%
            );
            z-index: 10;
        }

        &::after {
            content: "";
            position: absolute;
            top: 745px;
            width: 100%;
            height: 59px;
            background: linear-gradient(
                0deg,
                rgba(21, 21, 21, 1) 40%,
                rgba(21, 21, 21, 0.9) 50%,
                rgba(21, 21, 21, 0) 100%
            );
            z-index: 10;
        }
    }

    .about-us-first__two-col-scroll-container-tablet {
        grid-template-columns: 1fr 1fr;
        width: 100%;
        padding-top: 80px;
        position: relative;
        display: none;

        @include media-breakpoint-down(xl) {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        &__tab {
            position: relative;
            display: flex;
            gap: 30px;

            &__left {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 3px;
                    height: 99%;
                    top: 0;
                    left: 18px;
                    background-color: rgb(32, 32, 32);
                }

                &__box-container {
                    position: relative;
                    z-index: 2;
                    padding-bottom: 10px;
                    background-color: $dark-gray-15;

                    &__box {
                        padding: 8px;
                        background-color: $gray-20;
                        border-radius: 15px;
                        display: flex;
                        align-items: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        position: relative;
                        z-index: 2;

                        .arrow-down-icon {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }

            &__right {
                &__tab-name {
                    position: relative;
                    z-index: 2;
                    background-color: $dark-gray-15;
                    &--title {
                        font-size: 32px;
                        font-weight: $font-weight-normal;
                        letter-spacing: -0.9px;
                        color: $white;
                        margin-bottom: 5px;
                    }

                    &--desc {
                        background: linear-gradient(
                            90deg,
                            $white -217.26%,
                            rgba(255, 255, 255, 0.3) 142.13%
                        );
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                &__cards-section {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-bottom: 170px;
                    margin-top: 40px;

                    &__card {
                        padding: 25px;
                        border-radius: 30px;
                        background: $gray-20;
                        width: calc(50% - 10px);
                        position: relative;

                        &:nth-child(even) {
                            top: 40px;
                        }

                        &__title {
                            color: $white;
                            margin-bottom: 15px;
                        }
                        &__desc {
                            color: $dark-gray;
                        }
                    }
                }
            }

            &__info-section {
                padding-right: 140px;
                padding-top: 175px;
            }
        }

        &__right-col {
        }
    }

    .about-us-first__two-col-scroll-container-mobile {
        flex-direction: column;
        gap: 25px;
        padding-top: 25px;
        width: 100%;
        display: none;

        @include media-breakpoint-down(md) {
            padding-top: 20px;
            display: flex;
        }

        &--title {
            color: $gray;
            font-size: 24px;
            font-weight: $font-weight-normal;
        }

        &__buttons {
            display: flex;
            padding: 10px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 20px;
            border: 1.5px solid $gray-30;
            margin: auto;

            @include media-breakpoint-down(md) {
                margin: 0;
            }

            button {
                width: 140px;
                height: 50px;
                padding: 15px 50px;
                border-radius: 15px;
                background-color: transparent;
                color: $white;
                border: none;

                @include media-breakpoint-down(md) {
                    flex: 1 0 0;
                    width: auto;
                    padding: 0;
                }

                &.active {
                    background-color: $white;
                    color: $dark-gray-15;
                }

                &:hover:not(.active) {
                    background-color: rgba(255, 255, 255, 0.05);
                }
            }
        }

        &__panels {
            width: 100%;

            .about-us-first__two-col-scroll-container-mobile__panels__panel {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 25px;

                &--desc {
                    background: linear-gradient(
                        90deg,
                        $white -217.26%,
                        rgba(255, 255, 255, 0.3) 142.13%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .about-us-first__two-col-scroll-container-mobile__panels__panel__carousel {
                    width: 100%;

                    ul {
                        padding-left: 0;
                    }
                }

                .about-us-first__two-col-scroll-container-mobile__panels__panel__carousel-card {
                    background-color: $gray-20;
                    border-radius: 30px;
                    padding: 25px;
                    height: 100%;

                    &--title {
                        color: $white;
                        margin-bottom: 15px;
                    }

                    &--desc {
                        color: $dark-gray;
                    }
                }
            }
        }

        .collapsible-content {
            display: none;
        }

        .collapsible-content.active {
            display: flex;
            flex-direction: column;
            /* Additional styling for flex container */
        }
    }

    &.about-us-second {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        padding-bottom: 0;

        @include media-breakpoint-down(md) {
            padding: 0 20px 0;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
        }

        .about-us-second__simple-image {
            text-align: center;

            @include media-breakpoint-down(md) {
                text-align: start;
            }

            &--title {
                color: $white;
                margin-bottom: 25px;

                @include media-breakpoint-down(xxl) {
                    padding: 0 250px;
                }

                @include media-breakpoint-down(xl) {
                    padding: 0 100px;
                }

                @include media-breakpoint-down(md) {
                    padding: 0;
                    margin-bottom: 15px;
                }
            }

            &--desc {
                color: $gray;
                margin-bottom: 50px;
                padding: 0 280px;
                text-align: center;

                @include media-breakpoint-down(xl) {
                    padding: 0 70px;
                }

                @include media-breakpoint-down(md) {
                    padding: 0;
                    text-align: start;
                    line-height: 160%; /* 25.6px */
                    letter-spacing: -0.32px;
                    margin-bottom: 30px;
                }
            }

            &__figure {
                margin-bottom: 0;
                height: 600px;
                width: 100%;

                @include media-breakpoint-down(xl) {
                    height: 400px;
                }

                @include media-breakpoint-down(md) {
                    height: 210px;
                }

                &--img {
                    object-fit: cover;
                    border-radius: 30px;
                    height: 100%;
                    width: 100%;

                    @include media-breakpoint-down(md) {
                        border-radius: 20px;
                    }
                }
            }
        }
    }

    .about-us-second__container {
        @include media-breakpoint-between(xl, xxl) {
            max-width: 1280px;
        }
    }
}

.about-us {
    &__benefits {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 45px;

        @include media-breakpoint-down(md) {
            align-items: start;
            gap: 25px;
        }

        &.locations-content {
            align-items: center;

            @include media-breakpoint-down(md) {
                @include p(0 20px);
            }
        }

        &__cards {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            width: 100%;
            position: relative;

            @include media-breakpoint-down(md) {
                gap: 15px;
            }

            &.compressed {
                @include media-breakpoint-down(md) {
                    height: 1000px;
                    overflow: hidden;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0px;
                        width: 100%;
                        height: 170px;
                        background: linear-gradient(
                                0deg,
                                rgb(255, 255, 255) 40%,
                                rgba(255, 255, 255, 0.5) 50%,
                                rgba(255, 255, 255, 0) 100%
                        );
                        z-index: 10;
                    }

                    .about-us__benefits__cards--expand {
                        display: block;
                        border-radius: 15px;
                        background-color: $gray-e6e6;
                        border: none;
                        color: $dark-gray-15;
                        position: absolute;
                        bottom: 0;
                        padding: 15px 20px 15px 25px;
                        font-size: 15px;
                        z-index: 100;
                        left: 50%;
                        transform: translate(-50%, 0);

                        svg {
                            width: 24px;
                            height: 24px;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            &--expand {
                display: none;
            }

            &__card {
                padding: 30px 25px;
                border-radius: 30px;
                border: 0.08em solid $gray-e6e6;
                width: calc(100% / 3 - 14px);

                @include media-breakpoint-down(xl) {
                    width: calc(100% / 2 - 10px);
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                    padding: 20px;
                    border-radius: 20px;
                }

                &__title {
                    color: $primary;
                    margin-bottom: 20px;

                    figure {
                        margin: 0;
                        width: 25px;
                        display: block;
                        float: left;
                        @include mr(16px);
                        height: 40px;

                        @include media-breakpoint-up(md) {
                            width: 30px;
                            height: 45px;
                        }

                        @include media-breakpoint-up(xl) {
                            height: 80px;
                        }

                        @include media-breakpoint-up(xxl) {
                            height: 45px;
                            width: 35px;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 20px;
                        margin-bottom: 15px;
                    }
                }

                &__desc {
                    padding-left: 42px;
                    padding-right: 15px;
                    color: $dark-gray;
                    opacity: 0.9;

                    @include media-breakpoint-up(md) {
                        padding-left: 48px;
                    }

                    @include media-breakpoint-up(xxl) {
                        padding-left: 52px;
                    }
                }
            }
        }
    }
}

.white-background.about-us-lp {
    padding: 80px 0 0;
    border-radius: 0;

    @include media-breakpoint-down(md) {
        padding: 40px 20px;
    }

    .container {
        @include media-breakpoint-between(xl, xxl) {
            max-width: 1280px;
        }
    }

    .about-us__image-with-cols {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        @include media-breakpoint-down(xl) {
            margin-top: 90px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 60px;
            align-items: start;
        }

        &__title {
            margin-bottom: 25px;
            text-align: center;

            @include media-breakpoint-down(md) {
                text-align: start;
                width: 80%;
                margin: 0;
            }
        }

        &__desc {
            padding: 0px 250px;
            margin-bottom: 45px;
            color: $dark-gray;
            text-align: center;

            @include media-breakpoint-down(xl) {
                padding: 0 100px;
            }

            @include media-breakpoint-down(md) {
                text-align: start;
                padding: 0;
                margin-top: 15px;
                margin-bottom: 25px;
            }
        }

        &__container {
            position: relative;
            height: 600px;
            width: 100%;
            margin-bottom: 45px;

            @include media-breakpoint-down(xl) {
                height: 400px;
            }

            @include media-breakpoint-down(md) {
                height: 210px;
                margin-bottom: 15px;
            }

            figure {
                border-radius: 30px;
                margin: 0;
                height: 100%;
                width: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    @include media-breakpoint-down(md) {
                        border-radius: 20px;
                    }
                }
            }

            &__info-banner {
                position: absolute;
                left: 25px;
                bottom: 25px;
                right: 25px;
                background-color: rgba(255, 255, 255, 0.95);
                box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
                border-radius: 25px;
                display: flex;
                padding: 30px 20px;
                height: 165px;

                @include media-breakpoint-down(xl) {
                    padding: 35px 25px;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }

                &__info {
                    padding: 0 20px;

                    @include media-breakpoint-down(xl) {
                        padding: 0 10px;
                    }

                    &__title {
                        margin-bottom: 10px;
                        font-weight: $font-weight-semibold;
                        font-size: 18px;
                        @include media-breakpoint-down(xl) {
                            font-size: 12px;
                        }
                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                        }
                    }

                    &__desc {
                        color: $dark-gray;
                        font-size: 15px;

                        @include media-breakpoint-down(xl) {
                            font-size: 12px;
                        }
                    }
                }

                &--separator {
                    width: 1px;
                    height: 100%;
                    margin: 0px 25px;
                    background-color: $gray-e6e6;
                    @include media-breakpoint-down(xl) {
                        margin: 0 5px;
                    }
                }
            }
        }
        #splider-about-us-info-banner {
            display: none;
            width: 100%;

            @include media-breakpoint-down(md) {
                display: block;
            }
            .splide__track {
                margin-right: -20px;
                margin-left: -20px;
                padding-left: 20px !important;
                padding-right: 20px !important;
            }

            .about-us__image-with-cols__container__info-banner-mobile__card {
                padding: 20px;
                border-radius: 20px;
                border: 0.08em solid $gray-e6e6;
                height: 100%;

                &__title {
                }

                &__desc {
                    color: $dark-gray;
                    line-height: 160%; /* 24px */
                    letter-spacing: -0.3px;
                }
            }
        }

        & > a {
            align-self: center;
        }
    }
}

.photo-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 25px;

    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }
    figure {
        margin: 0;
        height: 100%;

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            height: 610px;
            border-radius: 30px;

            @include media-breakpoint-down(xxl) {
                height: 600px;
            }

            @include media-breakpoint-down(xl) {
                height: 410px;
            }

            @include media-breakpoint-down(md) {
                height: 260px;
                border-radius: 20px;
            }

            @media (max-width: 600px) {
                height: 210px;
            }
        }
    }

    &__location {
        display: flex;
        gap: 15px;
        align-items: center;
        position: absolute;
        padding: 5px;
        padding-right: 25px;
        bottom: 20px;
        right: 20px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
        backdrop-filter: blur(6px);

        @include media-breakpoint-down(xl) {
            right: 20px;
        }

        @include media-breakpoint-down(md) {
            right: 30px;
            bottom: 10px;
        }

        &--icon {
            display: flex;
            padding: 10px;
            background-color: $white;
            border-radius: 10px;
            box-shadow: 0px 2px 5px 0px rgba(21, 21, 21, 0.08);
            height: 40px !important;
            width: 40px;

            img {
                border-radius: 0 !important;
                object-fit: contain !important;
                height: auto !important;
            }
        }

        &--text {
            p {
                margin-bottom: 0;
                color: $text-rich-black;
                font-weight: $font-weight-semibold;
                font-size: 14px;

                @include media-breakpoint-down(md) {
                    font-size: 12px;
                    line-height: 125%;
                }
            }
        }
    }
}
