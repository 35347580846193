.location-template {
    &:has(.single-location__explore-more) {
         @include p(80px 0 0);
    }
}

.single-location {
    .container {
        @include media-breakpoint-between(xl, xxl) {
            max-width: 1280px;
        }
    }

    &__explore-more {
        @include p(60px 20px 30px);

        @include media-breakpoint-up(xl) {
            @include p(60px 0);
        }

        &--items {
            justify-content: center;

            .col {
                &:not(:last-child) {
                    .explore-more-item {
                        .explore-more-item__text {
                            border-bottom: 1px solid $gray-e6e6;

                            @include media-breakpoint-up(xl) {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            &.row-cols-xl-5 {
                .explore-more-item {
                    &__image {
                        @include media-breakpoint-up(xl)    {
                            height: 210px;
                        }
                    }
                }
            }

            .explore-more-item {
                @include mb(24px);

                @include media-breakpoint-up(xl) {
                    @include mb(0);
                }

                a {
                    text-decoration: none;
                    display: flex;

                    @include media-breakpoint-up(xl) {
                        display: grid;
                    }
                }

                &__text {
                    width: 75%;
                    @include ml(18px);
                    @include pb(14px);

                    @media only screen and (min-width: 580px) {
                        width: 80%;
                    }

                    @media only screen and (min-width: 700px) {
                        width: 85%;
                    }

                    @media only screen and (min-width: 800px) {
                        width: 88%;
                    }

                    @include media-breakpoint-up(md) {
                        width: 79%;
                    }

                    @include media-breakpoint-up(xl) {
                        @include ml(0);
                        width: 100%;
                    }

                    .item-title {
                        color: $dark-gray-15;
                        font-size: 18px;
                        line-height: 23.4px;
                        @include mb(8px);

                        @include media-breakpoint-up(md) {
                            line-height: 28.6px;
                            font-size: 22px;
                        }

                        @include media-breakpoint-up(xl) {
                            @include m(1rem 0);
                        }
                    }

                    .item-description {
                        line-height: 24px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        @include media-breakpoint-up(xl) {
                            -webkit-line-clamp: 3;
                        }
                    }
                }

                &__image {
                    overflow: hidden;
                    display: -webkit-box;
                    border-radius: 10px;
                    width: 72px;
                    height: 72px;

                    @include media-breakpoint-up(md) {
                        width: 160px;
                        height: 160px;
                    }

                    @include media-breakpoint-up(xl) {
                        width: 100%;
                        height: 270px;
                        display: grid;
                        border-radius: 30px;
                    }

                    img {
                        height: -webkit-fill-available;
                        object-fit: cover;
                    }

                    figure {
                        @include m(0);
                    }
                }
            }
        }
    }

    &__header {
        @include p(64px 20px 0);

        @include media-breakpoint-up(md) {
            @include p(68px 0 0);
        }

        &--title {
            @include mb(10px);
            text-align: center;

            @include media-breakpoint-up(md) {
                @include mb(70px);
            }

            h1 {
                font-weight: $font-weight-semibold;
            }
        }

        &--gray-content {
            @include p(22px 16px);
            background-color: $gray-90;
            border-radius: 20px;

            @include media-breakpoint-up(md) {
                border-radius: 30px;
                @include p(24px);
                @include mt(18px);
            }

            .section-title {
                font-size: 18px;
                font-weight: $font-weight-semibold;

                @include media-breakpoint-up(md) {
                    font-size: 22px;
                }
            }

            .text-container {
                @include mt(14px);
                display: flex;
                gap: 26px;
                flex-direction: column;

                @include media-breakpoint-up(md) {
                    @include mt(18px);
                    flex-direction: row;
                }

                p {
                    color: $dark-gray;
                    font-size: 16px;
                    line-height: 27px;

                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                        width: 50%;
                    }
                }
            }
        }

        &--purple-content {
            background-color: $purple;
            border-radius: 20px;
            height: 210px;
            @include mt(16px);
            @include p(22px 16px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
                border-radius: 30px;
                height: 350px;
                @include p(24px 30px);
                @include mt(0);
            }

            .section-text {
                font-size: 20px;
                font-weight: $font-weight-semibold;
                line-height: 28px;

                @include media-breakpoint-up(md) {
                    font-size: 22px;
                }
            }

            a {

                @media screen and (min-width: 601px) {
                    width: fit-content;
                }
            }
        }

        &--image {
            overflow: hidden;
            border-radius: 20px;
            height: 210px;
            @include mt(16px);
            position: relative;

            @include media-breakpoint-up(md) {
                border-radius: 30px;
                height: 350px;
                @include mt(0);
            }

            figure {
                @include m(0);

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                    position: absolute;
                }
            }
        }
    }

    &__offices {
        &.mobile {
            @include p(0 20px);

            @include media-breakpoint-up(md) {
                display: none;
            }

            h2 {
                text-align: center;
            }

            .splide {
                &__arrows {
                    display: none;
                }

                &__list {
                    .splide {
                        &__slide {
                            width: 84% !important;
                            border: 1px solid $gray-e6e6;
                            border-radius: 20px;
                            @include p(20px);

                            &:only-child {
                                width: 100% !important;
                            }

                            .title {
                                font-size: 18px;
                                font-weight: $font-weight-semibold;
                            }

                            .tab-pane__content--text {
                                .description {
                                    color: $dark-gray;
                                    font-size: 15px;
                                    line-height: 24px;
                                }

                                .address-section {
                                    display: flex;
                                    align-items: flex-start;

                                    img {
                                        @include mr(8px);
                                    }

                                    p {
                                        color: $dark-gray;
                                        font-size: 13px;
                                        line-height: 18.2px;
                                    }
                                }
                            }

                            .tab-pane__content--image {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &.desktop {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &--wrapper {
            background-color: $gray-90;
            border-radius: 30px;
            @include p(40px);

            .tabs-content-section {
                .tab-pane {
                    &__content {
                        display: flex;
                        gap: 30px;
                        flex-direction: column;

                        @include media-breakpoint-up(xl) {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }

                        .title {
                            display: none;
                        }

                        &--text {
                            @include media-breakpoint-up(xl) {
                                flex: 1 1 0;
                            }

                            .description {
                                line-height: 27px;
                                font-size: 18px;
                                color: $dark-gray;
                                text-align: center;

                                @include media-breakpoint-up(xl) {
                                    text-align: left;
                                }
                            }

                            .address-section {
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;

                                @include media-breakpoint-up(xl) {
                                    justify-content: initial;
                                }

                                img {
                                    @include mr(8px);
                                }

                                p {
                                    font-size: 13px;
                                    line-height: 18.2px;
                                    color: $dark-gray;
                                }
                            }
                        }

                        &--image {
                            height: 350px;
                            overflow: hidden;
                            border-radius: 20px;

                            @include media-breakpoint-up(md) {
                                border-radius: 25px;
                            }

                            @include media-breakpoint-up(xl) {
                                flex: 1 1 0;
                            }

                            figure {
                                @include m(0);

                                img {
                                    width: 100%;
                                    object-fit: cover;
                                    left: 0;
                                    margin: 0;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .tabs-section {
                .nav-tabs {
                    border: none;
                    justify-content: center;
                    gap: 10px;

                    &.default-ul {
                        line-height: initial;

                        li {
                            &::before {
                                content: "" !important;
                            }
                        }
                    }

                    .nav-item {
                        &:only-child {
                            button {
                                cursor: default;
                            }
                        }
                    }

                    .nav-link {
                        @include p(13px 22px);
                        border-radius: 15px;
                        color: $dark-gray;
                        border: 1px solid $gray-c3;
                        font-size: 14px;

                        &:hover {
                            border-color: $gray-c3;
                            color: $purple;
                        }

                        &.active {
                            background-color: $purple;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    &__video-slider {
        @include p(0 20px);

        @include media-breakpoint-up(md) {
            @include p(0);
        }

        &--title {
            @include mb(25px);

            @include media-breakpoint-up(md) {
                @include mb(40px);
            }
        }

        &--splide {
            .splide {
                position: relative;

                &__arrows {
                    position: absolute;
                    right: 0;
                    top: -80px;
                    display: none;

                    @include media-breakpoint-up(md) {
                        display: block;
                    }

                    .splide {
                        &__arrow {
                            background-color: transparent;
                            border: 1px solid $gray-e6e6;
                            border-radius: 10px;
                            width: 45px;
                            height: 45px;
                            background-image: url(/theme/luxoft/assets/images/icons/gray-arrow-63666A.svg);
                            background-repeat: no-repeat;
                            background-position: center;

                            svg {
                                display: none;
                            }

                            &--prev {
                                @include mr(16px);
                            }

                            &--next {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                &__list {
                    .splide {
                        &__slide {
                            width: 84% !important;

                            @include media-breakpoint-up(md) {
                                width: 46% !important;
                            }

                            @include media-breakpoint-up(xl) {
                                width: 31% !important;
                            }
                        }
                    }
                }

                &__pagination {
                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }
            }

            .video-slider-item {
                border: 1px solid $gray-e6e6;
                border-radius: 20px;
                @include p(8px 8px 14px);

                @include media-breakpoint-up(md) {
                    border-radius: 25px;
                    @include p(8px 8px 16px);
                }

                &:hover {
                    .video-slider-item__image-container {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }

                a {
                    text-decoration: none;
                    color: initial;
                }

                &__text-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 105px;
                    @include p(0 8px);

                    @include media-breakpoint-up(md) {
                        height: 130px;
                    }

                    .title {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        font-size: 18px;
                        font-weight: $font-weight-semibold;
                        line-height: 23.4px;
                    }

                    .action {
                        font-size: 13px;
                        gap: 6px;
                        display: flex;
                        align-items: center;
                    }
                }

                &__image-container {
                    height: 160px;
                    overflow: hidden;
                    border-radius: 25px;

                    @include media-breakpoint-up(md) {
                        height: 200px;
                    }

                    figure {
                        @include m(0);

                        img {
                            transition: .3s ease;
                            width: 100%;
                            object-fit: cover;
                            left: 0;
                            margin: 0;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &__e-calendar {
        @include p(0 20px);

        @include media-breakpoint-up(md) {
            @include p(0);
        }

        &--title {
            @include mb(20px);

            .e-calendar-title {
                font-weight: $font-weight-semibold;
                font-size: 24px;
                line-height: 28.8px;

                @include media-breakpoint-up(md) {
                    font-size: 30px;
                    line-height: 36px;
                }
            }
        }

        &--action {
            .btn-normal {
                display: inline-block;
            }
        }

        &--splide {
            .splide {
                position: relative;

                &__arrows {
                    display: none;

                    @include media-breakpoint-up(md) {
                        position: absolute;
                        right: 0;
                        top: -60px;
                        display: block;
                    }

                    .splide {
                        &__arrow {
                            background-color: transparent;
                            border: 1px solid $gray-e6e6;
                            border-radius: 10px;
                            width: 45px;
                            height: 45px;
                            background-image: url(/theme/luxoft/assets/images/icons/gray-arrow-63666A.svg);
                            background-repeat: no-repeat;
                            background-position: center;

                            svg {
                                display: none;
                            }

                            &--prev {
                                @include mr(16px);
                            }

                            &--next {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                &__list {
                    .splide {
                        &__slide {
                            width: 84% !important;

                            @include media-breakpoint-up(md) {
                                width: 46% !important;
                            }

                            @include media-breakpoint-up(xl) {
                                width: 31% !important;
                            }
                        }
                    }
                }

                &__pagination {
                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }
            }

            .e-calendar-item {
                border-radius: 20px;
                height: 160px;
                overflow: hidden;

                @include media-breakpoint-up(md) {
                    height: 200px;
                }

                figure {
                    @include m(0);

                    img {
                        transition: .3s ease;
                        width: 100%;
                        object-fit: cover;
                        left: 0;
                        margin: 0;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.locations {
    padding: 60px 0 !important;

    @include media-breakpoint-down(md) {
        padding: 0 !important;
    }

    &__desktop-regions {
        display: flex;
        flex-direction: column;
        gap: 45px;

        &__region {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @include media-breakpoint-down(md) {
                display: none;
            }

            h2 {
                color: $dark-gray-15;
                font-size: 25px;
                font-weight: $font-weight-bold;
                line-height: 120%;
                letter-spacing: 0.5px;
            }

            &__countries {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                &__country {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    border-radius: 25px;
                    border: 1.5px solid $gray-e6e6;
                    padding: 10px;
                    width: 310px;
                    box-shadow: 0px 5px 30px 0px rgba(255, 255, 255, 1);
                    transition: box-shadow 0.3s ease-out;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        border-color: transparent;
                        box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
                    }

                    @include media-breakpoint-down(xl) {
                        width: 274.6666px;
                    }

                    &__image {
                        position: relative;

                        figure {
                            .locations__desktop-regions__region__countries__country__image__photo {
                                width: 100%;
                                height: 200px;
                                object-fit: cover;
                                border-radius: 20px;
                            }
                        }

                        &__photo {
                            width: 100%;
                            height: 200px;
                            object-fit: cover;
                            border-radius: 20px;
                        }

                        &__icon {
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            padding: 10px;
                            border-radius: 15px;
                            background-color: $white;
                            box-shadow: 0px 10px 30px 0px
                                rgba(91, 106, 129, 0.2);
                            backdrop-filter: blur(6px);

                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    &__description {
                        display: flex;
                        justify-content: space-between;
                        gap: 12px;
                        padding: 0px 15px 10px 15px;

                        &__left {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            width: 100%;

                            &__title {
                                color: $dark-gray-15;
                                font-size: 15px;
                                font-weight: $font-weight-semibold;
                                line-height: 140%;
                                margin: 0;
                            }
                        }

                        &__right {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__mobile-regions {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &.accordion {
            .carousel-item {
                @include media-breakpoint-down(md) {
                    height: 260px;
                }

                @media (max-width: 600px) {
                    height: 210px;
                }
            }
        }

        &__region {
            .accordion-button {
                font-size: 18px !important;
                font-weight: $font-weight-semibold;
                line-height: 130%;
            }

            &__country {
                .accordion-body {
                    padding: 0 !important;

                    .carousel-skeleton {
                        height: 260px;
                        width: 100%;
                        background-color: $dark-gray;
                        border-radius: 20px;
                    }

                    .splide__track {
                        max-height: 305px;

                        @media (max-width: 600px) {
                            max-height: 255px;
                        }
                    }

                    .splide__slide {
                        margin-top: 0 !important;
                        figure {
                            width: 100%;
                            height: 100%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 20px;
                            }
                        }

                        .our-locations__content__right__location {
                            background: rgba(255, 255, 255, 0.95);
                            box-shadow: 0px 10px 30px 0px
                                rgba(91, 106, 129, 0.2);
                            backdrop-filter: blur(6px);

                            a {
                                min-width: 34px;
                                min-height: 34px;
                                padding: 7px;
                                svg {
                                    height: 20px;
                                    width: 20px;
                                    transition: stroke 0.3s ease; /* Add a smooth transition for the stroke property */
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
