.bookmarked {
    .save-job-icon {
        content: url("/theme/luxoft/assets/images/icons/bookmark-remove.svg");
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
    }
}

.save-job-icon {
    content: url("/theme/luxoft/assets/images/icons/save-favourite.svg");
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
}

.job-page-title {
    font-size: 55px;
    line-height: 130%;
    letter-spacing: -1.1px;
    color: $white;
    padding-top: 0px !important;
    position: relative;
    margin-top: 202px;
    padding-bottom: 30px !important;

    @include media-breakpoint-down(xxl) {
        font-size: 50px;
        letter-spacing: -1px;
    }
    @include media-breakpoint-down(xl) {
        font-size: 45px;
        letter-spacing: -0.9px;
    }
    @include media-breakpoint-down(md) {
        font-size: 32px;
        letter-spacing: -0.64px;
        line-height: 135%;
        color: $gray-90;
        margin-top: 173px;
        padding-bottom: 20px !important;
    }
}

.btn-transparent {
    &.job-back-btn {
        padding: 10px 20px 10px 5px;
        position: absolute;
        height: 44px;
        text-decoration: none;
        width: 140px;
        top: -72px;

        &:hover {
            svg {
                opacity: 1;
            }
        }

        svg {
            height: 24px;
            width: 24px;
            opacity: 0.5;
            stroke: $white;
        }

        p {
            color: $white;
            letter-spacing: -0.26px;
        }
    }

    &.back-to-events {
        width: 160px;
    }
}


.job-header {
    border-bottom: 1px solid var(--black-100, $gray-e6e6);
    display: flex;
    padding: 15px 0;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    position: fixed;
    top: 88px;
    z-index: 10;
    background-color: $white;
    width: 100%;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0s ease, opacity 0s ease;

    @include media-breakpoint-down(md) {
        display: none;
    }

    &.show {
        transform: translateY(0);
        opacity: 1;
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &__title {
        color: var(--500, $dark-gray-15);
        letter-spacing: -0.4px;
    }

    &__actions {
        display: flex;
        gap: 10px;

        &__apply {
            height: auto;
            width: auto;
            padding: 13px 35px;
            line-height: 140%; /* 19.6px */
            letter-spacing: -0.28px;
        }

        &__favourite,
        &__save-job,
        &__share {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 46px;
            padding: 10px;
            background-color: transparent;
            border-radius: 15px;
            border: 1.5px solid var(--black-100, $gray-e6e6);

            svg {
                width: 24px;
                height: 24px;
                stroke: $dark-gray;
                fill: transparent;
            }

            &::after {
                display: none;
            }

            &:focus {
                border-color: $gray-ccc;
                background-color: $gray-f9;
            }

            &:hover {
                border-color: $gray-ccc;
                background-color: $gray-f9;
            }
        }

        .bookmarked {
            background-color: $orange;
            border-color: $orange;
        }

        .dropdown-menu {
            border-radius: 15px;
            box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
            border: none;
            min-width: unset;
            padding: 0;
            overflow: hidden;
            width: 195px;

            .share-job-option {
                border: none;
                background-color: transparent;
                align-items: center;
                display: flex;
                gap: 10px;
                @include p(20px 25px 20px 20px);
                font-size: 14px;
                line-height: 19.6px;
                width: 100%;

                &:hover {
                    background-color: #fbfbfb;
                }

                &__icon-wrapper {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;

                    &--refer {
                        background-color: $purple;

                        img {
                            width: 14px;
                        }
                    }

                    &--facebook {
                        background-color: $blue-337;

                        svg {
                            fill: $white;
                            height: 19px;
                            width: 11px;
                        }
                    }

                    &--twitter {
                        background-color: $black;

                        svg {
                            fill: $white;
                            width: 19px;
                            height: 15px;
                        }
                    }

                    &--linkedin {
                        background-color: $blue-006;

                        svg {
                            fill: $white;
                            width: 15px;
                            height: 15px;
                        }
                    }

                    &--mail {
                        background-color: $orange;

                        svg {
                            fill: $white;
                            width: 40px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }
}

.job-short-details {
    @include media-breakpoint-down(md) {
        padding: 0 20px 0;
    }

    &__job-id {
        margin-bottom: 30px;
        color: $white;
        opacity: 0.5;

        @include media-breakpoint-down(md) {
            margin-top: 30px;
            margin-bottom: 40px;
            padding: 0 20px;
        }
    }

    &__actions {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 60px;

        &__apply {
            display: flex;
            padding: 15px 50px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        &__favourite,
        &__save-job,
        &__share {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 55px;
            height: 55px;
            padding: 10px;
            background-color: transparent;
            border-radius: 15px;
            border: 1.5px solid rgba(255, 255, 255, 0.15);

            &.active {
                background-color: $orange;
                border-color: transparent;
                svg {
                    opacity: 1;
                }
            }

            svg {
                width: 24px;
                height: 24px;
                stroke: white;
                fill: transparent;
                opacity: 0.5;
            }

            &::after {
                display: none;
            }

            &:focus {
                background-color: transparent;
                border-color: $white;
                svg {
                    opacity: 1;
                }
            }

            &:hover {
                border-color: $white;
                background-color: transparent;
                svg {
                    opacity: 1;
                }
            }
        }

        .bookmarked {
            background-color: $orange;
            border-color: $orange;
        }

        &__save-job:hover {
            .save-job-icon {
                content: url("/theme/luxoft/assets/images/icons/bookmark-add.svg");
            }
        }

        .dropdown-menu {
            border-radius: 15px;
            box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
            border: none;
            min-width: unset;
            padding: 0;
            overflow: hidden;
            width: 195px;

            .dropdown-item {
                display: flex;
                padding: 20px 25px 20px 20px;
                gap: 10px;
                align-items: center;
                cursor: pointer;

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &__icon--facebook {
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    background-color: $blue-337;
                }
                &__icon--twitter {
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    background-color: $black;
                }
                &__icon--linkedin {
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    background-color: $blue-006;
                }
                &__icon--mail {
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    background-color: $orange;
                }

                .facebook-icon {
                    fill: $white;
                    width: 11px;
                    height: 19px;
                }

                .twitter-icon {
                    fill: $white;
                    width: 19px;
                    height: 15px;
                }

                .linkedin-icon {
                    fill: $white;
                    width: 15px;
                    height: 15px;
                }

                .mail-icon {
                    fill: $white;
                    width: 40px;
                    height: 35px;
                }

                &:hover {
                    background-color: #fbfbfb;
                }
            }
        }
    }
}

.white-background.job {
    padding: 0;
}

.job {
    padding: 60px 0;

    @include media-breakpoint-down(md) {
        padding: 25px 20px;
    }

    .form-title,
    .form-legend {
        margin-left: 0;
        margin-right: 0;
    }

    & > .form {
        padding: 0;
    }

    &__grid {
        display: flex;
        align-items: flex-start;
        gap: 160px;
        margin-bottom: 50px;

        @include media-breakpoint-down(xxl) {
            gap: 50px;
            margin-bottom: 45px;
        }

        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }

        &__about-job {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 45px;

            @include media-breakpoint-down(md) {
                gap: 35px;
            }

            & > * {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;

                @include media-breakpoint-down(md) {
                    gap: 15px;
                }

                h5 {
                    color: $dark-gray-15;
                }

                p {
                    color: $dark-gray;

                    @include media-breakpoint-down(md) {
                        line-height: 160%; /* 24px */
                        letter-spacing: -0.3px;
                    }
                }

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @include media-breakpoint-down(md) {
                        gap: 15px;
                    }

                    & > div {
                        display: flex;
                        align-items: start;
                        gap: 10px;

                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
                    }
                }
            }

            &__skills {
                .job__grid__about-job__skills--subtitle {
                    color: $dark-gray-15;
                    font-size: 20px;

                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                    }
                }
            }

            &__other {
                @include media-breakpoint-down(md) {
                    gap: 20px;
                }
                &__items {
                    display: flex;
                    flex-direction: row !important;
                    align-items: flex-start;
                    align-content: flex-start;
                    gap: 15px;
                    flex-wrap: wrap;

                    @include media-breakpoint-down(md) {
                        gap: 10px !important;
                    }

                    &__item {
                        display: flex;
                        padding: 10px 20px 10px 10px;
                        align-items: center;
                        gap: 15px;
                        border-radius: 20px;
                        border: 1.5px solid $gray-e6e6;
                        background: $white;

                        &__icon {
                            display: flex;
                            padding: 10px;
                            border-radius: 15px;
                            background: #f8f2ff;
                        }

                        &__text {
                            :first-child {
                                color: $gray;
                                text-transform: uppercase;
                                letter-spacing: 0.24px;
                            }
                            :last-child {
                                color: $dark-gray-15;
                                letter-spacing: -0.3px;
                            }
                        }
                    }
                }
            }

            &__benefits {
                @include mb(50px);

                .benefits-notes {
                    p {
                        color: $dark-gray;
                        font-size: 15px;
                        line-height: 24px;
                    }
                }

                .benefits-items {
                    @include mt(20px);

                    &__row {
                        @include mb(15px);

                        .accordion {
                            @include mb(0 !important);
                            @include p(0);
                            display: flex;
                            gap: 20px;
                            flex-direction: column;

                            @include media-breakpoint-up(md) {
                                flex-direction: row;
                            }

                            .accordion-item {
                                border: 1px solid $gray-e6e6 !important;
                                border-radius: 20px !important;
                                @include p(20px);
                                width: 100%;
                                height: fit-content;

                                @include media-breakpoint-up(md) {
                                    width: 50%;
                                }

                                .accordion-header {
                                    display: flex;
                                    position: relative;

                                    .accordion-button {
                                        background-color: transparent;
                                        color: $purple;
                                        font-size: 15px;
                                        font-weight: $font-weight-bold;
                                        @include p(0);
                                        z-index: 2;

                                        &::after {
                                            content: none;
                                            background-image: none;
                                        }

                                        &.collapsed {
                                            .button-icon-wrapper {
                                                .close-icon {
                                                    &:before {
                                                        transform: rotate(0deg);
                                                    }

                                                    &:after {
                                                        opacity: 1;
                                                        transform: rotate(-90deg);
                                                    }
                                                }
                                            }
                                        }

                                        .button-icon-wrapper {
                                            background-color: $bright-purple-90;
                                            width: 44px;
                                            height: 44px;
                                            border-radius: 15px;
                                            position: absolute;
                                            right: -8px;
                                            top: -12px;
                                            z-index: 1;

                                            .close-icon {
                                                width: 24px;
                                                height: 24px;

                                                &:before,
                                                &:after {
                                                    position: absolute;
                                                    left: 21px;
                                                    top: 13px;
                                                    content: ' ';
                                                    height: 18px;
                                                    width: 2px;
                                                    background-color: $purple;
                                                    transition: all 0.3s ease;
                                                }

                                                &:before {
                                                    transform: rotate(270deg);
                                                }

                                                &:after {
                                                    transform: rotate(-90deg);
                                                    opacity: 0;
                                                }
                                            }
                                        }
                                    }
                                }

                                .accordion-body {
                                    @include p(14px 0 5px 5px);

                                    p,
                                    ul li {
                                        color: $dark-gray;
                                        font-size: 15px;
                                        line-height: 24px;
                                    }

                                    ul {
                                        @include p(0);
                                        @include mb(0);

                                        li {
                                            position: relative;
                                            @include mt(0.5rem);
                                            display: flex;
                                            align-items: baseline;

                                            span {
                                                font-size: 10px;
                                                @include pr(6px);
                                            }

                                            b {
                                                display: contents;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__job-related {
            display: flex;
            width: 420px;
            flex-direction: column;
            align-items: flex-start;
            gap: 35px;

            @include media-breakpoint-down(xxl) {
                width: 310px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &__refer {
                display: flex;
                padding: 25px;
                flex-direction: column;
                align-items: flex-start;
                border: 1.5px solid var(--black-100, $gray-e6e6);
                border-radius: 30px;
                background: var(--White, $white);
                width: 420px;

                @include media-breakpoint-down(xxl) {
                    width: 310px;
                }

                @include media-breakpoint-down(md) {
                    border-radius: 20px;
                }

                &__title {
                    letter-spacing: -0.44px;
                    color: $dark-gray-15;
                    margin-bottom: 12px;
                }

                &__description {
                    color: var(--text-dark-gray, $dark-gray);
                    letter-spacing: -0.28px;
                    margin-bottom: 35px;
                }

                &__recommend-btn {
                    border-radius: 15px;
                    background: var(--purple-90, #f8f2ff);
                    padding: 15px 25px;
                    height: 55px;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                }
            }

            &__jobs {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__title {
                    display: flex;
                    justify-content: space-between;

                    p {
                        color: var(--500, $dark-gray-15);
                    }

                    a {
                        text-decoration: none;
                        color: var(--text-medium-gray, $gray);

                        @include media-breakpoint-up(xl) {
                            &:hover {
                                color: $dark-gray;
                            }
                        }
                    }
                }

                &__container {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    &__card {
                        padding: 25px;
                        border: 1.5px solid var(--black-100, $gray-e6e6);
                        background: var(--White, $white);
                        border-radius: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        text-decoration: none;

                        @include media-breakpoint-up(xl) {
                            &:hover {
                                border-color: transparent;
                                box-shadow: 0px 5px 30px 0px
                                    rgba(21, 21, 21, 0.15);
                            }
                        }

                        &__title {
                            color: $dark-gray-15;
                            margin-bottom: 10px;
                        }

                        &__position {
                            color: var(--text-dark-gray, $dark-gray);
                            margin-bottom: 30px;
                        }

                        &__location-tag {
                            border-radius: 10px;
                            background: var(--gray-90, $gray-90);
                            display: flex;
                            padding: 5px 10px 5px 5px;
                            align-items: center;
                            color: $dark-gray-15;

                            img {
                                width: 20px;
                                height: 20px;

                                &.location-pin-icon {
                                    margin-right: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__details-again {
        display: flex;
        padding: 25px 35px;
        justify-content: space-between;
        align-items: center;
        border-radius: 30px;
        background: $gray-90;

        .route-icon-mobile {
            display: none;

            svg {
                stroke: $dark-gray !important;
                fill: none;
            }

            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .copy-to-clipboard {
            padding: 0;
            border: none;
            height: 18px;
            width: 18px;

            svg {
                width: 18px;
                height: 18px;
                fill: none;
                stroke: $primary !important;
            }
        }

        .job-id-desktop {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .job-id-mobile {
            display: none;
            color: $dark-gray-15;

            @include media-breakpoint-down(md) {
                display: flex;
                justify-content: space-between;
                width: 100%;
                border-top: 1px solid $gray-e6e6;
                padding-top: 20px;
                font-size: 14px;
            }
        }

        @include media-breakpoint-down(md) {
            padding: 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            border-radius: 20px;
        }

        div {
            display: flex;
            align-items: center;
            gap: 35px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                width: 100%;
            }

            div {
                display: flex;
                align-items: center;
                gap: 10px;

                @include media-breakpoint-down(md) {
                    flex-direction: row;
                }

                > p {
                    color: $dark-gray-15;
                    letter-spacing: -0.26px;
                    font-size: 14px;
                }

                svg {
                    width: 18px;
                    height: 18px;

                    &.job-position-icon {
                        stroke: $dark-gray;
                        fill: transparent;
                    }

                    &.clock-icon {
                        stroke: $dark-gray;
                        fill: transparent;
                    }

                    &.date-icon {
                        stroke: $dark-gray;
                        fill: transparent;
                    }
                }
            }
        }
    }

    &__related-mobile {
        display: none;
        padding: 20px;
        border-radius: 20px;
        border: 1.5px solid var(--black-100, $gray-e6e6);
        margin-top: 45px;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &__title {
            margin-bottom: 10px;
            color: $dark-gray-15;
        }

        &__description {
            color: $dark-gray;
            margin-bottom: 25px;
        }

        &__recommend-btn {
            border-radius: 15px;
            background: var(--purple-90, #f8f2ff);
            padding: 15px 25px;
            height: 55px;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__related-jobs-mobile {
        display: none;
        margin-top: 45px;

        @include media-breakpoint-down(md) {
            display: block;
        }

        .splide__track {
            margin-right: -19px;
        }

        p {
            color: $dark-gray-15;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        &__job {
            display: flex;
            padding: 20px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 5px;
            border-radius: 20px;
            border: 1.5px solid var(--black-100, $gray-e6e6);
            background: var(--White, $white);
            text-decoration: none;

            p {
                text-transform: none;
                margin: 0;
            }
        }

        &__btn {
            display: flex;
            height: 55px;
            padding: 15px 25px;
            justify-content: center;
            align-items: center;
            color: $white;
            gap: 10px;
            border-radius: 15px;
            background: var(--Primary, $purple);
            text-decoration: none;
            margin-top: 5px;
        }
    }
}

.job-header-mobile {
    display: flex;
    position: fixed;
    bottom: 64px;
    z-index: 10;
    border-top: 1px solid var(--black-100, $gray-e6e6);
    border-bottom: 1px solid var(--black-100, $gray-e6e6);
    padding: 10px 20px;
    width: 100%;
    gap: 10px;
    background-color: $white;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0s ease, opacity 0s ease;

    &.show {
        @include media-breakpoint-down(md) {
            transform: translateY(0);
            opacity: 1;
            transition: opacity 0s ease, transform 2s ease;
        }
    }

    &__apply {
        display: flex;
        padding: 10px 35px;
        justify-content: center;
        align-items: center;
        width: 227px !important;
    }

    &__favourite,
    &__save-job,
    &__share {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        padding: 10px;
        background-color: transparent;
        border-radius: 10px;
        border: 1.5px solid var(--black-100, $gray-e6e6);

        svg {
            width: 24px;
            height: 24px;
            stroke: $dark-gray;
            fill: transparent;
        }

        &::after {
            display: none;
        }

        &:focus {
            border-color: $gray-ccc;
            background-color: $gray-f9;
        }

        &:hover {
            border-color: $gray-ccc;
            background-color: $gray-f9;
        }
    }

    .bookmarked {
        background-color: $orange;
        border-color: $orange;
    }

}

.mobile-successfully-panel {
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}

.jobs-listing-panels,
.job-share-mobile,
.mobile-successfully-panel {
    display: flex;
    flex-direction: column;
    opacity: 0;
    position: fixed;
    bottom: 0;
    z-index: 202;
    transform: translateY(100%);
    background-color: $white;
    width: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    left: 0;

    &.show {
        opacity: 1;
        transform: translateY(0);

        @include media-breakpoint-up(md) {
            opacity: 0;
            transform: translateY(100%);
        }

        &.embedded-jobs-v {
            transform: translateY(-13%);

            @include media-breakpoint-up(md) {
                opacity: 0;
                transform: translateY(100%);
            }
        }
    }

    &__head {
        display: flex;
        padding: 20px;
        gap: 10px;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 18px;
            font-style: normal;
            font-weight: $font-weight-semibold;
            line-height: 130%;
            letter-spacing: 0.36px;
            text-transform: uppercase;
            color: $dark-gray-15;
        }

        button {
            border: none;
            background-color: transparent;

            svg {
                height: 32px;
                width: 32px;
                padding: 4px;
                border-radius: 10px;
                border: 1.5px solid var(--black-100, $gray-e6e6);
                stroke: $dark-gray;
            }
        }
    }

    &__close {
        padding: 20px;
        transition: all 0.3s ease-in;

        &.hidden {
            transform: translateX(-105%);
        }
    }

    .successfully-panel {
        &__content {
            align-items: center;
            flex-direction: column;
            display: flex;
            text-align: center;
            @include p(40px 20px 25px);

            .successfully {
                color: $dark-gray-15;
                font-weight: $font-weight-semibold;
                font-size: 18px;
                line-height: 23.4px;
            }

            .message {
                color: $gray-66;
                font-weight: $font-weight-normal;
                font-size: 15px;
                line-height: 24px;
            }
        }
    }
}

.job-share-mobile {
    &__list {
        border-radius: 15px;
        border: none;
        min-width: unset;
        padding: 0 20px;

        &__item {
            display: flex;
            padding: 20px 0px;
            gap: 10px;
            align-items: center;
            width: 100%;
            color: $dark-gray-15;
            text-decoration: none;
            cursor: pointer;

            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
            }

            &__icon--facebook {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                background-color: $blue-337;
            }

            &__icon--twitter {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                background-color: $black;
            }

            &__icon--linkedin {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                background-color: $blue-006;
            }

            &__icon--mail {
                border-radius: 50px;
                width: 35px;
                height: 35px;
                background-color: $orange;
            }

            .facebook-icon {
                fill: $white;
                width: 11px;
                height: 19px;
            }

            .twitter-icon {
                fill: $white;
                width: 19px;
                height: 15px;
            }

            .linkedin-icon {
                fill: $white;
                width: 15px;
                height: 15px;
            }

            .mail-icon {
                fill: $white;
                width: 40px;
                height: 35px;
            }
        }
    }
}

.job__details-again__first__office__icon,
.job__details-again__second__office__icon {
    width: 24px; /* Adjust the width and height as needed */
    height: 24px;
    fill: $dark-gray; /* Change this to the desired color */

    &--date {
        fill: transparent;
        stroke: $dark-gray;
        width: 24px; /* Adjust the width and height as needed */
        height: 24px;
    }
}

.job-title-link-container {
    position: relative;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
        padding: 0;
    }

    .page-title {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }
}
