.custom-contact-form-inputs {
    box-sizing: border-box;
    @include mt(25px);
    @include mb(25px);

    background-color: $black;
    color: $white;
    border: 1px solid $gray;
    @include border-radius(2px);

    &:focus {
        background-color: $black;
        color: $white;
    }
}

.file-upload {
    color: $white;
    border: 1px solid $gray;
    background-color: $black;
}

.contact_us {
    @include mt(170px);

    &--tabs {
        background-color: $gray-16;

        @include media-breakpoint-down(lg) {
            @include mr(10px);
            @include ml(10px);
            @include border-radius(10px);
        }

        &__wrap {
            padding: 40px 0px 50px;
            position: relative;
            @include mt(40px);

            .nav-tabs {
                display: none;
                border-bottom: none;

                @include media-breakpoint-up(lg) {
                    display: flex;
                }

                .nav-item {
                    width: 33%;
                    padding: 0;

                    &.show {
                        .nav-link {
                            color: $white;
                        }
                    }
                }

                .nav-link {
                    border: none;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    color: $gray-70;
                    background-color: transparent;
                    transition: all 0.3s ease;

                    &__content {
                        width: 90%;
                        position: relative;
                        transition: all 0.3s ease;
                        border-bottom: 2px solid $gray-70;

                        @include media-breakpoint-down(xl) {
                            min-height: 152px;
                        }

                        .arrow {
                            position: absolute;
                            right: 0;
                            top: 10px;
                            width: 30px;
                        }
                    }

                    &.active {
                        color: $white;

                        .nav-link__content {
                            border-bottom: 2px solid $purple;
                        }
                    }
                }
            }

            .tab-content {
                a {
                    color: $white;
                    text-decoration: none;

                    &.collapsed {
                        color: $gray-70;
                    }
                }

                & > .tab-pane {
                    @include media-breakpoint-down(lg) {
                        display: block !important;
                        opacity: 1;
                        @include mb(10px);
                    }
                }

                .card-header {
                    .nav-link {
                        &__content {
                            position: relative;
                            transition: all 0.3s ease;
                            border-bottom: 2px solid $purple;

                            .arrow {
                                position: absolute;
                                right: 0;
                                top: 10px;
                                width: 30px;
                                transform: rotate(180deg);
                                transition: all 0.3s ease;
                            }
                        }
                    }

                    .collapsed {
                        .nav-link {
                            &__content {
                                border-bottom: 2px solid $gray-70;

                                .arrow {
                                    transform: rotate(0deg);
                                }
                            }
                        }
                    }
                }

                .collapse-content {
                    @include mt(25px);

                    @include media-breakpoint-down(lg) {
                        @include mt(15px);
                    }

                    .card-body {
                        .location-tab {
                            label {
                                font-size: $font-size-28;
                                font-weight: $font-weight-normal;
                            }

                            .custom-contact-form-inputs {
                                @include mt(10px);
                                width: 90%;

                                @include media-breakpoint-down(lg) {
                                    width: 100%;
                                }

                                &:focus {
                                    color: $gray-70;
                                    border-color: $gray-70;
                                    background-color: $gray-16;
                                }
                            }

                            .location-offices {
                                @include mt(30px);
                                @include mb(10px);
                                display: none;
                                width: 100%;

                                &.show {
                                    display: inline-block;
                                }

                                &__flex {
                                    display: flex;
                                    align-items: flex-start;

                                    img {
                                        @include mr(10px);
                                    }
                                }
                            }

                            .map {
                                width: 100%;
                                height: 350px;

                                @include media-breakpoint-up(lg) {
                                    height: 660px;
                                }

                                @include media-breakpoint-down(lg) {
                                    @include mt(30px);
                                }
                            }
                        }

                        h6 {
                            font-size: $font-size-28;
                            font-weight: $font-weight-normal;
                        }

                        .send-email {
                            p {
                                @include mt(40px);
                                width: 85%;

                                @include media-breakpoint-down(lg) {
                                    @include mt(12px);
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .card {
            background-color: transparent;

            .card-header {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            .collapse {
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }
    }
}

.consent-hide {
    display: none;
}
