.hero-container {
    min-height: 100vh;

    .gradient {
        border-radius: 50%;
        opacity: 0.35;
        background: var(--csk-5-f-249-f-500, $purple);
        filter: blur(300px);
        position: absolute;
        top: 760px;
        width: 100%;
        height: 600px;
        z-index: -1;

        @include media-breakpoint-down(md) {
            top: 600px;
        }
    }
}

.hero-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 60px 100px 60px;

    @include media-breakpoint-down(md) {
        padding: 0px 60px 0px 60px;
    }

    & > div {
        & > .grid-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            column-count: 5;
            gap: 15px;
            height: 783px;
            width: 100vw;
            align-content: center;
            justify-content: center;

            @include media-breakpoint-down(xl) {
                column-count: 3;
                height: 1165px;
            }

            @include media-breakpoint-down(md) {
                column-count: 3;
                height: 675px;
                margin-bottom: 120px;
                gap: 10px;
            }

            & > div {
                width: 248px;

                @media screen and (max-width: 1441px) {
                    width: 244px;
                }

                @media screen and (max-width: 1025px) {
                    width: 278px;
                }

                @media screen and (max-width: 600px) {
                    width: 43%;
                }

                &:nth-child(1) {
                    height: 280px;

                    @include media-breakpoint-down(md) {
                        height: 190px;
                    }
                }

                &:nth-child(2) {
                    height: 280px;

                    @include media-breakpoint-down(md) {
                        height: 190px;
                    }
                }

                &:nth-child(3) {
                    height: 360px;

                    @include media-breakpoint-down(xl) {
                        height: 380px;
                    }

                    @include media-breakpoint-down(md) {
                        height: 190px;
                    }
                }

                &:nth-child(4) {
                    height: 280px;

                    @include media-breakpoint-down(xl) {
                        height: 380px;
                    }

                    @include media-breakpoint-down(md) {
                        height: 190px;
                    }
                }

                &:nth-child(5) {
                    height: 180px;

                    @include media-breakpoint-down(xl) {
                        height: 280px;
                    }

                    @include media-breakpoint-down(md) {
                        height: 190px;
                    }
                }

                &:nth-child(6) {
                    height: 360px;

                    @include media-breakpoint-down(xl) {
                        height: 280px;
                        margin-bottom: 140px;
                    }

                    @include media-breakpoint-down(md) {
                        height: 190px;
                        margin-bottom: -40px;
                    }
                }

                &:nth-child(7) {
                    height: 280px;

                    @include media-breakpoint-down(xl) {
                        height: 180px;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &:nth-child(8) {
                    height: 380px;

                    @include media-breakpoint-down(xl) {
                        height: 360px;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &:nth-child(9) {
                    height: 380px;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }

        .hero-card-shadow {
            figure {
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 20px;
                    background: linear-gradient(
                            180deg,
                            rgba(0, 0, 0, 0.45) 0%,
                            rgba(0, 0, 0, 0) 100%
                    );
                }
            }
        }

        .hero-card {
            position: relative;
            width: 100%;
            height: 100%;
            transition: all 500ms ease-out;
            transform: translateY(0);

            &:hover {
                transform: translateY(-5px);
            }

            figure {
                height: 100%;
                position: relative;
                display: inline-block;
                width: 100%;
            }

            img {
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }

            &__description {
                position: absolute;
                top: 20px;
                left: 20px;
                margin-right: 20px;

                &--title {
                    font-size: 18px;
                    color: $white;
                }
            }

            a {
                position: absolute;
                bottom: 20px;
                left: 20px;
                transition: opacity 0.3s;
            }

            &:hover {
                a {
                    opacity: 1 !important;
                }
            }
        }
    }
}

.new-hero-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0px;

    @include media-breakpoint-down(md) {
        padding: 0 20px;
        padding-bottom: 60px;
    }

    &.container {
        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }

        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }
    }

    .events-card {
        .hero-card-event {
            .no-events {
                min-height: 130px;
            }
        }
    }


    .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        width: 100%;

        @include media-breakpoint-down(xl) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            height: 763px;
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            height: auto;
        }

        & > div {
            @keyframes slide_up_default {
                0% {
                    transform: translate(0px, 200px);
                }
                100% {
                    transform: translate(0px, -40px);
                }
            }

            @keyframes slide_up_default_lower {
                0% {
                    transform: translate(0px, 200px);
                }
                100% {
                    transform: translate(0px, 0px);
                }
            }

            &:nth-child(1) {
                height: 297px;
                grid-area: 1 / 1 / 2 / 2;
                position: relative;
                transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
                transform: translate(0px, 0px);
                animation: slide_up_default_lower 2.0s;

                @include media-breakpoint-up(md) {
                    transform: translate(0px, 0px);
                    animation: slide_up_default_lower 2.0s;
                }

                @include media-breakpoint-up(xl) {
                    transform: translate(0px, -40px);
                    animation: slide_up_default 2.0s;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 30px;
                    background: linear-gradient(
                            170deg,
                            rgba(0, 0, 0, 0.65) 3.4%,
                            rgba(0, 0, 0, 0.4) 92.19%
                    );
                }

                @include media-breakpoint-down(xl) {
                    height: 263px;
                    grid-area: 1 / 2 / 2 / 3;
                }

                @include media-breakpoint-down(md) {
                    height: 200px;
                    order: 3;
                }

                .hero-card {
                    &__description {
                        position: absolute !important;
                        top: 20px;
                        left: 20px;
                    }
                    &__desc {
                        position: absolute !important;
                        left: 20px;
                        bottom: 20px;
                    }
                }
            }

            &:nth-child(2) {
                height: 263px;
                grid-area: 2 / 1 / 3 / 2;
                background: rgb(32, 209, 229);
                background: -moz-linear-gradient(
                        159deg,
                        rgba(32, 209, 229, 1) 0%,
                        rgba(17, 161, 177, 1) 0%,
                        rgba(0, 105, 117, 1) 100%
                );
                background: -webkit-linear-gradient(
                        159deg,
                        rgba(32, 209, 229, 1) 0%,
                        rgba(17, 161, 177, 1) 0%,
                        rgba(0, 105, 117, 1) 100%
                );
                background: linear-gradient(
                        159deg,
                        rgba(32, 209, 229, 1) 0%,
                        rgba(17, 161, 177, 1) 0%,
                        rgba(0, 105, 117, 1) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#20d1e5",endColorstr="#006975",GradientType=1);
                border-radius: 30px;
                transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
                transform: translate(0px, 0px);
                animation: slide_up_default_lower 2.0s;

                @include media-breakpoint-up(md) {
                    transform: translate(0px, -40px);
                    animation: slide_up_default 2.0s;
                }

                .hero-card {
                    max-width: 420px;
                    &__description {
                        width: 60%;

                        @include media-breakpoint-down(xl) {
                            width: 50%;
                        }
                    }

                    &__marquee-top {
                        position: absolute;
                        max-width: 420px;
                        bottom: 60px;
                        left: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        box-sizing: border-box;
                        z-index: 2000;
                        cursor: default;
                        height: 28px;

                        p {
                            display: flex;
                            gap: 20px;
                            cursor: pointer;
                            font-size: 22px;
                            color: rgba($color: $white, $alpha: 0.5);
                            animation: marqueeLeft 40s linear infinite;

                            a {
                                color: rgba($color: $white, $alpha: 0.5);
                                text-decoration: none;
                                transition: all 0.2s ease-in;

                                &:hover {
                                    color: $white;
                                }
                            }
                        }
                    }
                    &__marquee-bottom {
                        position: absolute;
                        z-index: 2000;
                        bottom: 20px;
                        max-width: 420px;
                        right: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        box-sizing: border-box;
                        cursor: default;
                        height: 28px;

                        p {
                            display: flex;
                            gap: 20px;
                            cursor: pointer;
                            font-size: 22px;
                            color: rgba($color: $white, $alpha: 0.5);
                            animation: marqueeRight 40s linear infinite;

                            a {
                                color: rgba($color: $white, $alpha: 0.5);
                                text-decoration: none;
                                transition: all 0.2s ease-in;

                                &:hover {
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                @include media-breakpoint-down(xl) {
                    grid-area: 3 / 1 / 4 / 2;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &:nth-child(3) {
                overflow: hidden;
                height: 580px;
                grid-area: 1 / 2 / 3 / 3;
                border-radius: 30px;
                background: rgb(156, 120, 195);
                background: -moz-linear-gradient(
                        51deg,
                        rgba(156, 120, 195, 1) 0%,
                        rgba(121, 71, 174, 1) 0%,
                        rgba(95, 36, 159, 1) 100%
                );
                background: -webkit-linear-gradient(
                        51deg,
                        rgba(156, 120, 195, 1) 0%,
                        rgba(121, 71, 174, 1) 0%,
                        rgba(95, 36, 159, 1) 100%
                );
                background: linear-gradient(
                        51deg,
                        rgba(156, 120, 195, 1) 0%,
                        rgba(121, 71, 174, 1) 0%,
                        rgba(95, 36, 159, 1) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9c78c3",endColorstr="#5f249f",GradientType=1);
                transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
                transform: translate(0px, 0px);
                animation: slide_up_default_lower 2.0s;

                @include media-breakpoint-up(md) {
                    transform: translate(0px, -40px);
                    animation: slide_up_default 2.0s;
                }

                @include media-breakpoint-up(xl) {
                    transform: translate(0px, 0px);
                    animation: slide_up_default_lower 2.0s;
                }

                .hero-card {
                    &__description {
                        width: 40%;
                    }
                    @include media-breakpoint-up(md) {
                        &:hover {
                            .hero-card__svg-line-left {
                                transform: translateX(-100%);
                            }
                            .hero-card__svg-line-right {
                                transform: translateX(100%);
                            }
                            .hero-card__description--title {
                                transform: scale(0.75) translateX(-30px)
                                translateY(-30px);
                            }
                            .hero-card__jobs {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    }
                }

                @include media-breakpoint-down(xl) {
                    height: auto;
                    grid-area: 1 / 1 / 3 / 2;
                }

                @include media-breakpoint-down(md) {
                    order: 1;
                    overflow: visible;
                    padding: 20px;
                }
            }

            &:nth-child(4) {
                height: 580px;
                grid-area: 1 / 3 / 3 / 4;
                border-radius: 30px;
                overflow: hidden;
                transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
                background: rgb(255, 202, 131);
                background: -moz-linear-gradient(
                        0deg,
                        rgba(255, 202, 131, 1) 0%,
                        rgba(255, 167, 89, 1) 0%,
                        rgba(255, 156, 76, 1) 100%
                );
                background: -webkit-linear-gradient(
                        0deg,
                        rgba(255, 202, 131, 1) 0%,
                        rgba(255, 167, 89, 1) 0%,
                        rgba(255, 156, 76, 1) 100%
                );
                background: linear-gradient(
                        0deg,
                        rgba(255, 202, 131, 1) 0%,
                        rgba(255, 167, 89, 1) 0%,
                        rgba(255, 156, 76, 1) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffca83",endColorstr="#ff9c4c",GradientType=1);
                transform: translate(0px, 0px);
                animation: slide_up_default_lower 2.0s;

                @include media-breakpoint-up(xl) {
                    transform: translate(0px, -40px);
                    animation: slide_up_default 2.0s;
                }

                .hero-card {
                    @include media-breakpoint-up(md) {
                        &:hover {
                            .hero-card__svg-circle-left {
                                transform: translateX(-100%);
                            }
                            .hero-card__svg-circle-right {
                                transform: translateX(100%);
                            }
                            .hero-card__description--title {
                                transform: scale(0.75) translateX(-65px)
                                translateY(-30px);
                            }
                            .hero-card__events {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    }

                    &__description {
                        @include media-breakpoint-down(md) {
                            width: 80%;
                        }
                    }
                }

                @include media-breakpoint-down(xl) {
                    height: auto;
                    grid-area: 2 / 2 / 4 / 3;
                }

                @include media-breakpoint-down(md) {
                    order: 2;
                    overflow: visible;
                    padding: 20px;
                }
            }
        }
    }

    .hero-card {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            overflow: visible;
        }

        &__svg-line-left {
            transition: all 400ms;
            transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &__svg-line-right {
            transition: all 400ms;
            transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &__svg-circle-left {
            transition: all 400ms;
            transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
            position: absolute;
            left: 0;
            bottom: 0;
            @include media-breakpoint-down(xl) {
                bottom: -100px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &__svg-circle-right {
            transition: all 400ms;
            transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
            position: absolute;
            right: 0;
            bottom: 0;
            @include media-breakpoint-down(xl) {
                bottom: -100px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &__jobs {
            position: absolute;
            opacity: 0;
            transform: translateY(100%);
            transition: all 400ms;
            transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
            padding: 10px;
            top: 100px;

            @include media-breakpoint-down(md) {
                transform: translateY(0);
                transition: none;
                opacity: 1;
                position: initial;
                padding: 0;
                padding-top: 15px;
            }

            &__job {
                padding: 15px 20px 20px 20px;
                cursor: pointer;
                border-radius: 20px;

                @include media-breakpoint-down(md) {
                    padding: 13px 0;
                }

                @include media-breakpoint-up(md) {
                    &:hover {
                        background: rgba(255, 255, 255, 0.2);

                        .hero-card__jobs__job__title {
                            opacity: 1;
                            -webkit-text-fill-color: $white;
                        }

                        .hero-card__jobs__job__place {
                            fill: $white;
                            color: $white;
                            opacity: 1;
                        }
                    }
                }

                a {
                    color: $white;
                    text-decoration: none;
                }

                &__title {
                    line-height: 140%;
                    font-size: 20px;
                    font-weight: $font-weight-semibold;
                    opacity: 0.5;
                    background: linear-gradient(87deg, $white -59.22%, rgba(255, 255, 255, 0) 193.82%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                }

                &__place {
                    margin-top: 5px;
                    align-items: center;
                    display: flex;
                    color: $white;
                    opacity: 0.5;
                    font-size: 14px;

                    @include media-breakpoint-down(md) {
                        font-size: 13px;
                    }

                    svg {
                        height: 20px;
                        width: 20px;
                        fill: rgba($color: white, $alpha: 0.5);
                    }
                }
            }
        }

        &__see-all-jobs {
            position: absolute;
            z-index: 1000;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: start;
            height: 125px;
            width: 100%;
            background: transparent;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7847ad",endColorstr="#6a33a6",GradientType=1);
            padding: 0 30px;

            @include media-breakpoint-down(md) {
                padding: 0;
                bottom: -10px;
                height: 70px;
                background: rgb(120, 71, 173);
                background: -moz-linear-gradient(51deg, rgba(120, 71, 173, 0.7) 0%, rgba(106, 51, 166, 0.7) 100%);
                background: -webkit-linear-gradient(51deg, rgba(120, 71, 173, 0.7) 0%, rgba(106, 51, 166, 0.7) 100%);
                background: linear-gradient(51deg, rgba(120, 71, 173, 0.7) 0%, rgba(106, 51, 166, 0.7) 100%);
            }

            a {
                text-decoration: none;
                color: $white;
                opacity: 0;
                transition: all 400ms;
                transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
                background-color: $purple;
                border: none;
                padding: 15px 20px 15px 25px;
                font-size: 14px;
                text-transform: uppercase;

                @include media-breakpoint-down(md) {
                    opacity: 1;
                    transition: none;
                    border-radius: 15px;
                }

                svg {
                    stroke: $white;
                }
            }
        }

        &__events {
            position: absolute;
            opacity: 0;
            transform: translateY(100%);
            transition: all 400ms;
            transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
            padding: 10px;
            top: 100px;

            @include media-breakpoint-down(md) {
                transform: translateY(0);
                transition: none;
                opacity: 1;
                position: initial;
                padding: 0;
                padding-top: 15px;
            }

            &__event {
                padding: 15px 20px 20px 20px;
                cursor: pointer;
                border-radius: 20px;

                @include media-breakpoint-down(md) {
                    padding: 13px 0;
                }

                @include media-breakpoint-up(md) {
                    &:hover {
                        background: rgba(255, 255, 255, 0.2);

                        .hero-card__events__event__title {
                            opacity: 1;
                            -webkit-text-fill-color: $white;
                        }

                        .hero-card__events__event__place {
                            fill: $white;
                            color: $white;
                            opacity: 1;
                        }
                    }
                }

                a {
                    color: $white;
                    text-decoration: none;
                }

                &__title {
                    line-height: 140%;
                    font-size: 20px;
                    font-weight: $font-weight-semibold;
                    background: linear-gradient(87deg, $white -31.29%, rgba(255, 255, 255, 0) 169.31%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                }

                &__place {
                    margin-top: 5px;
                    align-items: center;
                    display: flex;
                    color: $white;
                    opacity: 0.5;
                    font-size: 14px;
                    text-transform: capitalize;

                    @include media-breakpoint-down(md) {
                        font-size: 13px;
                    }

                    svg {
                        height: 20px;
                        width: 20px;
                        fill: rgba($color: white, $alpha: 0.5);
                    }
                }
            }
        }

        &__see-all-events {
            position: absolute;
            z-index: 1000;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: start;
            height: 125px;
            width: 100%;
            padding: 0 30px;
            background: transparent;

            @include media-breakpoint-down(md) {
                bottom: -10px;
                top: auto;
                padding: 0;
                height: 70px;
                background: rgba(255, 165, 86, 0.8);
            }

            a {
                text-decoration: none;
                color: $white;
                opacity: 0;
                transition: all 400ms;
                transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
                background-color: #ff782a;
                border: none;
                padding: 15px 20px 15px 25px;
                font-size: 14px;
                min-width: 140px;
                text-transform: uppercase;

                @include media-breakpoint-down(md) {
                    opacity: 1;
                    transition: none;
                    border-radius: 15px;
                }

                svg {
                    stroke: $white;
                }
            }
        }

        figure {
            height: 100%;
            position: relative;
            display: inline-block;
            width: 100%;
        }

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 30px;
        }

        &__description {
            position: absolute;
            top: 30px;
            left: 30px;
            z-index: 100;

            @include media-breakpoint-down(md) {
                position: initial;
            }

            &--title {
                transition: all 400ms;
                transition-timing-function: cubic-bezier(0.05, 0.7, 0.1, 1);
                font-weight: $font-weight-normal;
                font-size: 40px;
                background: linear-gradient(87deg, $white -59.22%, rgba(255, 255, 255, 0) 193.82%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @include media-breakpoint-down(xl) {
                    font-size: 35px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 24px;
                }
            }
        }

        &__desc {
            z-index: 100;
            position: absolute;
            bottom: 30px;
            left: 30px;
            font-weight: $font-weight-normal;
            font-size: 18px;
            background: linear-gradient(87deg, $white -59.22%, rgba(255, 255, 255, 0) 193.82%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            @include media-breakpoint-down(xl) {
                font-size: 15px;
            }

            @include media-breakpoint-down(md) {
                position: initial;
                font-size: 15px;
            }
        }
    }


    .hero-card-job {
        &:hover {
            a {
                opacity: 1;
            }

            .hero-card__see-all-jobs {
                background: rgb(120, 71, 173);
                background: -moz-linear-gradient(51deg, rgba(120, 71, 173, 0.8) 0%, rgba(106, 51, 166, 0.8) 100%);
                background: -webkit-linear-gradient(51deg, rgba(120, 71, 173, 0.8) 0%, rgba(106, 51, 166, 0.8) 100%);
                background: linear-gradient(51deg, rgba(120, 71, 173, 0.8) 0%, rgba(106, 51, 166, 0.8) 100%);
            }
        }
    }

    .hero-card-event {
        &:hover {
            a {
                opacity: 1;
            }

            .hero-card__see-all-events {
                background: rgba(255, 165, 86, 0.8);
            }
        }
    }

}

@keyframes marqueeLeft {
    0% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

@keyframes marqueeRight {
    0% {
        transform: translate(-180%, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}
