.white-background.jobs {
    padding: 0;
}

.jobs {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 40px;
    padding-bottom: 60px;

    @include media-breakpoint-down(md) {
        padding: 25px 20px 20px;
        gap: 25px;
    }

    &__listing-summary {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__options {
            display: flex;
            gap: 10px;

            .dropdown {
                .dropdown-face {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    padding: 8px 10px;
                    padding-left: 15px;
                    border-radius: 10px;
                    border: 1.5px solid $gray-e6e6;
                    background-color: $white;
                    color: $dark-gray-15;

                    .grey-text {
                        color: $gray;
                    }
                }

                .dropdown-menu {
                    border-radius: 15px;
                    box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
                    width: 100%;
                    min-width: unset;
                    padding: 0;
                    overflow: hidden;
                    z-index: 900;

                    .dropdown-item {
                        padding: 15px 20px;
                        color: $dark-gray-15;

                        &:hover {
                            background-color: $gray-f9;
                        }
                    }

                    &:nth-child(2) {
                        width: 160px;
                    }
                }

                &.recent {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &.jobs-per-page {
                    &.desktop {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }

                    &.mobile {
                        @include media-breakpoint-up(md) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include media-breakpoint-down(md) {
            gap: 15px;
        }

        &__job {
            display: flex;
            padding: 25px 25px 25px 30px;
            align-items: flex-start;
            gap: 100px;
            border-radius: 20px;
            border: 1.5px solid $gray-e6e6;
            background-color: $white;
            text-decoration: none;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                padding: 20px;
                gap: 0;
            }

            &:hover {
                @include media-breakpoint-up(md) {
                    border-radius: 20px;
                    border-color: transparent;
                    box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
                }
            }

            &__details {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
                flex: 1 0 0;

                @include media-breakpoint-down(md) {
                    gap: 5px;
                }

                &__tags {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;

                    @include media-breakpoint-down(md) {
                        gap: 5px;
                        margin-top: 10px;
                    }

                    &__location {
                        display: flex;
                        padding: 5px 10px 5px 5px;
                        align-items: center;
                        height: 34px;
                        border-radius: 10px;
                        background-color: $gray-90;

                        @include media-breakpoint-down(md) {
                            padding: 7px 10px;
                        }

                        .pin-icon {
                            margin-right: 6px;

                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }

                    &__hotjob {
                        display: flex;
                        height: 34px;
                        @include p(5px 10px);
                        align-items: center;
                        border-radius: 10px;
                        border: 2px solid $gray-90;
                        width: 140px;

                        p {
                            @include media-breakpoint-down(md) {
                                color: $dark-gray-15;
                            }
                        }
                    }
                }
            }

            &__buttons {
                position: relative;
                display: flex;
                align-items: flex-start;
                gap: 16px;
                width: 52px;
                @include pt(10px);
                @include pl(5px);

                @include media-breakpoint-up(md) {
                    width: 97px;
                }

                .share-job-options {
                    position: absolute;
                    display: none;
                    top: 40px;
                    left: -135px;
                    background-color: $white;
                    width: 171px;
                    height: 148px;
                    z-index: 9;
                    border-radius: 15px;
                    box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);
                    -webkit-box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);
                    -moz-box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.1);

                    @include media-breakpoint-up(md) {
                        width: 195px;
                        height: 375px;
                        left: -20px;
                        top: 45px;
                    }

                    @media screen and (min-width: 1150px) {
                        left: 45px;
                    }

                    &.shown {
                        display: block;
                    }

                    &__mobile {
                        display: block;
                        @include p(10px);

                        @include media-breakpoint-up(md) {
                            display: none;
                        }

                        .share-job-option {
                            border: none;
                            background-color: transparent;
                            align-items: center;
                            display: flex;
                            gap: 10px;
                            @include p(12px 5px);
                            font-size: 14px;
                            line-height: 19.6px;
                            width: 100%;
                            border-radius: 10px;

                            &.job-bookmark {
                                img {
                                    filter: brightness(0) invert(0);
                                }

                                &.bookmarked {
                                    background-color: $bright-purple-90;

                                    img {
                                        filter: brightness(1) invert(0);
                                    }
                                }
                            }
                        }
                    }

                    &__desktop {
                        display: none;

                        @include media-breakpoint-up(md) {
                            display: block;
                        }

                        .share-job-option {
                            border: none;
                            background-color: transparent;
                            align-items: center;
                            display: flex;
                            gap: 10px;
                            @include p(20px 25px 20px 20px);
                            font-size: 14px;
                            line-height: 19.6px;
                            width: 100%;

                            &:hover {
                                background-color: #fbfbfb;
                            }

                            &__icon-wrapper {
                                align-items: center;
                                display: flex;
                                justify-content: center;
                                border-radius: 50px;
                                width: 35px;
                                height: 35px;

                                &--refer {
                                    background-color: $purple;

                                    img {
                                        width: 14px;
                                    }
                                }

                                &--facebook {
                                    background-color: $blue-337;

                                    svg {
                                        fill: $white;
                                        height: 19px;
                                        width: 11px;
                                    }
                                }

                                &--twitter {
                                    background-color: $black;

                                    svg {
                                        fill: $white;
                                        width: 19px;
                                        height: 15px;
                                    }
                                }

                                &--linkedin {
                                    background-color: $blue-006;

                                    svg {
                                        fill: $white;
                                        width: 15px;
                                        height: 15px;
                                    }
                                }

                                &--mail {
                                    background-color: $orange;

                                    svg {
                                        fill: $white;
                                        width: 40px;
                                        height: 35px;
                                    }
                                }
                            }
                        }
                    }
                }

                &__icon {
                    width: 30px;
                    height: 30px;

                    &:first-child {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }

                    button {
                        border: none;
                        background-color: transparent;
                        @include p(0);
                        width: 30px;
                        height: 30px;
                    }
                }

                .bookmarked {
                    .save-job-icon {
                        content: url("/theme/luxoft/assets/images/icons/bookmark-purple-remove.svg");
                        width: 24px;
                        height: 24px;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }

    &__no-vacancies {
        @include p(70px 0 40px);

        p {
            line-height: 140%;
            font-size: 15px;

            @include media-breakpoint-up(md) {
                font-size: 18px;
            }
        }
    }
}

.jobs-page-title {
    padding-bottom: 45px !important;

    @include media-breakpoint-down(md) {
        padding-bottom: 15px !important;
    }

    &__wrapper {
        width: 310px;
        height: 60px;

        @include media-breakpoint-up(md) {
            width: 480px;
            height: 85px;
        }

        @include media-breakpoint-up(xl) {
            width: 510px;
            height: 90px;
        }

        @include media-breakpoint-up(xxl) {
            width: 560px;
            height: 100px;
        }

        h1 {
            line-height: normal;
            font-family: $font-family-base;
        }
    }
}

.jobs-filters {
    height: 160px;
    @include p(0 20px);

    @include media-breakpoint-up(md) {
        height: 168px;
        @include p(0);
    }

    &:has(.jobs-filters__chips) {
        @include media-breakpoint-up(md) {
            height: 295px;
        }
    }

    &__search-container {
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        background-color: $white;
        box-shadow: 0px 4px 35px 0px rgba(95, 36, 159, 0.25);
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            padding: 20px 20px 20px 15px;
            border-radius: 15px;
            height: 64px;
        }

        &__icon {
            display: none;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        > input {
            display: flex;
            border: none;
            padding: 15px;
            color: $black;
            width: 100%;
            height: 55px;
            cursor: pointer;
            cursor: auto;

            @include media-breakpoint-down(xl) {
                min-width: 250px;
            }

            @include media-breakpoint-down(md) {
                height: auto;
                padding: 0;
            }

            &:focus-visible {
                border: none;
                outline: none;
            }
        }

        .separator {
            width: 1.5px;
            height: 25px;
            background-color: $gray-e6e6;
            align-self: center;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        > .dropdown {
            @include dropdown-search();
        }

        > button {
            padding: 14px;
            padding-top: 13px;
            height: 52px;
            min-width: 52px;

            > img {
                width: 26px;
                height: 26px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &__chips {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
            gap: 10px;
        }

        &--chip {
            text-align: center;
            background-color: $purple-362e;
            border-radius: 10px;
            @include pl(14px);
            height: 44px;
            display: flex;
            align-items: center;
            gap: 2px;
            transition: all 0.3s ease;
            flex-shrink: 0;

            &:hover {
                background-color: #595261;

                button {
                    img {
                        filter: brightness(0) invert(1);
                    }
                }
            }

            p {
                font-size: 14px;
                color: $white;
            }

            button {
                font-size: 14px;
                color: $white;
                border: none;
                background-color: transparent;
            }

            &.counter-chip {
                @include p(0 14px);
            }
        }
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .form-switch {
            display: flex;
            align-items: center;
            gap: 15px;

            .form-check-input {
                width: 38px;
                height: 24px;
                cursor: pointer;
            }
        }

        &__save {
            display: flex;
            align-items: center;
            gap: 10px;
            border: none;
            background-color: transparent;

            @include media-breakpoint-down(md) {
                display: none;
            }

            &.light-purple {
                color: $bright-purple-70;

                img {
                    filter: invert(75%) sepia(47%) saturate(1878%) hue-rotate(205deg) brightness(106%) contrast(105%);
                }
            }
        }
    }
}

.jobs-keyword-search-panel {
    position: fixed;
    transform: translateY(100%);
    width: 100%;
    height: 100vh;
    transition: opacity 0.2s ease, transform 0.2s ease;
    top: 0;
    background-color: $white;
    z-index: 1001;
    left: 0;

    &.show {
        opacity: 1;
        transform: translateY(0);

        @include media-breakpoint-up(md) {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    &__close {
        @include p(0 20px);
    }

    &__results {
        @include p(0 20px);
        height: 70vh;
        overflow-x: hidden;

        &.item-selected {
            height: 70dvh;
        }

        .nothing-found {
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            gap: 20px;
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 0;

                a {
                    color: $dark-gray-15;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }

                span {
                    display: block;
                    @include mt(5px);
                    color: $gray;
                    font-size: 13px;
                }

                label {
                    color: $dark-gray-15;
                    width: 100%;
                    cursor: pointer;
                    font-size: 15px;
                }

                input[type="radio"] {
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: transparent;
                    place-content: center;

                    &::before {
                        content: url("/theme/luxoft/assets/images/icons/check-purple.svg");
                        width: 24px;
                        height: 24px;
                        opacity: 0;
                    }

                    &:checked {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__input-section {
        display: flex;
        @include p(0 20px);
        gap: 10px;

        .back-btn {
            border: none;
            background-color: transparent;
        }

        .filter-input-search {
            width: -webkit-fill-available;
        }

        @include filter-input-search();
    }
}

.jobs-listing-panels {

    &:has(.mobile-single-search-panel.shown) {
        height: 600px;
    }

    &.embedded-jobs-v {
        @include  pb(20px);

        .jobs-listing-panels__list {
            .filter-search-results {
                height: 230px
            }
        }
    }

    .mobile-all-filters {
        width: 100%;
        transition: all 0.2s ease-in;

        &.hidden {
            transform: translateX(-105%);
        }
    }

    .mobile-single-search-panel {
        transform: translateX(100%);
        width: 100%;
        position: absolute;
        transition: all 0.2s ease-out;

        &.shown {
            transform: translateX(0);
        }
    }

    &__head {
        &.main-panel {
            p {
                width: 45%;

                @media screen and (min-width: 455px) {
                    width: 55%;
                }

                @media screen and (min-width: 600px) {
                    width: 65%;
                }
            }
        }

        .save-search-btn {
            border: 1px solid $gray-e6e6;
            border-radius: 10px;
            @include p(3px);

            img {
                filter: brightness(0) invert(0);
            }

            &.saved {
                border: 1px solid $bright-purple-70;

                img {
                    filter: none;
                }
            }
        }

        .clear-all-btn {
            text-transform: uppercase;
            border-radius: 10px;
            background-color: $bright-purple-90;
            color: $purple;
            font-size: 12px;
            font-weight: $font-weight-semibold;
            @include p(10px);
        }
    }

    &__content {
        @include p(5px 20px);
        @include filter-input-search();

        .chips-filter-mobile {
            display: flex;
            gap: 10px;
            @include mb(12px);
            flex-wrap: wrap;

            &--chip {
                text-align: center;
                background-color: $gray-90;
                border-radius: 10px;
                padding-left: 12px;
                height: 34px;
                display: flex;
                align-items: center;
                gap: 2px;
                transition: all 0.3s ease;
                flex-shrink: 0;

                &:hover {
                    background-color: $gray-80;

                    button {
                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }

                p {
                    font-size: 14px;
                    color: $dark-gray;
                }

                button {
                    border: none;
                    background-color: transparent;
                }
            }
        }

        .input-filter-wrapper {
            @include mb(12px);

            button {
                background-color: transparent;
                border: 1px solid $gray-e6e6;
                border-radius: 15px;
                @include p(20px 18px);
                width: 100%;
                text-align: left;
                color: $gray;
                font-size: 15px;
                position: relative;

                .arrow {
                    padding: 3px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    border: 2px solid $gray;
                    margin: 4px 2px 2px 7px;
                    border-width: 0 2px 2px 0;
                    position: absolute;
                    right: 24px;
                }
            }
        }
    }

    &__list {
        border-radius: 15px;
        border: none;
        min-width: unset;
        @include p(0 20px);

        &.checkbox-list {
            @include p(0);

            ul {
                li {
                    @include p(13px 20px);

                    &:has(input[type="checkbox"]:checked) {
                        background-color: $bright-purple-90;
                    }
                }
            }
        }

        .filter-search-results {
            height: 335px;
            overflow-x: hidden;
        }

        ul {
            list-style: none;
            @include pl(0);

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include p(15px 0);

                label {
                    width: 100%;
                    cursor: pointer;
                    font-size: 15px;
                }

                input[type="radio"],
                input[type="checkbox"] {
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: transparent;
                    place-content: center;

                    &::before {
                        content: url("/theme/luxoft/assets/images/icons/check-purple.svg");
                        width: 24px;
                        height: 24px;
                        opacity: 0;
                    }

                    &:checked {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.embedded-jobs-listing {
    &.no-filters {
        .jobs-filters {
            display: none;
        }

        .jobs {
            @include pt(60px);
        }
    }

    h2:not(.subtitle-l) {
        font-size: 35px;

        @include media-breakpoint-up(md) {
            font-size: 50px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 55px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 60px;
        }
    }

    .jobs-filters {
        @include mb(0);

        &__search-container {
            border: 1.5px solid $gray-e6e6;
            box-shadow: none;
            @include p(20px 8px 20px 15px);

            @include media-breakpoint-up(md) {
                @include p(10px);
            }

            & > button {
                display: block;
                width: 44px;
            }

            .dropdown {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }

        &__countries-container {
            border: 1.5px solid $gray-e6e6;
            border-radius: 15px;

            @include media-breakpoint-up(md) {
                display: none;
            }

            .dropdown {
                @include dropdown-search();

                .dropdown-face {
                    display: flex;
                }
            }
        }
    }

    &__pagination {
        .pagination {
            @include mb(0);

            li {
                @include m(0 5px);

                &:first-child,
                &:last-child {
                    display: none;
                }

                button {
                    font-size: 14px;
                    background-color: transparent;
                    color: $dark-gray;
                    border: none;
                    border-radius: 15px;
                    @include p(14px 18px);

                    &:hover {
                        color: $purple;
                    }

                    &.active-page {
                        background-color: $primary;
                        color: $white;
                    }

                    &.disabled {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &__overlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        display: block;
        opacity: 0;
        transform: translateX(-10000px);
        transition: opacity 1s;

        &.show {
            transform: translateX(0);
            opacity: 1;

            @include media-breakpoint-up(md) {
                opacity: 0;
                transform: translateX(-10000px);
            }
        }
    }

    .jobs {
        @include pb(40px);
    }
}

body {
    &:has(.jobs-keyword-search-panel.show) {
        overflow-y: hidden;
    }
}
