@import "footer";
@import "hero-block";
@import "careers-with-impact";
@import "our-locations";
@import "locations";
@import "events";
@import "jobs";
@import "anchors-header";
@import "worlds-best-workplace";
@import "latest-opportunities";
@import "life-stories";
@import "career-legacy";
@import "custom-animations";
@import "custom-carousel";
@import "pagination";
@import "event-form";
@import "job";
@import "life-at-luxoft";
@import "how-we-hire";
@import "about-us";
@import "snippets";
@import "error-screens";
@import "logeek-magazine";
@import "search-results";
@import "thank-you";
@import "hero-locations";
@import "banner-text-snippet";
@import"primary-project-roles";
@import"referral-modules";
@import"refer-people-section";

.link-disabled {
    pointer-events: none;
    color: $gray;
}

.bg-header {
    background: transparent;

    a {
        color: $white !important;
    }

    a:hover {
        text-decoration: underline;
    }
}

.page-title {
    padding-top: 148px;
    padding-bottom: 60px;
    color: $white;

    @include media-breakpoint-down(xxl) {
        max-width: 970px;
    }

    @include media-breakpoint-down(xl) {
        max-width: 864px;
    }

    @include media-breakpoint-down(md) {
        padding: 99px 20px 40px;
    }
}

.bg-gradient {
    border-radius: 50%;
    opacity: 0.45;
    background: var(--csk-5-f-249-f-500, $purple);
    filter: blur(200px);
    position: absolute;
    top: 200px;
    width: 100%;
    height: 600px;
    z-index: -1;

    @include media-breakpoint-down(md) {
        position: absolute;
        top: 50px;
        border-radius: 600px;
        opacity: 0.55;
        background: var(--csk-5-f-249-f-500, $purple);
        filter: blur(100px);
    }
}

#blackSection2 {
    padding-top: 80px;

    @include media-breakpoint-down(md) {
        padding-top: 30px;
    }
}

.black-background {
    background-color: $dark-gray-15;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 90px;

    @include media-breakpoint-down(md) {
        padding-bottom: 25px;
        gap: 60px;
    }

    &.logeek-magazine-lp {
        &.first-black {
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            padding-bottom: 0;
            gap: 0;

            @include media-breakpoint-down(md) {
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
            }
        }

        &.second-black {
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            padding-top: 45px !important;
            padding-bottom: 0;

            @include media-breakpoint-down(md) {
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
            }
        }
    }

    &.about-us-lp {
        &.first-black {
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            padding-bottom: 80px;

            @include media-breakpoint-down(md) {
                @include p(0 20px 25px);
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
            }
        }

        &.second-black {
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
        }
    }

    &#blackSection1:not(.life-at-luxoft-lp):not(.about-us-lp):not(.logeek-magazine-lp) {
        @include mt(110px);
    }

    p {
        a {
            color: $navy-80;
            text-decoration: none;
            transition: all 0.3s ease;

            &:hover,
            &:focus {
                color: $navy-90;
                text-decoration: underline;
            }

            &:active,
            &:visited {
                color: $gray-66;
                text-decoration: underline;
            }
        }
    }
}


.white-background,
.grey-background {
    position: relative;
    z-index: 2;
    background-color: $white;
    border-radius: 40px;
    will-change: transform;
    padding: 80px 0px;
    display: flex;
    flex-direction: column;
    gap: 90px;

    &--no-boder-radius-top {
        border-top-right-radius: initial !important;
        border-top-left-radius: initial !important;
    }

    &.logeek-magazine-lp {
        border-radius: 0;
    }

    &.single-event {
        display: block;
        @include p(0);
    }

    @include media-breakpoint-down(md) {
        border-radius: 25px;
        padding: 40px 0px;
        gap: 60px;
    }

    &:has(.embedded-jobs-listing) {
        will-change: initial;
    }

    p {
        a {
            @include link-styles;
        }
    }
}

.homepage-first {
    @include pt(120px);
    margin-bottom: 60px;
    z-index: 2;
    position: relative;

    @include media-breakpoint-up(md) {
        @include pt(168px);
    }
}

.homepage-title {
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {
        text-align: left !important;
        margin-left: 20px;
        width: 80%;
    }

    @include media-breakpoint-up(xxl) {
        width: 80%;
        margin: 0 auto 15px;
    }
}

.grey-background {
    background-color: $gray-90;
}

.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    position: relative;

    @include media-breakpoint-down(md) {
        align-items: start;
    }
}

.homepage-white-section {
    .container {
        @include media-breakpoint-between(xl, xxl) {
            max-width: 1280px;
        }
    }
}

.special-container {
    @include media-breakpoint-between(xl, xxl) {
        max-width: 1280px;
    }
}

.home-search-results__wrapper::-webkit-scrollbar {
    width: 2px;
}

.home-search-results__wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $white;
}

.home-search-results__wrapper::-webkit-scrollbar-thumb {
    background-color: $gray-999;
    outline: 1px solid $gray-999;
}

.home-search-results {
    width: 700px;
    background-color: $white;
    height: 0;
    opacity: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    top: 75px;
    transition: all 0.2s ease;
    border-top: 1px solid $gray-90;
    @include p(16px 0 16px 0);

    @include media-breakpoint-down(xxl) {
        width: 560px;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }

    &.active {
        height: 310px;
        opacity: 1;
    }

    &__wrapper {
        overflow-y: scroll;
        height: 95%;

        .result-item {
            @include p(10px 0 10px 19px);
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 5px;
            text-decoration: none;

            &:hover {
                background-color: $gray-f9;
            }

            p {
                font-size: 14px;
                color: $dark-gray-15;
            }
        }

        .nothing-found {
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            gap: 20px;

            p {
                font-size: 14px;
            }
        }

    }
}

.job-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 700px;

    @include media-breakpoint-down(xxl) {
        width: 560px;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        padding: 0 20px;
    }

    .career-search {
        display: flex;
        background-color: $white;
        padding: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        border: 1.5px solid transparent;
        width: 100%;
        font-size: 14px;
        gap: 10px;

        &.showing-results {
            @include media-breakpoint-up(md) {
                border-radius: 20px 20px 0 0;
            }
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            gap: 0;
        }

        &:focus-within {
            @include media-breakpoint-up(md) {
                border: 1.5px solid $orange;
            }
        }

        &__icon {
            display: none;
            align-self: center;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        &__input {
            border: none;
            padding: 15px;
            color: $black;
            width: 80%;
            border-radius: 15px;

            &:focus-visible {
                border: none;
                outline: none;
            }
        }

        button {
            margin-left: auto;
        }
    }

    .top-searched-roles {
        color: $gray-500;
        column-gap: 5px;
        font-weight: $font-weight-base;

        &__label {
            font-size: 14px;
            margin-right: 10px;
            margin-bottom: 0px;

            @include media-breakpoint-down(md) {
                margin-right: 0;
                display: none;
            }
        }

        &__role {
            a {
                cursor: pointer;
                background-color: $gray-21;
                padding: 5px 15px;
                color: $gray;
                border-radius: 10px;
                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 24px;
                text-decoration: none;
                &:hover {
                    color: $white;
                    background-color: $gray-30;
                }

                @include media-breakpoint-down(md) {
                    width: fit-content;
                    font-size: 13px;
                    line-height: 140%;
                    padding: 10px 15px;
                }
            }
        }
    }
}

.available-locations {
    font-size: 18px;
    color: $gray-500;
    line-height: 21.6px;

    .bold {
        font-weight: $font-weight-semibold;
    }

    @include media-breakpoint-down(md) {
        text-align: left !important;
        margin-left: 20px;
        font-size: 17px;
    }
}

.background {
    &__gray {
        background-color: $gray-90;
    }

    .menu_section:last-of-type {
        @include media-breakpoint-down(xl) {
            width: 520px;
        }
    }

    @include media-breakpoint-down(xl) {
        &__menu-mobile {
            display: none;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        padding: 60px 0;
    }

    a {
        text-decoration: none;
        color: $white;
    }

    a:hover {
        color: $white;
    }
}

[data-aos="custom-fade-up"] {
    opacity: 0.1;
    transition-property: transform, opacity;
    transform: translateY(100px);

    &.aos-animate {
        opacity: 1;
        transform: translateY(0);
    }
}
