.life-stories {
    background-color: $white;

    &.gray {
        background-color: $gray-90;

        h2 {
            align-self: flex-start;
            padding: 0;
        }
    }

    &.gray {
        background-color: #f2f2f2;

        h2 {
            align-self: flex-start;
            padding: 0;
        }
    }

    &__content {
        padding: 80px 0px;
        display: flex;
        gap: 45px;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(md) {
            padding: 40px 20px;
            gap: 20px;
        }

        &.container {
            @include media-breakpoint-up(xxl) {
                max-width: 1300px;
            }

            @include media-breakpoint-down(xxl) {
                max-width: 1280px;
            }
            @include media-breakpoint-down(xl) {
                max-width: 864px;
            }

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }

        h2 {
            color: $text-rich-black;

            text-align: center;
            padding: 0 360px;

            @include media-breakpoint-down(xxl) {
                padding: 0 400px;
            }

            @include media-breakpoint-down(xl) {
                padding: 0 190px;
            }

            @include media-breakpoint-down(md) {
                padding: 0;
                text-align: left;
            }
        }

        &__stories {
            display: flex;
            gap: 20px;
            align-items: flex-start;
            width: 100%;

            @include media-breakpoint-down(xl) {
                // align-items: center;
                flex-direction: column;
            }

            @include media-breakpoint-down(md) {
                align-items: flex-start;
                gap: 15px;
            }

            .html-code,
            .twig-code {
                display: none;
            }

            &__story {
                background-color: transparent;
                width: calc(25% - 20px);
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                position: relative;
                text-decoration: none;

                &:hover {
                   text-decoration: none;
                    cursor: pointer;
                }

                @include media-breakpoint-down(xl) {
                    align-items: center;
                    flex-direction: row;
                    max-height: fit-content;
                    width: auto;
                }

                @include media-breakpoint-down(md) {
                    align-items: flex-start;
                }

                figure {
                    margin: 0;
                    width: 100%;
                    height: 270px;

                    @include media-breakpoint-down(xl) {
                        width: 160px;
                        height: 160px;
                        margin-right: 15px;
                        min-width: 160px;
                    }

                    @include media-breakpoint-down(md) {
                        width: 72px;
                        height: 72px;
                        border-radius: 10px;
                        min-width: 72px;
                    }

                    .life-stories__content__stories__story__image {
                        border-radius: 20px;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;

                        // @include media-breakpoint-down(xl) {
                        //     width: 160px;
                        //     height: 160px;
                        //     margin-right: 15px;
                        // }

                        // @include media-breakpoint-down(md) {
                        //     width: 72px;
                        //     height: 72px;
                        //     border-radius: 10px;
                        // }
                    }
                }

                &__image {
                    border-radius: 20px;
                    object-fit: cover;
                    width: 100%;
                    height: 270px;
                    cursor: pointer;

                    @include media-breakpoint-down(xl) {
                        width: 160px;
                        height: 160px;
                        margin-right: 15px;
                        border-radius: 30px;
                    }

                    @include media-breakpoint-down(md) {
                        width: 72px;
                        height: 72px;
                        border-radius: 10px;
                    }
                }

                &:last-child .life-stories__content__stories__story__right-col {
                    border-bottom: none;
                }

                &__right-col {
                    @include media-breakpoint-down(md) {
                        border-bottom: 1.2px solid $gray-de;
                        padding-bottom: 15px;
                    }

                    &__ct {
                        background-color: $orange-90;
                        padding: 3px 6px;
                        border-radius: 6px;
                        color: $orange;
                        font-size: 12px;
                        font-weight: $font-weight-bold;
                        line-height: 18px;
                        margin-bottom: 15px;
                        margin-top: 15px;
                        max-width: fit-content;

                        @include media-breakpoint-down(xl) {
                            margin-bottom: 5px;
                        }

                        @include media-breakpoint-down(md) {
                            margin-top: 0px;
                        }
                    }

                    .ct-blogs {
                        background-color: $cobalt-blue-90;
                        color: $cobalt-blue;
                    }

                    .ct-events {
                        background-color: $bright-purple-80;
                        color: $purple;
                    }


                    &__title {
                        color: $dark-gray-15;
                        font-size: 22px;
                        height: calc(
                            22px * 1.3 * 3
                        ); // heigth as big as 3 lines of text
                        font-style: normal;
                        font-weight: $font-weight-semibold;
                        line-height: 130%;
                        margin-bottom: 20px;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;

                        @include media-breakpoint-down(xl) {
                            height: auto;
                            margin-bottom: 15px;
                            -webkit-line-clamp: 2;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                            line-height: 130%;
                        }
                    }

                    &__description {
                        color: $dark-gray;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: $font-weight-normal;
                        line-height: 160%;

                        @include media-breakpoint-down(xl) {
                            -webkit-line-clamp: 2;
                        }
                    }
                }
            }
        }
    }
}
