.footer {
    background: $dark-gray-15;
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;

    .container {
        @include media-breakpoint-up(xl) {
            max-width: 1280px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }
    }

    #logo {
        fill: $white;

        #stroke {
            stroke: $white;
        }
    }

    a {
        text-decoration: none;
        color: $white;
    }

    a:hover {
        color: $white;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        padding: 60px 0;

        @include media-breakpoint-down(md) {
            padding: 40px 0 !important;
            flex-direction: column;
        }

        .col-contact {
            svg {
                width: 146px;
                height: 44px;
                max-width: none;

                @include media-breakpoint-down(md) {
                    width: 161px;
                    height: 54px;
                }
            }

            @include media-breakpoint-down(xl) {
                padding-bottom: 24px;
                border-right: none;
                order: 2;
            }

            @include media-breakpoint-down(md) {
                padding: 0 20px;
                order: 0;
                margin-bottom: 35px;
            }
        }
    }

    &__menu {
        flex-direction: row;
        flex-wrap: wrap;
        display: none;
        gap: 45px;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        @include media-breakpoint-up(xxl) {
            gap: 90px;
        }

        p {
            a {
                color: $gray;
                font-size: 14px;
            }
        }

        .menu-section {
            &:last-of-type {
                @include media-breakpoint-down(xl) {
                    width: 520px;
                }
            }

            h5 {
                font-size: 16px;
                font-style: normal;
                font-weight: $font-weight-semibold;
                margin-bottom: 30px;
            }

            ul {
                list-style-type: none;
                @include pl(0);
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                    a {
                        color: $gray;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: $font-weight-normal;
                        line-height: normal;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }

        .email-input {
            border-radius: 20px;
            padding: 10px;
            padding-left: 20px;
            margin: 20px 0;
            position: relative;
            background-color: $white;
            display: flex;
            justify-content: space-between;
            width: 100%;
            min-width: 315px;
            box-sizing: border-box;
            border: 1.5px solid transparent;

            &:focus-within {
                border: 1.5px solid $orange;
            }

            @include media-breakpoint-down(xl) {
                width: 446px;
            }

            input {
                border: none;
                width: 80%;
                height: 44px;
                color: $text-rich-black;
                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 25px;
                &:focus-visible {
                    border: none;
                    outline: none;
                }
                color: $black;
            }
            button {
                padding: 10px;
                align-self: flex-end;
                order: 1;
            }
        }
    }

    &__menu-mobile {
        display: block;

        @include media-breakpoint-up(md) {
            display: none;
        }

        .menu-section {
            padding: 0 20px;

            h5 {
                font-size: 17px;
                font-style: normal;
                font-weight: $font-weight-semibold;
                margin-bottom: 30px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 5px;
                }
            }

            div {
                p {
                    a {
                        color: $gray;
                        font-size: 15px;
                        font-weight: $font-weight-normal;
                        line-height: 160%;
                    }
                }
            }

            ul {
                list-style-type: none;
                @include pl(0);
                display: flex;
                flex-direction: column;
                gap: 15px;

                li {
                    a {
                        color: $gray;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: $font-weight-normal;
                        line-height: normal;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }

            .mobile-copyright {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    margin-top: 35px;
                    color: $gray;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: $font-weight-normal;
                    line-height: 160%;
                }
            }
        }

        .email-input {
            border-radius: 20px;
            padding: 10px;
            padding-left: 20px;
            margin: 20px 0;
            position: relative;
            background-color: $white;
            display: flex;
            justify-content: space-between;
            width: 100%;
            min-width: 315px;
            box-sizing: border-box;
            border: 1.5px solid transparent;

            &:focus-within {
                border: 1.5px solid $orange;
            }

            input {
                border: none;
                width: 80%;
                height: 44px;
                color: $text-rich-black;
                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-normal;
                line-height: 25px;
                &:focus-visible {
                    border: none;
                    outline: none;
                }
                color: $black;
            }
            button {
                padding: 10px;
                align-self: flex-end;
                order: 1;
                width: 44px;
            }
        }

        .accordion-button {
            padding: 15px 0px !important;
        }

        .accordion-body {
            padding: 0 !important;
            padding-bottom: 20px !important;
            ul {
                margin: 0;

                li {
                    &:first-child {
                        margin: 0;
                    }

                    a {
                        color: $gray;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: $font-weight-normal;
                        line-height: 160%;
                    }
                }
            }
        }
    }

    .social-networks {
        padding: 0;
        display: flex !important;
        gap: 10px;

        .btn-social-networks {
            display: flex;
            align-items: center;

            img {
                transition: all 0.3s ease;
            }

            &:hover {
                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    .sub-footer {
        background-color: $gray-20;
        font-style: normal;
        font-weight: $font-weight-normal;
        font-size: 13px;
        line-height: 130%;

        @include media-breakpoint-down(md) {
            padding: 0 20px;
            padding-bottom: 64px;
        }

        color: $gray;

        a {
            color: $gray !important;

            &:hover {
                color: $white !important;
            }
        }

        &__content {
            display: flex;
            justify-content: space-between;
            padding: 20px 0px;

            @include media-breakpoint-down(xl) {
                flex-direction: column;
            }

            @include media-breakpoint-down(md) {
                padding-top: 25px !important;
            }

            &__right {
                display: flex;
                justify-content: end;

                @include media-breakpoint-down(xl) {
                    justify-content: start;
                    margin-top: 10px !important;
                    padding-top: 0 !important;
                    margin-left: 0;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                    display: none !important;
                }

                @include media-breakpoint-between(lg, xl) {
                    width: auto;
                }
            }

            &__left {
                @include media-breakpoint-between(lg, xl) {
                    width: auto;
                }
                @include media-breakpoint-down(xl) {
                    margin-left: 0;
                }
                @include media-breakpoint-down(md) {
                    order: 2;
                }
                ul {
                    @include media-breakpoint-down(md) {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                    }
                }
            }
        }
    }

    .border-b {
        border-bottom: 1px solid $gray-70;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        @include mt(1.5rem);
        @include pt(1.5rem);
    }
}
