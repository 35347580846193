.thank-you {
    .thanks-wrapper {
        text-align: center;

        &__events {
            h4 {
                padding: 0 30px 0;

                @include media-breakpoint-up(md) {
                    padding: 0 130px 0;
                }

                @include media-breakpoint-up(xl) {
                    padding: 0 190px 0;
                }

                @include media-breakpoint-up(xxl) {
                    padding: 0 370px 0;
                }
            }

            p {
                padding: 0 30px 0;

                @include media-breakpoint-up(md) {
                    padding: 0 165px 0;
                }

                @include media-breakpoint-up(xl) {
                    padding: 0 240px 0;
                }

                @include media-breakpoint-up(xxl) {
                    padding: 0 440px 0;
                }
            }
        }

        img {
            width: 65px;
            height: 65px;
            margin-bottom: 20px;

            @include media-breakpoint-up(md) {
                width: 70px;
                height: 70px;
            }
        }

        p {
            font-size: 15px;
            line-height: 24px;
        }

        h4 {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }

    .cta-section {
        &__cta-banner {
            .btn-normal {
                background-color: $purple;
                color: $white;

                &:hover {
                    background-color: $bright-purple-40;
                }
            }
        }
    }
}
