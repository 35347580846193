.life-at-luxoft-lp {
    .life-at-luxoft-first {
        padding-top: 148px;

        @include media-breakpoint-down(md) {
            padding-top: 104px;
        }
    }

    &.first-black {
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        padding-bottom: 80px;

        @include media-breakpoint-down(md) {
            @include p(0 20px 25px);
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
        }
    }

    &.second-black {
        padding-bottom: 60px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;

        @include media-breakpoint-down(md) {
            padding: 30px 20px 20px;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
        }
    }

    .life-at-luxoft-first__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;

        @include media-breakpoint-between(xl, xxl) {
            max-width: 1280px;
        }

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            gap: 15px;
        }

        h1 {
            color: $white;
        }

        p {
            padding: 0 250px;
            color: $gray;
            text-align: center;

            @include media-breakpoint-up(xxl) {
                font-size: 18px;
            }

            @include media-breakpoint-down(xl) {
                padding: 0;
            }

            @include media-breakpoint-down(md) {
                text-align: left;
            }
        }

        &__collapse-section {
            display: flex;
            flex-direction: column;
            gap: 45px;
            padding-top: 25px;
            width: 100%;

            @include media-breakpoint-down(md) {
                padding-top: 20px;
            }

            &__buttons {
                display: flex;
                padding: 10px;
                align-items: flex-start;
                gap: 10px;
                border-radius: 20px;
                border: 1.5px solid $gray-30;
                margin: auto;

                @include media-breakpoint-down(md) {
                    margin: 0;
                }

                button {
                    width: 140px;
                    height: 50px;
                    padding: 15px 50px;
                    border-radius: 15px;
                    background-color: transparent;
                    color: $white;
                    border: none;

                    @include media-breakpoint-down(md) {
                        flex: 1 0 0;
                        width: auto;
                        padding: 0;
                    }

                    &.active {
                        background-color: $white;
                        color: $dark-gray-15;
                    }

                    &:hover:not(.active) {
                        background-color: rgba(255, 255, 255, 0.05);
                    }
                }
            }

            &__panels {
                width: 100%;

                &__panel {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    figure {
                        margin: 0;
                        width: 50%;
                        height: 350px;
                        border-radius: 30px;

                        @include media-breakpoint-down(xl) {
                            height: 260px;
                        }

                        @include media-breakpoint-down(md) {
                            display: none;
                        }

                        .life-at-luxoft-first__container__collapse-section__panels__panel__img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 30px;
                        }
                    }

                    .life-at-luxoft-first__container__collapse-section__panels__panel__img {
                        object-fit: cover;
                        border-radius: 30px;
                        width: 50%;
                        height: 350px;

                        @include media-breakpoint-down(xl) {
                            height: 260px;
                        }

                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }

                    &__text {
                        width: 50%;
                        height: 350px;
                        border-radius: 30px;
                        background-color: $gray-20;
                        padding: 35px 100px 35px 35px;
                        position: relative;

                        @include media-breakpoint-down(xl) {
                            height: 260px;
                            padding: 35px;
                            overflow: hidden;
                        }

                        @include media-breakpoint-down(md) {
                            width: 100%;
                            padding: 25px 25px 100px 25px;
                            height: auto;
                        }

                        p {
                            padding: 0;
                            text-align: start;
                            color: $gray-ccc;

                            @include media-breakpoint-down(xl) {
                                font-size: 14px;
                                line-height: 140%; /* 19.6px */
                                letter-spacing: -0.28px;
                            }

                            @include media-breakpoint-down(md) {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: $font-weight-normal;
                                line-height: 160%; /* 25.6px */
                                letter-spacing: -0.32px;
                                color: $gray-e6e6;
                            }
                        }

                        svg {
                            position: absolute;
                            bottom: 0;
                            right: 0;

                            @include media-breakpoint-down(xl) {
                                bottom: -5px;
                                right: -100px;
                                transform: scale(1.5);
                            }
                        }
                    }
                }
            }
        }
        .collapsible-content {
            display: none;
        }

        .collapsible-content.active {
            display: flex;
            /* Additional styling for flex container */
        }
    }

    .life-at-luxoft-second__container {
        @include media-breakpoint-between(xl, xxl) {
            max-width: 1280px;
        }

        &__tech-insights {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
            padding-bottom: 120px;

            @include media-breakpoint-down(xl) {
                gap: 20px;
            }

            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                gap: 25px;
                padding-bottom: 50px;
            }

            &__desc {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                &__text {
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                    padding-right: 80px;

                    @include media-breakpoint-down(xl) {
                        padding-right: 40px;
                    }

                    @include media-breakpoint-down(md) {
                        padding-right: 0;
                        gap: 15px;
                    }

                    h2 {
                        color: $white;
                        letter-spacing: -1.1px;

                        @include media-breakpoint-up(xxl) {
                            font-size: 55px;
                        }
                    }

                    p {
                        color: $gray;
                        letter-spacing: -0.36px;

                        @include media-breakpoint-up(xxl) {
                            font-size: 18px;
                        }
                    }
                }
            }

            &__figure {
                width: 100%;
                height: 350px;
                border-radius: 30px;
                margin: 0;

                @include media-breakpoint-down(md) {
                    height: 210px;
                }

                &--img {
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    object-fit: cover;

                    @include media-breakpoint-down(md) {
                        object-position: 0 15%;
                    }
                }
            }

            &__container {
                display: flex;
                gap: 45px;
                align-items: center;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;

                @include media-breakpoint-down(xl) {
                    gap: 35px;
                }

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    gap: 5px;
                }

                &__download {
                    display: flex;
                    height: 55px;
                    padding: 15px 20px 15px 25px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 15px;
                    border: 1.5px solid $white;
                    color: $white;
                    text-decoration: none;

                    align-self: end;
                    justify-self: flex-start;

                    @include media-breakpoint-down(bsm) {
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        align-self: auto;
                    }

                    @include media-breakpoint-up(xl) {
                        &:hover {
                            background-color: $white;
                            color: $dark-gray-15;

                            .download-black-icon {
                                display: block;
                            }

                            .download-white-icon {
                                display: none;
                            }
                        }
                    }

                    .download-white-icon {
                        height: 24px;
                        width: 24px;
                    }

                    .download-black-icon {
                        display: none;
                        height: 24px;
                        width: 24px;
                    }
                }

                &__learn-more {
                    text-decoration: none;
                    color: $gray;
                    align-self: end;
                    padding: 18px 0px;
                    @include media-breakpoint-down(md) {
                        align-self: center;
                    }
                    &:hover {
                        color: $white;
                    }
                }
            }
        }

        &__global-comm {
            display: flex;
            padding: 40px;
            align-items: center;
            gap: 10px;
            border-radius: 30px;
            background: $gray-20;
            justify-content: space-between;

            @include media-breakpoint-down(xl) {
                padding: 30px;
            }

            @include media-breakpoint-down(md) {
                padding: 25px;
                flex-direction: column;
                gap: 20px;
            }

            h3 {
                color: $white;

                @include media-breakpoint-down(xl) {
                    font-size: 22px;
                    font-style: normal;
                    font-weight: $font-weight-semibold;
                    line-height: 130%; /* 28.6px */
                    letter-spacing: -0.44px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: $font-weight-semibold;
                    line-height: 140%; /* 28px */
                    letter-spacing: -0.4px;
                }
            }

            a.btn-normal {
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }
}

.white-background {
    &.life-at-luxoft-lp {
        .container {
            @include media-breakpoint-between(xl, xxl) {
                max-width: 1280px;
            }
        }

        .life-stories {
            background-color: $white;

            &__content {
                @include p(0 0 80px 0);

                @include media-breakpoint-between(xl, xxl) {
                    max-width: 1280px;
                }

                @include media-breakpoint-down(md) {
                    gap: 25px;
                    @include p(40px 20px);
                }

                h2 {
                    text-align: center;
                    padding: 0 360px;

                    @include media-breakpoint-down(xxl) {
                        padding: 0 400px;
                    }

                    @include media-breakpoint-down(xl) {
                        padding: 0 190px;
                    }

                    @include media-breakpoint-down(md) {
                        padding: 0;
                        text-align: left;
                    }
                }

                .life-stories__content__stories__story__right-col__title {
                    @include media-breakpoint-up(xl) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .life-changing {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;
            padding-top: 20px;

            @include media-breakpoint-down(md) {
                padding: 40px 20px 40px 20px;
            }

            h2 {
                color: $dark-gray-15;
                text-align: center;
                padding: 0 200px;
                letter-spacing: -1.2px;

                @include media-breakpoint-down(xxl) {
                    padding: 0 220px;
                }

                @include media-breakpoint-down(xl) {
                    padding: 0 40px;
                }

                @include media-breakpoint-down(md) {
                    padding: 0;
                    text-align: left;
                }
            }

            p {
                text-align: center;
                padding: 0 200px;
                letter-spacing: -0.4px;
                color: $dark-gray;

                @include media-breakpoint-down(xxl) {
                    padding: 0 220px;
                }

                @include media-breakpoint-down(xl) {
                    padding: 0 40px;
                }

                @include media-breakpoint-down(md) {
                    padding: 0;
                    text-align: left;
                }
            }

            .standard-video-section-rounded {
                @include mt(5px);

                @include media-breakpoint-up(md) {
                    @include mt(25px);
                }
            }
        }
    }
}
