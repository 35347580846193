:root {
    --bs-font-sans-serif: "Open Sans", sans-serif;
    letter-spacing: 0;

    font-size: $font-size-root-sm;

    @include media-breakpoint-up(md) {
        font-size: $font-size-root-md;
    }

    @include media-breakpoint-up(xxl) {
        font-size: $font-size-root-xl;
    }
}

body {
    font-size: $font-size-base;
    background-color: $dark-gray-15;

    &.body-event {
        background-color: $white;

        .footer {
            border-top-right-radius: 40px;
            border-top-left-radius: 40px;
        }

        &:has(.single-event__photo-gallery) {
            background-color: $gray-90;
        }
    }

    &:has(.about-us-lp),
    &:has(.logeek-magazine-lp) {
        background-color: $white;
    }

    &:has(.single-location) #header {
        border-bottom: 1px solid $gray-e6e6;

        .up-menu-logo {
            background: url("/theme/luxoft/assets/images/logo/logo-luxoft-career-purple.svg") no-repeat !important;
        }

        .menu-item {
            color: $dark-gray;

            &:hover {
                color: $text-rich-black;
            }
        }

        .life-at-luxoft-option {
            &:hover {
                .arrow {
                    border: solid $gray;
                    border-width: 0 2px 2px 0;
                }
            }

            .menu-item {
                &__submenu {
                    box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
                    -webkit-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
                    -moz-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
                }
            }
        }
    }
}

h1 {
    font-weight: $font-weight-normal;
    line-height: 120%;
    font-size: 40px;

    @include media-breakpoint-up(md) {
        font-size: 60px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 65px;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 70px;
    }
}

h2 {
    font-size: 60px;
    font-weight: $font-weight-semibold;
    line-height: 120%;

    @include media-breakpoint-down(xxl) {
        font-size: 55px;
    }

    @include media-breakpoint-down(xl) {
        font-size: 50px;
    }

    @include media-breakpoint-down(md) {
        font-size: 35px;
    }
}

h3 {
    font-size: 30px;
    font-weight: $font-weight-bold;
    line-height: 120%;

    @include media-breakpoint-down(xxl) {
        font-size: 30px;
    }

    @include media-breakpoint-down(xl) {
        font-size: 30px;
    }

    @include media-breakpoint-down(md) {
        font-size: 20px;
    }
}

h4 {
    font-size: 25px;
    font-weight: $font-weight-bold;
    line-height: 120%;

    @include media-breakpoint-down(xxl) {
        font-size: 25px;
    }

    @include media-breakpoint-down(xl) {
        font-size: 25px;
    }

    @include media-breakpoint-down(md) {
        font-size: 20px;
    }
}

h5 {
    font-size: 25px;
    font-weight: $font-weight-bold;
    line-height: 120%;
    letter-spacing: 0.5px;

    @include media-breakpoint-down(md) {
        font-size: 18px;
        font-weight: $font-weight-semibold;
        line-height: 130%;
        letter-spacing: 0.36px;
    }
}

.subtitle-l {
    font-size: 22px;
    line-height: 130%;
    font-weight: $font-weight-semibold;

    @include media-breakpoint-down(md) {
        font-size: 18px;
    }
}

.subtitle-m {
    font-size: 16px;
    line-height: 135%;
    font-weight: $font-weight-semibold;

    @include media-breakpoint-down(xxl) {
        font-size: 16px;
    }

    @include media-breakpoint-down(xl) {
        font-size: 16px;
    }

    @include media-breakpoint-down(md) {
        font-size: 14px;
    }
}

.body-xl-regular {
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.4px;
    font-weight: $font-weight-normal;

    @include media-breakpoint-down(xxl) {
        font-size: 18px;
    }

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 160%;
    }
}

.body-xl-semibold {
    font-size: 20px;
    line-height: 130%;
    font-weight: $font-weight-semibold;
    letter-spacing: -0.4px;

    @include media-breakpoint-down(xxl) {
        font-size: 18px;
    }

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 160%;
    }
}

.body-xxl-semibold {
    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 130%;
        font-weight: $font-weight-semibold;
    }
}

.body-l-regular {
    font-size: 15px;
    font-weight: $font-weight-normal;
    line-height: 160%;
}

.body-l-semibold {
    font-size: 15px;
    font-weight: $font-weight-semibold;
    line-height: 160%;
}

.body-m-regular {
    font-size: 14px;
    line-height: 140%;
    font-weight: $font-weight-normal;
    letter-spacing: -0.28px;
}

.body-s-regular {
    font-size: 13px;
    line-height: 140%;
    font-weight: $font-weight-normal;
}

.body-m-semibold {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.28px;
    font-weight: $font-weight-semibold;
}

.caption-xs-bold {
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 140%;
    letter-spacing: 0.24px;
}

.caption-m-semibold {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    line-height: 140%;

    @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 130%;
    }
}

.regular {
    font-weight: $font-weight-normal;
}

.semibold {
    font-weight: $font-weight-semibold;
}

.bold {
    font-weight: $font-weight-bold;
}

.text-14 {
    font-size: $font-size-base * 0.85;
    line-height: $line-height-130;
}

.text-15 {
    font-size: 15px;
}

.text-16 {
    font-size: $font-size-base;
    line-height: $line-height-130;
}

.text-18 {
    font-size: 18px;
}

.text-20 {
    font-size: $font-size-base * 1.25;
    line-height: $line-height-130;
}

.text-22 {
    font-size: 22px;
}

.text-28 {
    font-size: $font-size-base * 1.75;
    line-height: $line-height-130;
}

.overline {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.text-gray-63666a {
    color: $dark-gray;
}

.sub01 {
    font-size: $font-size-base * 3;
    font-weight: $font-weight-normal;
    line-height: $line-height-base;
}

.sub02 {
    font-size: $font-size-base * 1.75;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
}

.fw-semibold {
    font-weight: $font-weight-semibold;
}

.lh-120 {
    line-height: $line-height-base;
}

.lh-130 {
    line-height: $line-height-130;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-justify {
    text-align: justify;
}

.align-left {
    text-align: left;
}

img,
svg {
    max-width: 100%;
}

.edit-page-btn {
    z-index: 10;
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 40px;
    right: 40px;
    background-color: $purple;
    @include border-radius(15px);
    text-align: center;
    box-shadow: 2px 2px 3px $purple;

    &__image {
        width: 30px;
        filter: invert(1);
        @include mt(9px);
        @include ml(3px);
    }
}

.standard-black-tags {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 15px;

    &--event-tags {
        @include mb(25px);

        @include media-breakpoint-up(md) {
            @include mb(35px);
            justify-content: center;
        }
    }

    &__single-tag {
        display: flex;
        padding: 10px 15px 10px 10px;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.05);

        &__icon:not(.relocation-friendly) {
            opacity: 0.3;
        }
    }
}

.standard-video-section-rounded {
    .embed-responsive {
        width: 100%;
        border-radius: 30px;
        height: 210px;

        @include media-breakpoint-up(md) {
            height: 410px;
        }

        @include media-breakpoint-up(xl) {
            height: 600px;
        }

        @include media-breakpoint-up(xxl) {
            height: 610px;
        }

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 30px;
        }
    }
}

ol {
    li {
        font-size: 15px;
    }
}

.default-ul {
    list-style: none;
    font-size: 15px;
    line-height: 24px;

    li {
        color: $gray-500;
        position: relative;
        @include mb(4px);

        &::before {
            content: "\25CF";
            color: $gray-500;
            display: inline-block;
            width: 1.2em;
            margin-left: -1.2em;
            font-size: 12px;
            position: absolute;
            top: 2px;
        }
    }

    .default-ul {
        padding-left: 0;

        li {
            margin-left:2em;
            padding-bottom: 0;

            &::before {
                content: "\035E";
                color: $gray-500;
                top: 8px;
                margin-left: -1.6em;
            }
        }
    }
}

#whiteSection {
    .default-ul {
        list-style: none;
        font-size: 15px;
        line-height: 24px;

        li {
            position: relative;
            @include mb(4px);
            color: $dark-gray;

            a {
                @include link-styles;
            }

            &::before {
                content: "\25CF";
                color: $dark-gray;
                display: inline-block;
                width: 1.2em;
                margin-left: -1.2em;
                font-size: 12px;
                position: absolute;
                top: 2px;
            }
        }

        .default-ul {
            padding-left: 0;

            li {
                margin-left:2em;
                padding-bottom: 0;

                &::before {
                    content: "\035E";
                    color: $dark-gray;
                    top: 8px;
                    margin-left: -1.6em;
                }
            }
        }
    }

    ol {
        li {
            a {
                @include link-styles;
            }
        }
    }

    .latest-opportunities,
    .photo-container-text-columns {
        .default-ul {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .photo-container-text-columns {
        &__container {
            &__info-banner {
                &__info {
                    .default-ul {
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

.loading-animation {
    margin: 0 auto;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;

    &::before , &::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 4px solid $purple;
        animation: prixClipFix 2s linear infinite;
    }

    &::after{
        border-color: $bright-purple-40;
        animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
        inset: 6px;
    }

    @keyframes rotate {
        0%   {transform: rotate(0deg)}
        100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
}

.career-pagination {
    gap: 10px;
    align-items: center;

    li {
        &:has(.back-button),
        &:has(.next-button) {
            display: none;
        }

        button {
            width: 44px;
            height: 44px;
            border: none;
            background-color: transparent;
            font-size: 14px;
            color: $dark-gray;

            &.active-page {
                background-color: $purple;
                color: $white;
                border-radius: 15px;
            }
        }
    }
}

.job-back-btn,
.job__grid__job-related__refer__recommend-btn,
.btn-normal,
.btn-transparent,
.btn-arrow-ghost--rich-black,
.btn-arrow-ghost--black-orange,
.btn-arrow--bright-purple,
.btn-arrow-ghost--white,
.btn-arrow-ghost--purple,
.luxoft-magazine__container__learn-more,
.luxoft-magazine__container__download,
.luxoft-magazine__white__container__download,
.btn,
#submit-button
{
    text-transform: uppercase;
}

.grey-white-black-template {
    background-color: $white;

    #greySection {
        padding: 80px 0 40px;
        background: $gray-100;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;

        .single-event__faq {
            .accordion {
                .accordion-item {
                    .accordion-header {
                        button {
                            color: $text-rich-black;
                        }
                    }
                }
            }
        }
    }

    #blackSection2 {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        color: $white;
    }
}

.page-form-container {
    .page-form-end-date {
        display: none;
    }
}

.section-icons-with-text {
    &--item {
        display: flex;
        align-items: center;
        @include mb(22px);

        .item-image {
            width: 48px;
            height: 48px;
            @include mr(20px);

            figure {
                @include m(0);
            }
        }
    }
}
