.primary-project-roles {
    @include p(40px 20px);

    @include media-breakpoint-up(md) {
        @include p(60px 0);
    }

    &__btn {
        a {
            @media screen and (max-width: 600px) {
                width: 100%;
                display: inline-block;
            }
        }
    }

    &__mobile {
        @media screen and (min-width: 650px) {
            display: none;
        }

        .splide {
            &__slide {
                width: 85% !important;
            }

            &__arrows {
                display: none;
            }
        }
    }

    &__roles {
        display: none;

        @media screen and (min-width: 650px) {
            display: flex;
            justify-content: center;
        }

        .col {
            @include p(0 0.4rem);
        }

        &--item {
            border-radius: 30px;
            overflow: hidden;
            position: relative;
            display: flex;
            height: 259px;
            @include mb(2);

            @include media-breakpoint-up(xl) {
                @include mb(0);
                height: 180px;
            }

            @include media-breakpoint-up(xxl) {
                height: 235px;
            }

            figure {
                @include m(0);
            }

            img {
                position: absolute;
                height: 100%;
                object-fit: cover;
                width: 100%;
                z-index: 0;
            }

            .item-title {
                border-radius: 15px;
                background-color: $white;
                @include p(10px 15px);
                z-index: 1;
                width: 90%;
                display: flex;
                align-items: center;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: 20px;

                @include media-breakpoint-up(bsm) {
                    min-height: 73px;
                    width: 88%;
                }

                @include media-breakpoint-up(md) {
                    width: 90%;
                }

                @include media-breakpoint-up(xxl) {
                    width: 85%;
                }

                p {
                    color: $dark-gray-15;
                    font-weight: $font-weight-semibold;
                    line-height: 26.4px;
                    font-size: 20px;

                    @include media-breakpoint-up(xxl) {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}
