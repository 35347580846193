.blog-post {
    .fixed ~ & {
        top: 269px;
    }
    &.white-background {
        will-change: initial;
        gap: 0;
        @include p(20px 0 0);

        @include media-breakpoint-up(md) {
            @include p(60px 0 0);
        }
    }

    .row {
        @include mr(0);
        @include ml(0);

        @include media-breakpoint-up(md) {
            margin-right: calc(var(--#{$variable-prefix}gutter-x) * -.5);
            margin-left: calc(var(--#{$variable-prefix}gutter-x) * -.5);
        }
    }

    .row > * {
        @include pr(20px);
        @include pl(20px);

        @include media-breakpoint-up(md) {
            @include pr(0);
            @include pl(0);
        }
    }

    .pl-70 {
        @include media-breakpoint-up(md) {
            @include pl(70px !important);
        }
    }

    &__banner {
        position: relative;
        background-color: $bright-purple-40;
        @include pt(42px);
        @include pb(35px);
        @include mt(88px);
        transition: all 0.4s ease;

        @include media-breakpoint-up(lg) {
            @include pb(55px);
        }

        .blog-post-corner-r,
        .blog-post-corner-l {
            position: absolute;
            height: 50px;
            width: 60px;
            background-color: $bright-purple-40;
            bottom: -45px;
        }

        .blog-post-corner-r {
            right: 0;
        }

        .blog-post-corner-l {
            left: 0;
        }

        .back-btn {
            @include p(10px 20px 10px 5px);
            height: 44px;
            width: 82px;

            svg {
                height: 24px;
                width: 24px;
                opacity: 0.5;
                stroke: $white;
            }
        }

        h1 {
            @include mt(30px);
            @include mb(22px);
            color: $white;
            font-size: 32px;
            line-height: 1.3;
            transition: all 0.5s ease;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            text-wrap: balance;

            @include media-breakpoint-up(md) {
                @include mb(30px);
                font-size: 36px;
            }
        }

        p {
            font-size: 16px;
            color: $white;
            transition: all 0.5s ease;
        }

        .col-lg-3 {
            position: relative;
        }

        &--social {
            @include mt(26px);

            @include media-breakpoint-up(md) {
                position: absolute;
                bottom: 22px;
                @include mt(0);
            }

            ul {
                @include mb(0);
                @include pl(0);

                li {
                    list-style: none;
                    display: inline-block;
                    @include mr(6px);
                    border: 1px solid $white;
                    border-radius: 15px;

                    @include media-breakpoint-up(md) {
                        border: none;
                    }

                    a {
                        text-decoration: none;

                        img {
                            width: 40px;
                        }
                    }
                }
            }
        }

        &.fixed {
            @include mt(0);
            @include media-breakpoint-up(md) {
                top: 0;
                position: fixed;
                z-index: 3;
                width: 100%;
                max-height: 149px;
                @include pt(79px);
                @include pb(25px);
            }

            @include media-breakpoint-up(lg) {
                @include pt(96px);
                @include pb(70px);
                max-height: 166px;
            }

            .blog-post-corner-r,
            .blog-post-corner-l {
                display: none;
            }

            h1 {
                @include media-breakpoint-up(md) {
                    -webkit-line-clamp: 1;
                    font-size: 14px!important;
                    @include mt(30px);
                    font-weight: $font-weight-bold;
                }

                @include media-breakpoint-up(lg) {
                    @include mt(25px);
                }
            }

            p,
            .back-btn {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

            .blog-post__banner {
                &--social {
                    @include media-breakpoint-up(md) {
                        display: block;
                        bottom: 18px;
                    }
                }
            }
        }
    }

    &__right-column {
        .blog-right-column-separator {
            display: none;
            width: 100%;

            &.small {
                height: 440px;
            }

            &.medium {
                height: 480px;
            }

            &.large {
                height: 530px;
            }
        }

        &.scrolled {
            .blog-right-column-separator {
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }

        &.no-authors {
            .blog-post__right-column--authors {
                border-bottom: none;
            }

            .related-content {
                @include pt(0);
            }

            &.scrolled {
                .related-content {
                    @include pt(1.7rem !important);
                }
            }

            .blog-right-column-separator {
                display: none;
            }
        }

        &--authors {
            border-bottom: 1px solid $gray-c3;

            .item {
                @include mb(4);

                .image {
                    width: 60px;
                    height: 60px;
                    position: relative;
                    overflow: hidden;
                    @include mb(2);

                    img {
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        width: 100%;
                    }
                }

                .text {
                    .author-name {
                        @include mb(1);
                        color: $gray-20;
                        font-size: 16px;
                        font-weight: $font-weight-bold;
                    }

                    .author-title {
                        color: $gray-20;
                        font-size: 14px;
                        font-weight: $font-weight-normal;
                    }
                }
            }
        }

        .related-content {
            @include pt(1.7rem);

            &.fixed {
                position: fixed;
                top: 150px;

                @include media-breakpoint-up(md) {
                    width: 226px;
                }

                @include media-breakpoint-up(xl) {
                    width: 263px;
                }
            }

            &__title {
                @include mb(1rem);
            }

            &__items {
                .item {
                    position: relative;
                    @include mb(1.5rem);

                    &.html-code {
                        display: none;
                    }

                    &--image {
                        width: 100%;
                        height: 110px;
                        @include border-radius(14px);
                        position: relative;
                        overflow: hidden;

                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }

                    &--title {
                        @include mt(10px);

                        a {
                            font-weight: $font-weight-bold;
                            font-size: 16px;
                            color: $gray-20;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    &__content {
        @include pb(3.5rem);
        @include pt(20px);

        @include media-breakpoint-up(lg) {
            @include pt(0);
        }

        .text-content {
            p {
                font-size: 18px;
                line-height: 25.2px;
                color: $dark-gray;

                @include media-breakpoint-up(md) {
                    color: $gray-20;
                }
            }

            .blog-content-separator {
                display: none;
                width: 100%;

                &.small {
                    height: 440px;
                }

                &.medium {
                    height: 480px;
                }

                &.large {
                    height: 530px;
                }
            }

            &.scrolled {
                .blog-content-separator {
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                }
            }

            .cta-section {
                @include mb(2);
                @include mt(2);

                .cta-section__cta-banner {
                    h3 {
                        font-size: 22px;
                    }
                }

                .btn-arrow-ghost--white {
                    &.outline {
                        &:hover {
                            background-color: $bright-purple-40;
                        }
                    }
                }
            }
        }

        .blog-authors {
            background-color: $white;
            color: $gray-20;
            height: auto;
            max-width: 100vw;
            @include pt(2rem);
            position: relative;
            width: 100%;

            .author-wrapper {
                border-top: 1px solid $gray-c3;

                &__content {
                    display: flex;
                    flex-direction: column;
                    @include mt(2rem);

                    @include media-breakpoint-up(md) {
                        display: -webkit-box;
                    }

                    .author-image {
                        width: 60px;
                        height: 60px;
                        position: relative;
                        overflow: hidden;
                        @include mb(20px);

                        @include media-breakpoint-up(md) {
                            width: 120px;
                            height: 120px;
                            @include mb(0);
                        }

                        .picture {
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            width: 100%;
                        }
                    }

                    .author-content {
                        text-wrap: balance;

                        @include media-breakpoint-up(md) {
                            @include ml(40px);
                            width: 73%;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 76%;
                        }

                        @include media-breakpoint-up(xl) {
                            width: 79%;
                        }

                        @include media-breakpoint-up(xxl) {
                            width: 82%;
                        }

                        .author-name {
                            color: $gray-20;
                            font-size: 20px;
                            font-weight: $font-weight-bold;
                            @include mb(8px);
                        }

                        .author-title {
                            color: $gray-20;
                            @include mb(16px);

                            @include media-breakpoint-up(md) {
                                @include mb(24px);
                            }
                        }

                        .author-title,
                        .author-bio {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    &__related-content {
        background-color: $gray-90;
    }
}

.related-content-snippet {
    @include pt(6);
    @include pb(8);

    .row > * {
        margin-right: calc(var(--#{$variable-prefix}gutter-x) * .5);
        margin-left: calc(var(--#{$variable-prefix}gutter-x) * .5);
    }

    .row {
        .col {
            flex: auto;

            @include media-breakpoint-up(md) {
                flex: 1 0 0%;
            }
        }

        &.title {
            @include mb(1.5rem);
        }
    }

    h2 {
        font-size: 35px;
        color: $gray-20;
        line-height: 36.4px;
        font-weight: $font-weight-semibold;

        @include media-breakpoint-up(md) {
            font-weight: $font-weight-bold;
            font-size: 28px;
        }
    }

    &__item {
        position: relative;
        @include mb(24px);
        display: -webkit-box;

        @include media-breakpoint-up(md) {
            @include mb(0);
            display: initial;
        }

        &:hover {
            .related-content-snippet__item--image {
                img {
                    transform: scale(1.25);
                }
            }
        }

        &--image {
            overflow: hidden;

            @include media-breakpoint-down(md) {
                @include border-radius(14px);
            }

            @include media-breakpoint-up(md) {
                height: 177px;
                position: relative;
                border-top-left-radius: 14px;
                border-top-right-radius: 14px;
            }

            figure {
                @include m(0);
            }

            img {
                width: 90px;
                height: 90px;
                @include border-radius(14px);
                transition: .3s ease;

                @include media-breakpoint-up(md) {
                    @include border-radius(initial);
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        &--content {
            background-color: transparent;
            width: 80%;
            @include px(20px);

            @include media-breakpoint-up(md) {
                width: 90%;
            }

            @include media-breakpoint-up(md) {
                @include pt(24px);
                @include pb(32px);
                @include px(24px);
                width: 100%;
                background-color: $white;
                border-bottom-left-radius: 14px;
                border-bottom-right-radius: 14px;
            }

            a {
                color: $black;
                text-decoration: none;
                line-height: 23.4px;
                font-size: 18px;

                @include media-breakpoint-up(md) {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    height: 72px;
                }
            }

            p {
                line-height: 20.8px;
                font-size: 16px;
                @include mt(12px);
                @include mb(0);

                @include media-breakpoint-up(md) {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    height: 42px;
                }

                @include media-breakpoint-up(lg) {
                    -webkit-line-clamp: 1;
                    height: 22px;
                }
            }
        }
    }
}

.blogs-listing {
    display: flex;
    flex-direction: column;
    gap: 25px;
    @include p(0 20px 20px);

    @include media-breakpoint-up(md) {
        gap: 40px;
        @include p(0);
    }


    &__post {
        &:not(:last-child) {
            .single-post {
                &__text-section {
                    border-bottom: 1px solid $gray-e6e6;

                    @include media-breakpoint-up(md) {
                        border-bottom: none;
                    }
                }
            }
        }

        .single-post {
            text-decoration: none;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            @include mt(20px);

            @include media-breakpoint-up(md) {
                @include mt(0);
                @include mb(30px);
            }

            @include media-breakpoint-up(xl) {
                flex-direction: column;
                @include mb(40px);
            }

            &__image-section {
                overflow: hidden;
                position: relative;
                display: flex;
                height: 72px;
                width: 72px;
                flex-shrink: 0;

                @include media-breakpoint-up(md) {
                    height: 160px;
                    width: 160px;
                }

                @include media-breakpoint-up(xl) {
                    height: 270px;
                    width: 100%;
                }

                @include media-breakpoint-up(xxl) {
                    height: 270px;
                }

                img {
                    border-radius: 14px;
                    left: 50%;
                    object-fit: contain;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;
                    z-index: 0;

                    @include media-breakpoint-up(md) {
                        border-radius: 30px;
                    }
                }
            }

            &__text-section {
                @include ml(20px);
                @include pb(16px);

                @include media-breakpoint-up(md) {
                    @include pb(0);
                    border-bottom: none;
                }

                @include media-breakpoint-up(xl) {
                    @include ml(0);
                }

                &--title,
                &--text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                &--title {
                    font-weight: $font-weight-semibold;
                    color: $dark-gray-15;
                    font-size: 18px;
                    @include mb(8px);

                    @include media-breakpoint-up(md) {
                        font-size: 22px;
                        @include mb(10px);
                        min-height: 55px;
                    }

                    @include media-breakpoint-up(xl) {
                        -webkit-line-clamp: 3;
                        @include mb(14px);
                        min-height: 85px;
                    }
                }

                &--text {
                    font-size:15px;
                    color: $dark-gray;
                    line-height: 24px;

                    @include media-breakpoint-up(xl) {
                        -webkit-line-clamp: 3;
                    }
                }

                &--tag {
                    background-color: $light-purple;
                    color: $bright-purple-30;
                    border-radius: 4px;
                    font-size: 12px;
                    @include mb(12px);

                    @include media-breakpoint-up(md) {
                        @include mt(12px);
                    }

                    @include media-breakpoint-up(xl) {
                        @include mt(16px);
                    }
                }


                &--date {
                    font-size:13px;
                    color: $dark-gray-15;
                    display: none;

                    @include media-breakpoint-up(xxl) {
                        display: block;
                        @include mb(12px);
                    }
                }
            }
        }
    }
}

.blogs-filters {
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
        padding: 0 20px;
        margin-bottom: 40px;
        display: none;
    }

    &__search-container {
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        background-color: $white;
        margin-bottom: 30px;
        width: 50%;
        height: 64px;

        @include media-breakpoint-up(md) {
            border-radius: 20px;
        }

        @include media-breakpoint-up(xl) {
            width: 45%;
        }

        @include media-breakpoint-up(xxl) {
            width: 35%;
        }

        &__icon {
            display: none;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        > input {
            display: flex;
            border: none;
            padding: 15px;
            color: $black;
            width: 100%;
            height: 55px;
            cursor: pointer;
            cursor: auto;

            @include media-breakpoint-down(xl) {
                min-width: 250px;
            }

            @include media-breakpoint-down(md) {
                height: auto;
                padding: 0;
            }

            &:focus-visible {
                border: none;
                outline: none;
            }
        }

        .separator {
            width: 1.5px;
            height: 25px;
            background-color: $gray-e6e6;
            align-self: center;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        > .dropdown {
            width: 100%;
            height: 55px;

            &.items-checked {
                .dropdown-face {
                    color: $dark-gray-15;
                }
            }

            .dropdown-face {
                width: 100%;
                display: flex;
                height: 55px;
                padding: 15px;
                align-items: center;
                justify-content: space-between;
                gap: 5px;
                flex: 1 0 0;
                border-radius: 15px;
                background-color: transparent;
                border: 1px solid $gray-e6e6;

                @include media-breakpoint-down(xl) {
                    padding: 14px 15px;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }

                img {
                    transition: all 0.2s ease;
                }

                &.show {
                    color: $dark-gray-15;
                }

                &.show {
                    img {
                        transform: rotate(180deg);
                    }
                }

                .extra-counrties-checked {
                    background-color: $purple-f8f3;
                    border-radius: 5px;
                    @include p(3px 5px);

                    span {
                        color: $purple;
                    }
                }
            }

            .dropdown-menu::-webkit-scrollbar {
                width: 2px;
            }

            .dropdown-menu::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px $gray-999;
            }

            .dropdown-menu::-webkit-scrollbar-thumb {
                background-color: $gray-70;
                outline: 1px solid $gray-70;
            }

            .dropdown-menu {
                width: 100%;
                border: none;
                box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
                -webkit-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
                -moz-box-shadow: 1px 10px 40px -14px rgba(46,46,46,0.44);
                @include p(0 20px);
                overflow-x: hidden;
                max-height: 300px;

                @media (max-height: 710px) and (min-width: 1024px) {
                    max-height: 220px;
                }

                @media (max-height: 710px) and (min-width: 1440px) {
                    max-height: 255px;
                }

                li {
                    cursor: pointer;
                    @include m(22px 0);
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    font-size: 14px;

                    label {
                        width: 100%;
                        cursor: pointer;
                    }

                    input[type="checkbox"] {
                        cursor: pointer;
                        -webkit-appearance: none;
                        appearance: none;
                        background-color: transparent;
                        margin: 0;
                        width: 24px;
                        height: 24px;
                        border: 1px solid $gray-ccc;
                        border-radius: 5px;
                        display: grid;
                        place-content: center;

                        &::before {
                            content: url("/theme/luxoft/assets/images/icons/checkbox-checked-purple.svg");
                            width: 24px;
                            height: 24px;
                            transform: scale(0);
                            transform-origin: center;
                            transition: 120ms transform ease-in-out;
                        }

                        &:checked {
                            &::before {
                                border: none;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }

        > button {
            padding-top: 13px;
            height: 55px;
            min-width: 96px;
            border-radius: 15px;
            text-transform: capitalize;
            > img {
                width: 26px;
                height: 26px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &__chips {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
            gap: 10px;
        }

        &--chip {
            text-align: center;
            background-color: $light-purple;
            border-radius: 10px;
            @include pl(14px);
            height: 44px;
            display: flex;
            align-items: center;
            gap: 2px;
            transition: all 0.3s ease;
            flex-shrink: 0;

            &:hover {
                background-color: #decbf5;

                button {
                    img {
                        filter: brightness(0) invert(0);
                    }
                }
            }

            p {
                font-size: 14px;
                color: $purple;
            }

            button {
                border: none;
                background-color: transparent;
            }

            &.counter-chip {
                background-color: transparent;
                border: 1px solid $gray-e6e6;
                @include p(0 14px);
            }
        }
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .form-switch {
            display: flex;
            align-items: center;
            gap: 15px;

            .form-check-input {
                width: 38px;
                height: 24px;
                cursor: pointer;
            }
        }

        &__save {
            display: flex;
            align-items: center;
            gap: 10px;
            border: none;
            background-color: transparent;

            @include media-breakpoint-down(md) {
                display: none;
            }

            &.light-purple {
                p {
                    color: $bright-purple-70;
                }

                img {
                    filter: invert(75%) sepia(47%) saturate(1878%) hue-rotate(205deg) brightness(106%) contrast(105%);
                }
            }
        }
    }
}

.blog-listing-container {
    gap: 0;
    @include p(30px 0px);

    @include media-breakpoint-up(xl) {
        @include p(60px 0px);
    }
}
