.referral-modules {
    @include p(0 20px);

    @include media-breakpoint-up(md) {
        @include p(0);
    }

    &__items {
        &--item {
            background-color: $gray-90;
            border-radius: 30px;
            @include p(22px);
            display: flex;
            align-items: center;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            .item-image {
                width: 50px;
                @include mb(0.75rem);

                @include media-breakpoint-up(md) {
                    width: 70px;
                    @include mr(0.75rem);
                    @include mb(0);
                }
            }

            p {
                color: $dark-gray-15;
                font-size: 18px;
                font-weight: $font-weight-semibold;

                @include media-breakpoint-up(md) {
                    font-size: 22px;
                }
            }
        }
    }
}
