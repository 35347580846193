.text-module-white {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 160px;
        text-align: center;
        row-gap: 25px;

        @include media-breakpoint-down(xxl) {
            padding: 0 230px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 40px;
        }

        @include media-breakpoint-down(md) {
            text-align: left;
            padding: 0 20px;
            font-size: 16px;
        }

        h2 {
            margin: 0;
            color: $text-rich-black;
        }

        p {
            color: $gray-66;
            margin: 0;
            font-size: 20px;
            line-height: 150%;

            @include media-breakpoint-down(xxl) {
                font-size: 18px;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 160%;
                font-weight: $font-weight-normal;
            }
        }
    }
}

.text-module-white--alignment-left {
    text-align: left;

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        text-align: start;
        padding: 0 20px;
    }

    &--title {
        color: $text-rich-black;
        margin-bottom: 25px;

        @include media-breakpoint-down(xxl) {
            padding: 0 230px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 70px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            margin-bottom: 15px;
        }
    }

    &--desc {
        color: $gray-66;
        text-align: left;
        margin-top: 25px;

        @include media-breakpoint-down(xxl) {
            padding: 0 230px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 70px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            text-align: start;
            line-height: 160%; /* 25.6px */
            letter-spacing: -0.32px;
        }
    }
}

.text-module-black--alignment-left {
    text-align: left;

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        text-align: start;
        padding: 0 20px;
    }

    &--title {
        color: $white;
        margin-bottom: 25px;

        @include media-breakpoint-down(xxl) {
            padding: 0 280px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 100px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            margin-bottom: 15px;
        }
    }

    &--desc {
        color: $gray;
        text-align: left;

        @include media-breakpoint-down(xxl) {
            padding: 0 280px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 70px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            text-align: start;
            line-height: 160%; /* 25.6px */
            letter-spacing: -0.32px;
        }
    }
}

.text-module-black {
    text-align: center;

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        text-align: start;
        padding: 0 20px;
    }

    &--title {
        color: $white;
        margin-bottom: 25px;

        @include media-breakpoint-down(xxl) {
            padding: 0 250px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 100px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            margin-bottom: 15px;
        }
    }

    &--desc {
        color: $gray;
        text-align: center;

        @include media-breakpoint-down(xxl) {
            padding: 0 280px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 70px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            text-align: start;
            line-height: 160%; /* 25.6px */
            letter-spacing: -0.32px;
        }
    }
}

.photo-container-simple {
    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }
    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__figure {
        margin-bottom: 0;
        height: 600px;
        width: 100%;

        @include media-breakpoint-down(xl) {
            height: 400px;
        }

        @include media-breakpoint-down(md) {
            height: 210px;
        }

        &--img {
            object-fit: cover;
            border-radius: 30px;
            height: 100%;
            width: 100%;

            @include media-breakpoint-down(md) {
                border-radius: 20px;
            }
        }
    }
}

.photo-container-location {
    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }
    &__image {
        position: relative;
        width: 100%;
        height: 100%;

        figure {
            margin: 0;
            height: 100%;

            img {
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
                height: 610px;
                border-radius: 30px;

                @include media-breakpoint-down(xxl) {
                    height: 600px;
                }

                @include media-breakpoint-down(xl) {
                    height: 410px;
                }

                @include media-breakpoint-down(md) {
                    height: 260px;
                    border-radius: 20px;
                }

                @media (max-width: 600px) {
                    height: 210px;
                }
            }
        }

        &__location {
            display: flex;
            gap: 15px;
            align-items: center;
            position: absolute;
            padding: 5px;
            padding-right: 25px;
            bottom: 20px;
            right: 20px;
            border-radius: 15px;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
            backdrop-filter: blur(6px);

            @include media-breakpoint-down(xl) {
                right: 20px;
            }

            @include media-breakpoint-down(md) {
                right: 10px;
                bottom: 10px;
            }

            &--icon {
                display: flex;
                padding: 10px;
                background-color: $white;
                border-radius: 10px;
                box-shadow: 0px 2px 5px 0px rgba(21, 21, 21, 0.08);
                height: 40px !important;
                width: 40px;

                img {
                    border-radius: 0 !important;
                    object-fit: contain !important;
                    height: auto !important;
                }
            }

            &--text {
                p {
                    margin-bottom: 0;
                    color: $text-rich-black;
                    font-weight: $font-weight-semibold;
                    font-size: 14px;

                    @include media-breakpoint-down(md) {
                        font-size: 12px;
                        line-height: 125%;
                    }
                }
            }
        }
    }
}

.photo-container-text-columns {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 0 20px;
        align-items: start;
    }

    &__title {
        margin-bottom: 25px;
        text-align: center;

        @include media-breakpoint-down(md) {
            text-align: start;
            width: 80%;
            margin: 0;
        }
    }

    &__desc {
        padding: 0px 250px;
        margin-bottom: 45px;
        color: $dark-gray;
        text-align: center;

        @include media-breakpoint-down(xxl) {
            padding: 0 300px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 100px;
        }

        @include media-breakpoint-down(md) {
            text-align: start;
            padding: 0;
            margin-top: 15px;
            margin-bottom: 25px;
        }
    }

    &__container {
        position: relative;
        height: 600px;
        width: 100%;
        margin-bottom: 45px;

        @include media-breakpoint-down(xl) {
            height: 400px;
        }

        @include media-breakpoint-down(md) {
            height: 210px;
            margin-bottom: 15px;
        }

        figure {
            border-radius: 30px;
            margin: 0;
            height: 100%;
            width: 100%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 30px;
                @include media-breakpoint-down(md) {
                    border-radius: 20px;
                }
            }
        }

        &__info-banner {
            position: absolute;
            left: 25px;
            bottom: 25px;
            right: 25px;
            background-color: rgba(255, 255, 255, 0.95);
            box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
            border-radius: 25px;
            display: flex;
            padding: 30px 20px;
            height: 165px;

            @include media-breakpoint-down(xl) {
                padding: 35px 25px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &__info {
                padding: 0 20px;

                @include media-breakpoint-down(xl) {
                    padding: 0 10px;
                }

                &__title {
                    margin-bottom: 10px;
                    font-weight: $font-weight-semibold;
                    font-size: 18px;
                    @include media-breakpoint-down(xl) {
                        font-size: 12px;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                    }
                }

                &__desc {
                    color: $dark-gray;
                    font-size: 15px;

                    @include media-breakpoint-down(xl) {
                        font-size: 12px;
                    }
                }
            }

            &--separator {
                width: 1px;
                height: 100%;
                margin: 0px 25px;
                background-color: $gray-e6e6;
                @include media-breakpoint-down(xl) {
                    margin: 0 5px;
                }
            }
        }
    }
    #splider-about-us-info-banner {
        display: none;
        width: 100%;

        @include media-breakpoint-down(md) {
            display: block;
        }
        .splide__track {
            margin-right: -20px;
            margin-left: -20px;
            padding-left: 20px !important;
            padding-right: 20px !important;
        }

        .photo-container-text-columns__container__info-banner-mobile__card {
            padding: 20px;
            border-radius: 20px;
            border: 0.08em solid $gray-e6e6;
            height: 100%;

            &__title {
            }

            &__desc {
                color: $dark-gray;
                line-height: 160%; /* 24px */
                letter-spacing: -0.3px;
            }
        }
    }

    & > a {
        align-self: center;
    }
}

.cta-banner {
    padding-top: 60px;
    padding-bottom: 20px;

    @include media-breakpoint-down(md) {
        padding: 0 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__content {
        display: flex;
        padding: 40px;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: #202020;
        justify-content: space-between;

        @include media-breakpoint-down(xl) {
            padding: 30px;
        }

        @include media-breakpoint-down(md) {
            padding: 25px;
            flex-direction: column;
            gap: 20px;
        }

        h3 {
            color: white;

            @include media-breakpoint-down(xl) {
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%; /* 28.6px */
                letter-spacing: -0.44px;
            }

            @include media-breakpoint-down(md) {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 28px */
                letter-spacing: -0.4px;
            }
        }

        a.btn-normal {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}

.cta-module {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding-bottom: 120px;

    @include media-breakpoint-down(xl) {
        gap: 20px;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding-bottom: 50px;
    }

    &__desc {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &__text {
            display: flex;
            flex-direction: column;
            gap: 25px;
            padding-right: 80px;

            @include media-breakpoint-down(xl) {
                padding-right: 40px;
            }

            @include media-breakpoint-down(md) {
                padding-right: 0;
                gap: 15px;
            }

            h2 {
                color: $white;
                letter-spacing: -1.1px;

                @include media-breakpoint-up(xxl) {
                    font-size: 55px;
                }
            }

            p {
                color: $gray;
                letter-spacing: -0.36px;

                @include media-breakpoint-up(xxl) {
                    font-size: 18px;
                }
            }
        }
    }

    &__figure {
        &--img {
            width: 100%;
            height: 350px;
            border-radius: 30px;
            object-fit: cover;

            @include media-breakpoint-down(md) {
                height: 210px;
            }
        }
    }

    &__container {
        display: flex;
        gap: 45px;
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;

        @include media-breakpoint-down(xl) {
            gap: 35px;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            gap: 5px;
        }

        &__download {
            display: flex;
            height: 55px;
            padding: 15px 20px 15px 25px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 15px;
            border: 1.5px solid white;
            color: white;
            text-decoration: none;

            align-self: end;
            justify-self: flex-start;

            @include media-breakpoint-down(bsm) {
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                align-self: auto;
            }

            @include media-breakpoint-up(xl) {
                &:hover {
                    background-color: $white;
                    color: $dark-gray-15;

                    .download-black-icon {
                        display: block;
                    }

                    .download-white-icon {
                        display: none;
                    }
                }
            }

            .download-white-icon {
                height: 24px;
                width: 24px;
            }

            .download-black-icon {
                display: none;
                height: 24px;
                width: 24px;
            }
        }

        &__learn-more {
            text-decoration: none;
            color: $gray;
            align-self: end;
            padding: 18px 0px;
            @include media-breakpoint-down(md) {
                align-self: center;
            }
            &:hover {
                color: $white;
            }
        }
    }
}

.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    h2 {
        color: $dark-gray-15;
        text-align: center;
        padding: 0 200px;
        letter-spacing: -1.2px;

        @include media-breakpoint-down(xxl) {
            padding: 0 220px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 40px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            text-align: left;
        }
    }

    p {
        text-align: center;
        width: 889px;
        letter-spacing: -0.4px;
        color: $dark-gray;

        @include media-breakpoint-down(xxl) {
            width: 840px;
        }

        @include media-breakpoint-down(xl) {
            width: 784px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            text-align: left;
            width: auto;
        }
    }

    figure {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        margin: 0;
        iframe {
            width: 100%;
            height: 610px;
            border-radius: 30px;
            margin-top: 25px;

            @include media-breakpoint-down(xxl) {
                height: 600px;
            }

            @include media-breakpoint-down(xl) {
                height: 410px;
            }

            @include media-breakpoint-down(md) {
                margin-top: 5px;
                height: 210px;
            }
        }
    }
}

.two-cards-container {
    display: flex;
    flex-direction: column;
    gap: 45px;

    @include media-breakpoint-down(md) {
        padding: 0 20px;
        gap: 25px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__info {
        display: flex;
        gap: 20px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        &__card {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 25px 30px 30px 30px;
            border-radius: 30px;
            border: 0.075em solid var(--black-100, $gray-e6e6);
            background: var(--White, $white);
            line-height: 160%; /* 24px */
            letter-spacing: -0.3px;

            @include media-breakpoint-down(xl) {
                padding-bottom: 25px;
            }

            @include media-breakpoint-down(md) {
                padding: 20px;
            }

            &__description {
                color: $dark-gray;
            }
        }
    }

    &__images {
        display: flex;
        gap: 20px;

        @include media-breakpoint-down(xl) {
            flex-direction: column;
        }

        @include media-breakpoint-down(md) {
            padding-top: 5px;
        }

        &__card {
            position: relative;
            height: 550px;
            width: 100%;

            @include media-breakpoint-down(md) {
                height: 400px;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 30px;
            }

            &__text {
                display: flex;
                position: absolute;
                bottom: 25px;
                left: 25px;
                right: 25px;
                gap: 50px;
                padding: 30px;
                align-items: end;
                background: rgba(255, 255, 255, 0.95);
                border-radius: 25px;
                justify-content: space-between;

                @include media-breakpoint-down(bsm) {
                    flex-direction: column;
                    bottom: 10px;
                    left: 10px;
                    right: 10px;
                    gap: 15px;
                    padding: 20px;
                    align-items: start;
                }

                &__description {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    h4 {
                        @include media-breakpoint-up(md) {
                            font-size: 30px;
                        }
                    }
                    p {
                        width: 80%;
                        color: $dark-gray;
                        @include media-breakpoint-up(md) {
                            font-size: 18px;
                            line-height: 150%;
                        }
                    }
                }
                a {
                    font-weight: 600;
                    display: flex;
                    height: 55px;
                    border-color: $gray-e6e6;
                    white-space: nowrap;
                    padding: 15px 25px;
                    cursor: pointer;
                }
            }
        }
    }
}

.images-only-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    @include media-breakpoint-down(md) {
        align-items: flex-start;
        gap: 15px;
        padding: 30px 20px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    h1 {
        color: $white;
    }

    p {
        padding: 0 250px;
        color: $gray;
        text-align: center;

        @include media-breakpoint-up(xxl) {
            font-size: 18px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0;
        }

        @include media-breakpoint-down(md) {
            text-align: left;
        }
    }

    &__header-imgs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        padding-top: 45px;
        padding-bottom: 25px;

        @include media-breakpoint-down(md) {
            padding: 0;
            padding-top: 20px;
            display: flex;
            width: 100%;
        }

        figure {
            margin: 0;
            width: 100%;

            &.image-0 {
                grid-area: 1 / 2 / 2 / 3;
                height: 350px;

                @include media-breakpoint-down(xl) {
                    height: 230px;
                }
            }
            &.image-1 {
                grid-area: 1 / 1 / 3 / 2;
                height: 600px;
                align-self: center;

                @include media-breakpoint-down(xl) {
                    height: 400px;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
            &.image-2 {
                grid-area: 1 / 3 / 3 / 4;
                height: 600px;
                align-self: center;

                @include media-breakpoint-down(xl) {
                    height: 400px;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
            &.image-3 {
                grid-area: 2 / 2 / 3 / 3;
                height: 350px;

                @include media-breakpoint-down(xl) {
                    height: 230px;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .images-only-container__header-imgs__img {
                border-radius: 30px;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.images-and-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;

    @include media-breakpoint-down(xl) {
        padding-bottom: 0;
    }

    @include media-breakpoint-down(md) {
        align-items: flex-start;
        gap: 15px;
        padding: 30px 20px 25px 20px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    h1 {
        color: $white;

        @include media-breakpoint-down(md) {
            width: 80%;
        }
    }

    &__desc {
        display: none;
        color: $gray;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &__header-imgs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 20px;
        height: 536px;
        width: 100%;
        margin-top: 90px;

        @include media-breakpoint-down(xl) {
            height: 520px;
            padding: 30px 0;
            margin-top: 60px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 0;
            padding: 25px 0;
            display: none;
        }

        & > * {
            border-radius: 30px;
            background: #202020;
        }

        &__img1 {
            height: 100%;
            width: 338px;
            padding: 30px;
            position: relative;
            overflow: hidden;

            @include media-breakpoint-down(xl) {
                width: calc(100% / 3 - 15px);
                padding: 25px;
            }

            &--title {
                color: $white;
                letter-spacing: -0.44px;
                margin-bottom: 15px;
            }

            &--desc {
                color: $gray;
                letter-spacing: -0.3px;
            }

            &__small-circle {
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &__big-circle {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        &__img2 {
            width: 605px;
            height: 340px;
            margin: 0;

            @include media-breakpoint-down(xl) {
                width: calc(100% / 3 - 15px);
                height: 180px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 30px;
            }
        }

        &__img3 {
            width: 605px;
            height: 176px;
            padding: 30px;
            position: relative;
            overflow: hidden;

            @include media-breakpoint-down(xl) {
                width: calc(100% / 3 - 15px);
                height: 260px;
                padding: 25px;
            }

            &--title {
                color: $white;
                letter-spacing: -0.44px;
                margin-bottom: 15px;
            }

            &--desc {
                color: $gray;
                letter-spacing: -0.3px;

                @include media-breakpoint-down(xl) {
                    line-height: 140%; /* 19.6px */
                    letter-spacing: -0.28px;
                }
            }

            &__big-circle-left {
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        &__img4 {
            width: 317px;
            height: 258px;
            margin: 0;

            @include media-breakpoint-down(xl) {
                width: calc(100% / 3 - 15px);
                height: 220px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 30px;
            }
        }
        &__img5 {
            width: 317px;
            height: 258px;
            margin: 0;

            @include media-breakpoint-down(xl) {
                width: calc(100% / 3 - 15px);
                height: 220px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 30px;
            }
        }
    }

    #splider-about-us-header {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
        }

        .splide__track {
            margin-right: -20px;
            margin-left: -20px;
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }

    .images-and-text-container__header-imgs-carousel__card {
        border-radius: 30px;
        background: #202020;
        padding: 25px;
        height: 100%;
        position: relative;
        overflow: hidden;

        &--title {
            color: $gray-e6e6;
            margin-bottom: 15px;
        }
        &--desc {
            color: $gray;
        }

        &__mobile-left-circles {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        &__mobile-right-circle {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.tabs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    @include media-breakpoint-between(xl, xxl) {
        max-width: 1280px;
    }

    @include media-breakpoint-down(md) {
        align-items: flex-start;
        gap: 15px;
        padding: 0px 20px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    h1 {
        color: $white;
    }

    p {
        padding: 0 250px;
        color: $gray;
        text-align: center;

        @include media-breakpoint-up(xxl) {
            font-size: 18px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0;
        }

        @include media-breakpoint-down(md) {
            text-align: left;
        }
    }

    &__collapse-section {
        display: flex;
        flex-direction: column;
        gap: 45px;
        padding-top: 25px;
        width: 100%;

        @include media-breakpoint-down(md) {
            padding-top: 20px;
        }

        &__buttons {
            display: flex;
            padding: 10px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 20px;
            border: 1.5px solid #333;
            margin: auto;

            @include media-breakpoint-down(md) {
                margin: 0;
            }

            button {
                min-width: 140px;
                height: 50px;
                padding: 15px 50px;
                border-radius: 15px;
                background-color: transparent;
                color: $white;
                border: none;

                @include media-breakpoint-down(md) {
                    flex: 1 0 0;
                    width: auto;
                    padding: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    min-width: 30%;
                }

                &.active {
                    background-color: $white;
                    color: $dark-gray-15;
                }

                &:hover:not(.active) {
                    background-color: rgba(255, 255, 255, 0.05);
                }
            }
        }

        &__panels {
            width: 100%;

            &__panel {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;

                figure {
                    margin: 0;
                    width: 50%;
                    height: 350px;
                    border-radius: 30px;

                    @include media-breakpoint-down(xl) {
                        height: 260px;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }

                    .tabs-container__collapse-section__panels__panel__img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 30px;
                    }
                }

                &__text {
                    width: 50%;
                    height: 350px;
                    border-radius: 30px;
                    background-color: #202020;
                    padding: 35px 100px 35px 35px;
                    position: relative;

                    @include media-breakpoint-down(xl) {
                        height: 260px;
                        padding: 35px;
                        overflow: hidden;
                    }

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        padding: 25px 25px 100px 25px;
                        height: auto;
                    }

                    p, .default-ul {
                        padding: 0;
                        text-align: start;
                        color: #ccc;
                        line-height: 150%;
                        font-size: 18px;

                        @include media-breakpoint-down(xl) {
                            font-size: 14px;
                            line-height: 140%; /* 19.6px */
                            letter-spacing: -0.28px;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 160%; /* 25.6px */
                            letter-spacing: -0.32px;
                            color: $gray-e6e6;
                        }
                    }

                    svg {
                        position: absolute;
                        bottom: 0;
                        right: 0;

                        @include media-breakpoint-down(xl) {
                            bottom: -5px;
                            right: -100px;
                            transform: scale(1.5);
                        }

                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .collapsible-content {
        display: none;
    }

    .collapsible-content.active {
        display: flex;
        /* Additional styling for flex container */
    }
}

.location-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-down(md) {
        display: none;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    h2 {
        color: $dark-gray-15;
        font-size: 25px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    &__countries {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        &__country {
            display: flex;
            flex-direction: column;
            gap: 15px;
            border-radius: 25px;
            border: 1.5px solid $gray-e6e6;
            padding: 10px;
            width: 310px;
            // box-shadow: 0px 5px 30px 0px rgba(255, 255, 255, 1);
            transition: box-shadow 0.3s ease-out;
            cursor: pointer;
            text-decoration: none;
            position: relative;

            &:hover {
                border-color: transparent;
                box-shadow: 0px 5px 30px 0px rgba(21, 21, 21, 0.15);
            }

            @include media-breakpoint-down(xl) {
                width: 274.6666px;
            }

            &__image {
                // position: relative;

                figure {
                    .locations__desktop-regions__region__countries__country__image__photo {
                        width: 100%;
                        height: 200px;
                        object-fit: cover;
                        border-radius: 20px;
                    }
                }

                &__photo {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                    border-radius: 20px;
                }

                &__icon {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    padding: 10px;
                    border-radius: 15px;
                    background-color: $white;
                    box-shadow: 0px 10px 30px 0px rgba(91, 106, 129, 0.2);
                    backdrop-filter: blur(6px);

                    img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            &__description {
                display: flex;
                justify-content: space-between;
                gap: 12px;
                padding: 0px 15px 10px 15px;

                &__left {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;

                    &__title {
                        color: $dark-gray-15;
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 140%;
                        margin: 0;
                    }
                }

                &__right {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }

    &__mobile-regions {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &.accordion {
            .carousel-item {
                @include media-breakpoint-down(md) {
                    height: 260px;
                }

                @media (max-width: 600px) {
                    height: 210px;
                }
            }
        }

        &__region {
            @include media-breakpoint-down(md) {
                padding: 0 20px;
            }
            .accordion-button {
                font-size: 18px !important;
                font-weight: 600;
                line-height: 130%;
                padding-top: 0 !important;
            }
            &__country {
                .accordion-body {
                    padding: 0 !important;

                    .carousel-skeleton {
                        height: 260px;
                        width: 100%;
                        background-color: $dark-gray;
                        border-radius: 20px;
                    }

                    .splide__track {
                        max-height: 305px;

                        @media (max-width: 600px) {
                            max-height: 255px;
                        }
                    }

                    .splide__slide {
                        margin-top: 0 !important;
                        figure {
                            width: 100%;
                            height: 100%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 20px;
                            }
                        }

                        .our-locations__content__right__location {
                            background: rgba(255, 255, 255, 0.95);
                            box-shadow: 0px 10px 30px 0px
                            rgba(91, 106, 129, 0.2);
                            backdrop-filter: blur(6px);

                            a {
                                min-width: 34px;
                                min-height: 34px;
                                padding: 7px;
                                svg {
                                    height: 20px;
                                    width: 20px;
                                    transition: stroke 0.3s ease; /* Add a smooth transition for the stroke property */
                                }
                            }

                            &--text {
                                .jobs {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cards-with-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
        padding: 45 20px;
        text-align: left;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 250px;
        text-align: center;
        row-gap: 25px;
        margin-bottom: 45px;

        @include media-breakpoint-down(xl) {
            padding: 0 80px;
        }

        @include media-breakpoint-down(md) {
            text-align: left;
            max-width: 100vw;
            padding: 0 20px;
        }

        h2 {
            margin: 0;
            color: $text-rich-black;
        }

        p {
            color: $gray-66;
            margin: 0;
            font-size: 20px;

            @include media-breakpoint-down(xxl) {
                font-size: 18px;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 160%;
                font-weight: 400;
            }
        }
    }

    &__cards {
        display: flex;
        gap: 20px;

        @include media-breakpoint-down(xl) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
        }

        &__card {
            border-radius: 30px;
            padding: 30px;
            width: 25%;
            min-height: 347px;
            position: relative;
            overflow: hidden;

            @include media-breakpoint-down(xl) {
                min-height: auto;
                width: 100%;
            }

            &.green {
                background-color: #00968f;

                p {
                    color: #ccfaff;
                }
            }

            &.blue {
                background-color: #0e56e9;

                p {
                    color: $cobalt-blue-90;
                }
            }

            &.purple {
                background-color: #9d4dff;

                p {
                    color: $white;
                }
            }

            h3 {
                font-size: 24px;
                color: $white;
                margin-bottom: 15px;
                font-weight: 500;
                padding-right: 70px;
            }

            p {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 21px */
                opacity: 0.8999999761581421;
            }

            .svg {
                position: absolute;
                bottom: 0;
                right: 0;
            }

            &:last-child {
                display: flex;
                padding: 25px 30px 30px 30px;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                background-color: #ffbd66;

                h3 {
                    padding: 0;
                    font-weight: 700;
                    color: $text-rich-black;
                }
            }

            &--v2 {
                border-radius: 30px;
                padding: 30px;
                width: 25%;
                min-height: 372px;
                position: relative;
                overflow: hidden;
                background-color: $gray-90;

                @include media-breakpoint-down(xl) {
                    min-height: auto;
                    width: 100%;
                }

                @include media-breakpoint-down(md) {
                    border-radius: 20px;
                    padding: 20px;
                }

                figure {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 35px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 20px;
                        width: 40px;
                        height: 40px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                h3 {
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%;
                    color: #5f249f;
                    margin-bottom: 15px;
                    width: 65%;

                    @include media-breakpoint-down(xxl) {
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 20px;
                        line-height: 140%;
                    }
                }

                p {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                    color: $dark-gray;
                    margin-bottom: 0;

                    @include media-breakpoint-down(md) {
                        font-size: 15px;
                        line-height: 160%;
                    }
                }

                a {
                    text-align: center;
                    justify-content: center;

                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }

            &--v2:last-child {
                display: flex;
                padding: 25px 30px 30px 30px;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                background-color: #5f249f;

                @include media-breakpoint-down(md) {
                    padding: 20px;
                }

                h3 {
                    padding: 0;
                    font-weight: 600;
                    color: $white;
                    width: 100%;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 40px;
                    }
                }

                svg {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;

    @include media-breakpoint-down(md) {
        align-items: start;
        gap: 25px;
        padding: 0 20px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__cards {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        position: relative;

        @include media-breakpoint-down(md) {
            gap: 15px;
        }

        &.compressed {
            @include media-breakpoint-down(md) {
                height: 1000px;
                overflow: hidden;

                &.more-than-6-children::after {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    width: 100%;
                    height: 170px;
                    background: linear-gradient(
                            0deg,
                            rgb(255, 255, 255) 40%,
                            rgba(255, 255, 255, 0.5) 50%,
                            rgba(255, 255, 255, 0) 100%
                    );
                    z-index: 10;
                }

                .benefits__cards--expand {
                    display: block;
                    border-radius: 15px;
                    background-color: $gray-e6e6;
                    border: none;
                    color: $dark-gray-15;
                    position: absolute;
                    bottom: 0;
                    padding: 15px 20px 15px 25px;
                    font-size: 15px;
                    z-index: 100;
                    svg {
                        width: 24px;
                        height: 24px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        &--expand {
            display: none;
        }

        &__card {
            padding: 30px 25px;
            border-radius: 30px;
            border: 0.08em solid $gray-e6e6;
            width: calc(100% / 3 - 14px);

            @include media-breakpoint-down(xl) {
                width: calc(100% / 2 - 10px);
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 20px;
                border-radius: 20px;
            }

            &__title {
                display: flex;
                gap: 15px;
                color: $primary;
                margin-bottom: 20px;
                align-items: center;
                figure {
                    margin: 0;
                    width: 35px;
                    height: 35px;
                    padding: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include media-breakpoint-down(md) {
                        width: 25px;
                        height: 25px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    margin-bottom: 15px;
                }
            }

            &__desc {
                padding-left: 50px;
                padding-right: 15px;
                color: $dark-gray;
                opacity: 0.9;

                @include media-breakpoint-down(md) {
                    padding-left: 40px;
                }
            }
        }
    }
}

.image-banners-text {
    display: flex;
    gap: 20px;

    @include media-breakpoint-down(xl) {
        flex-direction: column;
    }

    @include media-breakpoint-down(md) {
        padding: 0px 20px;
        padding-top: 5px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__card {
        position: relative;
        height: 370px;
        width: 100%;

        @include media-breakpoint-down(md) {
            height: 400px;
        }

        figure {
            width: 100%;
            height: 100%;
            border-radius: 30px;

            img {
                object-fit: cover;
                border-radius: 30px;
                width: 100%;
                height: 100%;
            }
        }

        &__text {
            display: flex;
            position: absolute;
            bottom: 25px;
            left: 25px;
            right: 25px;
            gap: 50px;
            padding: 30px;
            align-items: end;
            background: rgba(255, 255, 255, 0.95);
            border-radius: 25px;
            justify-content: space-between;

            @include media-breakpoint-down(bsm) {
                flex-direction: column;
                bottom: 10px;
                left: 10px;
                right: 10px;
                gap: 15px;
                padding: 20px;
                align-items: start;
            }

            &__description {
                display: flex;
                flex-direction: column;
                gap: 15px;

                h4 {
                    @include media-breakpoint-up(md) {
                        font-size: 30px;
                    }
                }
                p {
                    width: 80%;
                    color: $dark-gray;
                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                        line-height: 150%;
                    }
                }
            }
            a {
                font-weight: 600;
                display: flex;
                height: 55px;
                border-color: $gray-e6e6;
                white-space: nowrap;
                padding: 15px 25px;
                cursor: pointer;
            }
        }
    }
}

.luxoft-magazine,
.luxoft-magazine__white {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    @include media-breakpoint-down(xl) {
        gap: 20px;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 0 20px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &__desc {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &__text {
            display: flex;
            flex-direction: column;
            gap: 25px;
            padding-right: 80px;

            @include media-breakpoint-down(xl) {
                padding-right: 40px;
            }

            @include media-breakpoint-down(md) {
                padding-right: 0;
                gap: 15px;
            }

            h2 {
                color: white;
                letter-spacing: -1.1px;

                @include media-breakpoint-up(xxl) {
                    font-size: 55px;
                }
            }

            p {
                color: $gray;
                letter-spacing: -0.36px;

                @include media-breakpoint-up(xxl) {
                    font-size: 18px;
                }
            }
        }
    }

    &__figure {
        width: 100%;
        height: 350px;
        border-radius: 30px;
        margin: 0;

        @include media-breakpoint-down(md) {
            height: 210px;
        }

        &--img {
            width: 100%;
            height: 100%;
            border-radius: 30px;
            object-fit: cover;
        }
    }

    &__container {
        display: flex;
        gap: 45px;
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;

        @include media-breakpoint-down(xl) {
            gap: 35px;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            gap: 5px;
        }

        &__download {
            display: flex;
            height: 55px;
            padding: 15px 20px 15px 25px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 15px;
            border: 1.5px solid white;
            color: white;
            text-decoration: none;

            align-self: end;
            justify-self: flex-start;

            @include media-breakpoint-down(bsm) {
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                align-self: auto;
            }

            @include media-breakpoint-up(xl) {
                &:hover {
                    background-color: white;
                    color: $dark-gray-15;

                    .download-black-icon {
                        display: block;
                    }

                    .download-white-icon {
                        display: none;
                    }
                }
            }

            .download-white-icon {
                height: 24px;
                width: 24px;
            }

            .download-black-icon {
                display: none;
                height: 24px;
                width: 24px;
            }
        }

        &__learn-more {
            text-decoration: none;
            color: $gray;
            align-self: end;
            padding: 18px 0px;
            @include media-breakpoint-down(md) {
                align-self: center;
            }
            &:hover {
                color: white;
            }
        }
    }
}

.luxoft-magazine__white {

    @include media-breakpoint-down(xl) {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 0 20px;
    }

    &__desc {
        &__text {
            h2 {
                color: $text-rich-black;
            }

            p {
                color: $dark-gray;
            }

            @include media-breakpoint-up(md) {
                text-align: center;
            }

            @include media-breakpoint-up(xl) {
                text-align: left;
            }

            @include media-breakpoint-down(xl) {
                padding: 0;
            }
        }

        @include media-breakpoint-down(xl) {
            order: 1;
        }
    }

    &__figure {
        @include media-breakpoint-down(xl) {
            order: 3;
        }
    }

    &__container {
        &__download {
            border: 1.5px solid $text-rich-black;
            color: $text-rich-black;

            figure {
                margin: 0;
            }
        }

        @include media-breakpoint-down(xl) {
            justify-content: center;
            order: 2;
        }
    }
}

.cta-section {
    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }

    &.container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &--purple {
        .cta-section__cta-banner {
            background-color: $purple;

            a {
                border: 1.5px solid $white;

                &:hover {
                    background-color: $bright-purple-40;
                }
            }
        }
    }

    &--light-gray {
        .cta-section__cta-banner {
            background-color: $gray-90;

            h3 {
                color: $dark-gray-15;
            }

            .btn-normal {
                border: 1.5px solid $dark-gray-15;
                background-color: transparent;
                color: $dark-gray-15;
            }
        }
    }

    &__cta-banner {
        display: flex;
        padding: 40px;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: $gray-20;
        justify-content: space-between;

        @include media-breakpoint-down(xl) {
            padding: 30px;
        }

        @include media-breakpoint-down(md) {
            padding: 20px;
            flex-direction: column;
            gap: 20px;
        }

        h3 {
            color: $white;

            @include media-breakpoint-down(xl) {
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%; /* 28.6px */
                letter-spacing: -0.44px;
            }

            @include media-breakpoint-down(md) {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 28px */
                letter-spacing: -0.4px;
            }
        }

        a.btn-normal {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}

.carousel-cards {
    & > .container {
        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 1280px;
        }
        @include media-breakpoint-down(xl) {
            max-width: 864px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }
}

.purple-icons-cta {
    background-color: $purple;
    color: $white;
    border-radius: 20px;
    padding: 20px;
    width: 90%;

    &.container {
        @include media-breakpoint-up(xl) {
            max-width: 1280px;
        }
    }

    @include media-breakpoint-up(bsm) {
        width: 95%;
    }

    @include media-breakpoint-up(md) {
        border-radius: 30px;
        padding: 40px;
        width: 100%;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
        }

        &__heading {
            h5 {
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;

                @include media-breakpoint-up(md) {
                    font-size: 22px;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 30px;
                    line-height: 36px;
                    font-weight: 700;
                }
            }
        }

        &__icons {
            display: flex;
            flex-direction: row;
            gap: 10px;

            figure {
                margin: 0;

                img {
                    width: auto;
                    height: 40px;

                    @include media-breakpoint-up(md) {
                        height: 32px;
                    }
                }
            }
        }
    }
}


.key-technology {
    @include media-breakpoint-down(md) {
        overflow: hidden;
        position: relative;
    }

    .container {
        width: 90%;

        @include media-breakpoint-up(xl) {
            max-width: 1280px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 1300px;
        }
    }

    h4 {
        font-size: 35px;
        line-height: 42px;
        font-weight: 600;
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: 50px;
            line-height: 60px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 55px;
            line-height: 66px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 60px;
            line-height: 72px;
        }
    }

    &__chips {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-top: 30px;
        flex-wrap: nowrap;
        justify-content: flex-start;

        @include media-breakpoint-up(md) {
            gap: 20px;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__chip {
            border-radius: 30px;
            border: 1px solid $gray-e6e6;
            padding: 10px;
            min-width: 150px;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;

            @include media-breakpoint-up(md) {
                padding: 10px 5px;
                min-width: 200px;
            }

            @include media-breakpoint-up(xl) {
                min-width: 150px;
            }

            @include media-breakpoint-up(xxl) {
                min-width: 165px;
            }

            p {
                color: $text-rich-black;
                font-size: 15px;
                line-height: 19.5px;
                font-weight: 600;

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }
}
