#how-we-hire-first {
    background-color: $white;
}

#how-we-hire-second {
    background-color: $white;

    &.no-content {
        height: 30px;
    }
}

.black-background {
    &.how-we-hire-first {
        padding-top: 148px;
        padding-bottom: 80px;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;

        @include media-breakpoint-down(md) {
            padding: 104px 20px 25px;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
        }

        .container {
            @include media-breakpoint-between(xl, xxl) {
                max-width: 1280px;
            }
        }
    }

    &.how-we-hire-second {
        .container {
            @include media-breakpoint-between(xl, xxl) {
                max-width: 1280px;
            }
        }
    }

    .how-we-hire-first__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            gap: 15px;
        }

        h1 {
            color: $white;
        }

        p {
            padding: 0 250px;
            color: $gray;
            text-align: center;

            @include media-breakpoint-up(xxl) {
                font-size: 18px;
            }

            @include media-breakpoint-down(xl) {
                padding: 0;
            }

            @include media-breakpoint-down(md) {
                text-align: left;
            }
        }

        &__header-imgs {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            padding-top: 45px;

            @include media-breakpoint-down(md) {
                padding: 0;
                padding-top: 20px;
                display: flex;
                width: 100%;
            }

            &__img {
                border-radius: 30px;
                object-fit: cover;
                width: 100%;

                &.image-0 {
                    grid-area: 1 / 2 / 2 / 3;
                    height: 350px;

                    @include media-breakpoint-down(xl) {
                        height: 230px;
                    }
                }
                &.image-1 {
                    grid-area: 1 / 1 / 3 / 2;
                    height: 600px;
                    align-self: center;

                    @include media-breakpoint-down(xl) {
                        height: 400px;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
                &.image-2 {
                    grid-area: 1 / 3 / 3 / 4;
                    height: 600px;
                    align-self: center;

                    @include media-breakpoint-down(xl) {
                        height: 400px;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
                &.image-3 {
                    grid-area: 2 / 2 / 3 / 3;
                    height: 350px;

                    @include media-breakpoint-down(xl) {
                        height: 230px;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }
    }

    &.how-we-hire-second {
        padding-top: 80px;
        padding-bottom: 60px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;

        @include media-breakpoint-down(md) {
            padding: 30px 20px 20px;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
        }
    }

    .how-we-hire-second__container {
        @include media-breakpoint-between(xl, xxl) {
            max-width: 1280px;
        }
    }
}

.white-background.how-we-hire {
    padding: 80px 0px;
    border-radius: 0;

    @include media-breakpoint-down(md) {
        padding: 40px 20px;
    }

    .container {
        @include media-breakpoint-between(xl, xxl) {
            max-width: 1280px;
        }
    }

    #how-we-hire-carousel,
    #how-we-hire-mobile-carousel {
        margin-top: 45px;
        margin-bottom: 50px;
        position: relative;

        @include media-breakpoint-down(md) {
            margin-top: 25px;
            margin-bottom: 30px;
        }

        .carousel-mobile-blur {
            display: none;
            position: absolute;
            bottom: 0;

            @include media-breakpoint-down(md) {
                display: block;
                width: 100%;
                height: 50px;
                background: rgb(255, 255, 255);
                background: -moz-linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 70%
                );

                background: -webkit-linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 70%
                );

                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 70%
                );

                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
            }
        }
    }

    .how-we-hire__slide-container {
        width: 460px;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

#how-we-hire-carousel {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

#how-we-hire-mobile-carousel {
    display: none;

    @include media-breakpoint-down(md) {
        display: initial;
    }

    & .splide__track {
        margin-bottom: 30px;
        margin-top: 25px;
    }
}

.carousel-mobile-blur {
    display: none;
    position: fixed;
    bottom: 60px;
    z-index: 1000;

    @include media-breakpoint-down(md) {
        display: block;
        width: 100%;
        height: 50px;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 70%
        );

        background: -webkit-linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 70%
        );

        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 70%
        );

        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    }
}

.how-we-hire {
    &__starting {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 45px;

        @include media-breakpoint-down(md) {
            padding-top: 30px;
            gap: 25px;
        }

        &__info {
            display: flex;
            gap: 20px;
            padding-bottom: 50px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                padding-bottom: 30px;
            }

            &__card {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding: 25px 30px 30px 30px;
                border-radius: 30px;
                border: 0.075em solid var(--black-100, $gray-e6e6);
                background: var(--White, $white);
                line-height: 160%; /* 24px */
                letter-spacing: -0.3px;

                @include media-breakpoint-down(xl) {
                    padding-bottom: 25px;
                }

                @include media-breakpoint-down(md) {
                    padding: 20px;
                }

                &__description {
                    color: $dark-gray;
                }
            }
        }

        &__images {
            display: flex;
            gap: 20px;

            @include media-breakpoint-down(xl) {
                flex-direction: column;
            }

            @include media-breakpoint-down(md) {
                padding-top: 5px;
            }

            &__card {
                position: relative;
                height: 550px;
                width: 100%;

                @include media-breakpoint-down(md) {
                    height: 400px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 30px;
                }

                &__text {
                    display: flex;
                    position: absolute;
                    bottom: 25px;
                    left: 25px;
                    right: 25px;
                    gap: 50px;
                    padding: 30px;
                    align-items: end;
                    background: rgba(255, 255, 255, 0.95);
                    border-radius: 25px;
                    justify-content: space-between;

                    @include media-breakpoint-down(bsm) {
                        flex-direction: column;
                        bottom: 10px;
                        left: 10px;
                        right: 10px;
                        gap: 15px;
                        padding: 20px;
                        align-items: start;
                    }

                    &__description {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;

                        h4 {
                            @include media-breakpoint-up(md) {
                                font-size: 30px;
                            }
                        }
                        p {
                            width: 80%;
                            color: $dark-gray;
                            @include media-breakpoint-up(md) {
                                font-size: 18px;
                                line-height: 150%;
                            }
                        }
                    }
                    a {
                        font-weight: $font-weight-semibold;
                        display: flex;
                        height: 55px;
                        border-color: $gray-e6e6;
                        white-space: nowrap;
                        padding: 15px 25px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__slide-container {
        &.mod-1 {
            display: flex;
            flex-direction: column;
            gap: 10px;

            @include media-breakpoint-down(md) {
                padding: 10px;
                border: 1px solid $gray-e6e6;
                border-radius: 20px;
                height: 466px;
            }

            .how-we-hire__slide-container {
                &__image {
                    position: relative;
                    height: 233px;

                    @include media-breakpoint-down(md) {
                        height: 166px;
                    }

                    img {
                        border-radius: 30px;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;

                        @include media-breakpoint-down(md) {
                            border-radius: 15px;
                        }
                    }

                    &__number {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: $font-weight-bold;
                        width: 24px;
                        height: 24px;
                        top: 20px;
                        left: 20px;
                        background-color: $white;
                        border-radius: 50%;
                        mix-blend-mode: screen;

                        @include media-breakpoint-down(md) {
                            top: 10px;
                            left: 10px;
                        }
                    }
                }
                &__text {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 20px;
                    padding-right: 50px;

                    @include media-breakpoint-down(md) {
                        padding: 15px;
                    }

                    &__description {
                        color: $dark-gray;
                    }
                }
            }
        }

        &.mod-2 {
            height: 100%;

            @include media-breakpoint-down(md) {
                height: 466px;
            }

            .how-we-hire__slide-container__text {
                @include p(20px 20px 34px 25px);
            }

            .how-we-hire__slide-container {
                &__image {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 30px;
                }

                &__number {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                    width: 24px;
                    height: 24px;
                    top: 20px;
                    left: 20px;
                    background-color: $white;
                    border-radius: 50%;
                    mix-blend-mode: screen;
                }

                &__text {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 20px 20px 25px 25px;
                    bottom: 0;
                    background-color: rgba(255, 255, 255, 0.95);
                    border-radius: 25px;
                    margin: 10px;
                    backdrop-filter: blur(6px);

                    &__description {
                        color: $dark-gray;
                    }
                }
            }
        }

        &.mod-3 {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            @include media-breakpoint-down(md) {
                height: 466px;
            }

            .how-we-hire__slide-container {
                &__image {
                    position: relative;
                    background-color: $gray-90;
                    border-radius: 30px;
                    height: 150px;
                    min-height: 150px;
                    overflow: hidden;
                    background-image: url('/theme/luxoft/assets/images/others/how-we-hire-mod-3.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    @include media-breakpoint-up(md) {
                        height: 233px;
                        min-height: 233px;
                    }

                    &__number {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: $font-weight-bold;
                        width: 24px;
                        height: 24px;
                        top: 20px;
                        left: 20px;
                        background-color: $dark-gray-15;
                        color: $gray-90;
                        border-radius: 50%;
                    }
                }

                &__text {
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    gap: 10px;
                    padding: 20px 25px 25px 25px;
                    background-color: $gray-90;
                    border-radius: 30px;
                    overflow: hidden;
                    height: 100%;

                    &__description {
                        color: $dark-gray;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 7;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        &.mod-4 {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            @include media-breakpoint-down(md) {
                height: 466px;
            }

            .how-we-hire__slide-container {
                &__image {
                    position: relative;
                    background-color: $gray-90;
                    border-radius: 30px;
                    height: 150px;
                    min-height: 150px;
                    overflow: hidden;
                    background-image: url('/theme/luxoft/assets/images/others/how-we-hire-mod-4.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    @include media-breakpoint-up(md) {
                        height: 233px;
                        min-height: 233px;
                    }

                    &__number {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: $font-weight-bold;
                        width: 24px;
                        height: 24px;
                        top: 20px;
                        left: 20px;
                        background-color: $dark-gray-15;
                        color: $gray-90;
                        border-radius: 50%;
                    }
                }
                &__text {
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    gap: 10px;
                    padding: 20px 25px 25px 25px;
                    background-color: $gray-90;
                    border-radius: 30px;
                    overflow: hidden;
                    height: 100%;

                    &__description {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 7;
                        -webkit-box-orient: vertical;
                        color: $dark-gray;
                    }
                }
            }
        }
    }
}

.hiring-process-carousel {
    &__splide {
        position: relative;

        .splide__list {
            .splide {
                &__slide {
                    @include media-breakpoint-down(md) {
                        width: 84% !important;
                    }

                    .how-we-hire {
                        &__slide-container {
                            width: 100%;

                            &.mod-1 {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                            }

                            &.mod-2 {
                                .how-we-hire__slide-container__text {
                                    @include p(20px 20px 34px 25px);
                                }
                            }

                            &.mod-3 {
                                .how-we-hire__slide-container__text {
                                    height: 100%;
                                }

                                .how-we-hire__slide-container__image {
                                    background-image: url('/theme/luxoft/assets/images/others/how-we-hire-mod-3.png');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                            }

                            &.mod-4 {
                                .how-we-hire__slide-container__text {
                                    height: 100%;
                                }

                                .how-we-hire__slide-container__image {
                                    background-image: url('/theme/luxoft/assets/images/others/how-we-hire-mod-4.png');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                }
                            }
                        }
                    }
                }
            }
        }

        .splide {
            &__arrows {
                position: absolute;
                top: 110px;
                z-index: 2;
                width: 100%;
                display: none;

                @include media-breakpoint-down(md) {
                    display: none !important;
                }

                .splide {
                    &__arrow {
                        svg {
                            display: none;
                        }

                        &--next,
                        &--prev {
                            background-image: url(/theme/luxoft/assets/images/icons/slide-carousel-arrow.svg);
                            width: 50px;
                            height: 50px;
                            border: none;
                            background-color: transparent;
                            background-repeat: no-repeat;
                            position: absolute;

                            &:hover {
                                background-image: url(/theme/luxoft/assets/images/icons/slide-carousel-arrow-hover.svg);
                            }
                        }

                        &--prev {
                            left: -25px;
                            transform: rotate(180deg);
                        }

                        &--next {
                            right: -25px;
                        }
                    }
                }
            }

            &__pagination {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
    }
}
