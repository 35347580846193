.expired-job {
    position: relative;
    @include mt(202px);
    @include p(0 20px 30px 20px);

    @include media-breakpoint-up(md) {
        @include p(0 0 30px 0);
    }

    &__white-section {
        &.white-background {
            display: block;
            @include p(50px 20px);
    
            @include media-breakpoint-up(md) {
                @include p(50px 0);
            }
        }

        p {
            font-size: 18px;
            line-height: 27px;
        }
    }
    
    &__explore-opportunities {
        @include mt(45px);
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $gray-90;
        border-radius: 30px;
        @include p(30px);

        @include media-breakpoint-up(md) {
            @include mt(70px);
            flex-direction: row;
        }

        h4 {
            font-weight: $font-weight-semibold;

            @include media-breakpoint-up(md) {
                font-size: 22px;
            }

            @include media-breakpoint-up(xl) {
                font-weight: $font-weight-bold;
                font-size: 30px;
            }
        }

        .btn-normal {
            @include mt(20px);

            @include media-breakpoint-up(md) {
                @include mt(0);
            }
        }
    }
}

.error-screen {
    @include mt(202px);
    @include pb(140px);
    position: relative;

    p {
        color: $gray;
    }

    .headline {
        font-size: 140px;
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(md) {
            font-size: 200px;
        }
    }

    .subheadline {
        font-size: 40px;
        font-weight: $font-weight-normal;
        line-height: 48px;

        @include media-breakpoint-up(md) {
            font-size: 60px;
            line-height: 72px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 65px;
            line-height: 78px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 70px;
            line-height: 84px;
        }
    }

    &__actions-container {
        display: flex;
        justify-content: center;
        gap: 20px;
        @include mt(40px);

        @include media-breakpoint-up(md) {
            @include mt(70px);
        }
    }
}