.filters-and-chips {
    background-color: $white!important;
    color: $gray!important;
    @include py(2);
    @include px(2);

    .chips-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 11px;

        .chips-set {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 7px;

            .pmd-chip {
                border: 1px solid $gray;
                border-radius: 12px;
                padding: 0.25rem 0.75rem;
                gap: 10px;

                &:hover {
                    border: 1px solid $purple;
                    color: $purple;
                }
            }
        }
    }

    .filters-list {
        display: flex;
        justify-content: center;
        @include mt(2);
        @include mb(2);
        gap: 1rem;

        .filter-types {
            justify-content: center;
            align-items: center;

            .btn {
                cursor: pointer;
                font-weight: $font-weight-normal;
                text-transform: none;
                border: 0.5px solid $gray;
                background-color: $bright-purple-90;
                color: $gray;
                border-radius: 0.25rem;
                padding: 0.375rem 1.125rem 0.375rem 1.5rem;

                &:hover {
                    box-shadow: none;
                }

                &:after {
                    display: none;
                }
            }

            .dropdown-menu {
                margin-top: 0.25rem!important;
                background-color: $white;
                min-width: 10rem;
                box-shadow: 0px 0px 8px rgba(132, 132, 132, 0.25);
                border-radius: 0.25rem;
                padding: 0.25rem 0;

                .item-container {
                    display: flex;
                    padding: 0.25rem 1rem;
                    gap: 0.5rem;
                }

                span {
                    color: $black;
                    font-size: $font-size-14;
                    line-height: 1.25rem;
                    text-decoration: none;
                    display: block;
                }

                input {
                    border: 2px solid $gray-80;
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }
}
